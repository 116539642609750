import {ColumnProps} from "../../../../../types/table";
import {Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import React from "react";
import {currencyFormat} from "../../../../../helpers";
type ListColumnsProps = {
    onRemoveProduct?: (productId: number) => void
}
export const listColumns = (): ColumnProps[] => {
    return [
        {
            id: "id",
            header: "ID"
        },
        {
            id: "product_name",
            header: "PRODUTO",
            cell: info => info.row.original.product_final.name
        },
        {
            id: "product_price",
            header: "PREÇO",
            cell: info => currencyFormat((info.row.original.price))
        },
        // {
        //     id: "delivery_price",
        //     header: "FRETE",
        //     cell: info => currencyFormat(Number(info.row.original.delivery_price))
        // },
        {
            id: "quantity",
            header: "QUANTIDADE"
        },
        {
            id: "product_total_price",
            header: "PREÇO FINAL",
            cell: info => currencyFormat((info.row.original.price) * info.row.original.quantity)
        },
        // {
        //     header: "AÇÕES",
        //     cell: (info) => {
        //         return (
        //             <Flex gap={2}>
        //                 <AiFillDelete className={`cursor-pointer fill-red-800`} onClick={() => onRemoveProduct && onRemoveProduct(info.row.original.id)} />
        //             </Flex>
        //         )
        //     }
        // }
    ]
}