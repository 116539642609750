import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control, useForm} from "react-hook-form";
import {UserBox} from "../../../components/FormBox/UserBox";
import React, {useEffect, useState} from "react";
import {AddressProps, AdProps, AdSlugProps, SelectOptionProps, UserProps} from "../../../types";
import {createAddress, getAddressById} from "../../../services/address";
import {createAd, getAdById, updateAd} from "../../../services/ads";
import {Box, Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {
    getAddressCreateAndEditErrors,
    getAdCreateAndEditErrors,
    getUserCreateAndEditErrors
} from "../../../helpers";
import {CancelItemBox} from "../../../components/CancelItemBox";
import {Dialog} from "../../../components/Dialog";
import {Container} from "../../../components/Container";
import {Button} from "../../../components/Button";
import {PasswordBox} from "../../../components/FormBox/PasswordBox";
import {CreateAndEditBox} from "./components/CreateAndEditBox";
import {NewItemBox} from "../../../components/NewItemBox";
import {getProducts as getAllProducts} from "../../../services/products";
import cloneDeep from "clone-deep";
import {serialize} from "object-to-formdata";
import {storageURL} from "../../../services/axios";
import {v4 as uuid} from "uuid";

export function AdCreateAndEdit() {
    const [isSaving, setIsSaving] = useState(false)
    const [products, setProducts] = useState<SelectOptionProps[]>([])

    const {onOpen: saveDialogOnOpen, isOpen: saveDialogIsOpen, onClose: saveDialogOnClose} = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()
    const {
        control: adControl,
        watch: adWatch,
        getValues: adGetValues,
        reset: adReset,
        resetField: adResetField,
        trigger: adTrigger
    } = useForm<AdProps>()
    const params = useParams()
    const adId = Number(params.adId)

    const getAdData = async () => {
        const response = await getAdById(adId)
        adReset({
            ...response.data,
            start_date: response.data.start_date && new Date(response.data.start_date).toISOString().split('T')[0],
            end_date: response.data.start_date && new Date(response.data.end_date).toISOString().split('T')[0],
            slugs: response.data.slugs.map((slug: AdSlugProps) => ({
                label: slug.slug,
                value: slug.slug,
                data: slug
            })),
            media: {
                url: response.data.media_url,
                type: `image`,
                uuid: uuid(),
                id: response.data.id
            }
        })
    }

    useEffect(() => {

        if (adId) {
            getAdData()
        }
    }, [])


    const handleSave = async () => {
        const trigger = await adTrigger()

        if (!trigger) {
            return
        }

        const adPayload = cloneDeep(adGetValues())

        console.log(adPayload.start_date, adPayload.end_date)
        if (adPayload.start_date) {
            adPayload.start_date = new Date(adPayload.start_date)
        }
        if (adPayload.end_date) {
            adPayload.end_date = new Date(adPayload.end_date)
        }

        adPayload.media = adPayload.media.file
        adPayload._slugs = adPayload.slugs.map((item: any) => ({slug: item?.label}))

        delete adPayload.slugs
        delete adPayload.media_type_id
        delete adPayload.id
        console.log({adPayload})

        const formattedPayload = serialize(adPayload, {indices: true, booleansAsIntegers: true})

        let adResponse: any
        setIsSaving(true)
        if (adId) {
            adResponse = await updateAd(adId, formattedPayload)
        } else {
            adResponse = await createAd(formattedPayload)
        }
        if (adResponse.status !== 201 && adResponse.status !== 200) {
            setIsSaving(false)
            return toast({
                status: "error",
                title: "Erro ao salvor anúncios",
                description: adResponse.data.message || "Houve um erro salvor o anúncios, por favor tente novamente",
                isClosable: true
            })
        }
        setIsSaving(false)

        navigate('/ads')
        return toast({
            status: "success",
            title: "Destaque salvo com sucesso",
            description: adResponse.data.message || "O anúncios foi salvo com sucesso",
            isClosable: true
        })

    }
    const handleCancel = () => {
        return navigate("/ads")
    }

    return (
        <>
            <Dialog
                onAccept={handleSave}
                isOpen={saveDialogIsOpen}
                onClose={saveDialogOnClose}
                title={"Salvar anúncio"}
                description={"Ao confirmar, o anúncio será salvo"}
            />
            <CancelItemBox title={"Voltar para lista de anúncios"} onClick={() => navigate('/ads')} />
            <Accordion title={"Dados do anúncio"} defaultExpanded={true}>
                <CreateAndEditBox control={adControl as Control<any>} />
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => handleCancel()}>Voltar</Button>
                    <Button className={`!bg-red-500 !text-white`} onClick={() => saveDialogOnOpen()}> Salvar {isSaving && <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                </Flex>
            </Container>
        </>
    )
}