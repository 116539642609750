import {api} from "./axios";
import {UserProps} from "../types";

export const getInvoices = async (params?: any) => {
    return await api.get(`/invoice`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getInvoiceById = async (invoiceId?: number) => {
    return await api.get(`/invoice/${invoiceId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateInvoice = async (invoiceId: number, payload?: any) => {
    return await api.put(`/invoice/${invoiceId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createInvoice = async (payload?: any) => {
    return await api.post(`/invoice`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteInvoice = async (invoiceId: number) => {
    return await api.delete(`/invoice/${invoiceId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const cancelInvoice = async (invoiceId: number) => {
    return await api.post(`/invoice/${invoiceId}/cancel`, {
    }).catch(({response}) => {
        return response
    })
}
export const finalizeInvoice = async (invoiceId: number) => {
    return await api.post(`/invoice/${invoiceId}/finalize`, {
    }).catch(({response}) => {
        return response
    })
}