import {CancelItemBox} from "../../../components/CancelItemBox";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control} from "react-hook-form";
import {RefundProps, ProductInHighlight, SelectOptionProps} from "../../../types";
import {getRefundById} from "../../../services/refunds";
import {RefundBox} from "./components/RefundBox";
import {Container} from "../../../components/Container";
import {Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {Button} from "../../../components/Button";
import {Dialog} from "../../../components/Dialog";
import {createRefund} from "../../../services/refunds";

export function RefundDetails () {
    const params = useParams()
    const refundId = Number(params.refundId)
    const navigate = useNavigate()
    const [refund, setRefund] = useState<RefundProps>({} as RefundProps)

    const getRefundData = async () => {
        const response = await getRefundById(refundId)
        console.log({data: response.data})
        setRefund({
            ...response.data
        })
    }

    useEffect(() => {
        getRefundData()
    }, [])

    return (
        <>
            <CancelItemBox title={"Voltar para lista de estornos"} onClick={() => navigate('/refunds')} />
            <Accordion title={"Dados do estorno"} defaultExpanded={true} >
                <RefundBox values={refund} />
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`}  onClick={() => navigate("/refunds")}>Voltar</Button>
                </Flex>
            </Container>
        </>
    )
}