import {api} from "./axios";
import {UserProps} from "../types";

export const getPayments = async (params?: any) => {
    return await api.get(`/payment`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getPaymentById = async (paymentId?: number) => {
    return await api.get(`/payment/${paymentId}/status`, {
    }).catch(({response}) => {
        return response
    })
}
export const updatePayment = async (paymentId: number, payload?: any) => {
    return await api.put(`/payment/${paymentId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createPayment = async (payload?: any) => {
    return await api.post(`/payment`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deletePayment = async (paymentId: number) => {
    return await api.delete(`/payment/${paymentId}`, {
    }).catch(({response}) => {
        return response
    })
}