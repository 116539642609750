import {SimpleGrid} from "@chakra-ui/react";
import {Input} from "../../../../../components/Input";
import React from "react";
import { Control } from "react-hook-form";
import {MediaInput} from "../../../../../components/MediaInput";
import {Textarea} from "../../../../../components/Textarea";

type CreateAndEditBoxProps = {
    control: Control<any>
    isEditing?: boolean
    isLoading?: boolean
    onSelectInvalidType?: () => void
}

export function CreateAndEditBox({control, isEditing, onSelectInvalidType, isLoading}: CreateAndEditBoxProps) {
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Input control={control} name={'name'} labelClassName={"flex-1"}
                   label={"Nome *"} placeholder={"Digite o nome *"}
            />
            <Input control={control} type={"number"} name={'discount_percent'} labelClassName={"flex-1"}
                   label={"Desconto *"} placeholder={"Desconto *"}
            />
            <Input control={control} type={"date"} name={'available_at'} labelClassName={"flex-1"}
                   label={"Disponível em *"} placeholder={"Disponível em *"}
            />
            <Input control={control} type={"date"} name={'expire_at'} labelClassName={"flex-1"}
                   label={"Expira em *"} placeholder={"Expira em *"}
            />
            <Textarea control={control} name={'description'} labelClassName={"flex-1 col-span-2"}
                       label={"Descrição"} placeholder={"Descrição"}
            />
        </SimpleGrid>
    )
}