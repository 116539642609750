import {Box, Flex, SimpleGrid, Stack} from "@chakra-ui/react";
import React from "react";
import {Control} from "react-hook-form";
import {IoMdAdd} from "react-icons/io"
import {List} from "../../../../../components/List";
import {listColumns} from "./listColumns";
import {OrderProps} from "../../../../../types";
import {Text} from "../../../../../components/Text";
import {Link} from "../../../../../components/Link";
import {useNavigate} from "react-router-dom";
import {currencyFormat} from "../../../../../helpers";

type ProductsBoxProps = {
    isEditing?: boolean
    isLoading?: boolean
    onSelectInvalidType?: () => void
    onAddProduct?: () => void
    onRemoveProduct?: (productId: number) => void
    values: OrderProps
}

export function PaymentsBox({
                                values,
                                isEditing,
                                onSelectInvalidType,
                                onAddProduct,
                                onRemoveProduct,
                                isLoading
                            }: ProductsBoxProps) {
    const navigate = useNavigate()
    return (
        <>
            {values?.payments?.map((payment) => {
                return (
                    <Stack spacing={1} className={"flex-1 p-2 bg-gray-200 dark:bg-neutral-800 text-sm"}>
                        <Text><b>ID :</b> <Link
                            onClick={() => navigate(`/payments/${payment?.id}`)}>{payment?.id}</Link></Text>
                        <Text><b>ID MAVERICK :</b> {payment?.id_maverick_payment}</Text>
                        <Text><b>STATUS :</b> {payment?.payment_status?.name}</Text>
                        <Text><b>TIPO :</b> {payment?.payment_methods?.name}</Text>
                        <Text><b>VALOR TOTAL :</b> {currencyFormat(Number(payment?.price))}</Text>
                    </Stack>
                )
            })}
        </>
    )
}