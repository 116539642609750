import {SimpleGrid} from "@chakra-ui/react";
import {Input} from "../../components/Input";
import {Control, UseFormResetField} from "react-hook-form";
import {Select} from "../../components/Select";
type SearchBoxProps = {
    control: Control<any>
}
export function SearchBox({control}: SearchBoxProps) {
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Input control={control} type={"date"} name={'start_date'} labelClassName={"flex-1"}
                   label={"Data inicial de criação do administrador"}
            />
            <Input control={control} type={"date"} name={'end_date'} labelClassName={"flex-1"}
                   label={"Data final de criação do administrador"}
            />
            <Input control={control} name={'search'} labelClassName={"flex-1"}
                   placeholder={"Busque por alguma coisa"} label={"Busque por alguma coisa"}
            />
            <Select control={control} labelClassName={"flex-1"} name={"status"} label={"Status do administrador"}
                    placeholder={"Selecione um status"} />
            <Select control={control} labelClassName={"flex-1"} name={"country"} label={"País"}
                    placeholder={"Selecione um país"}
            />
            <Select control={control} labelClassName={"flex-1"} name={"state"} label={"Estado"}
                    placeholder={"Selecione um estado"}
            />
        </SimpleGrid>
    )
}