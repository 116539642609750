import {Accordion} from "../../components/Accordion";
import {
    CategoryProps,
    DepartmentProps,
    PaginationInfoProps,
    SelectOptionProps,
    SubcategoryProps,
    UserProps
} from "../../types";
import { Table } from "../../components/Table";
import {tableColumns} from "./tableColumns"
import {deleteSubcategory, getSubcategories} from "../../services/subcategories";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Control, FieldValues, useForm} from "react-hook-form";
import {SearchBox} from "./searchBox";
import {NewItemBox} from "../../components/NewItemBox";
import {useNavigate} from "react-router-dom";
import {useDisclosure, useToast} from "@chakra-ui/react";
import {Dialog} from "../../components/Dialog";
import {getCategories as getAllCategories} from "../../services/categories";
import {getDepartments as getAllDepartments} from "../../services/departments";

export function Subcategories() {
    const {onOpen: deleteDialogOnOpen, isOpen: deleteDialogIsOpen, onClose: deleteDialogOnClose} = useDisclosure()
    const [deleteId, setDeleteId] = useState(0)
    // const [dataIsLoading, setDataIsLoading] = useState(false)
    const dataIsLoading = useRef(false)
    const toast = useToast()
    const navigate = useNavigate()
    const {
        control,
        watch,
        getValues,
        resetField
    } = useForm()
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfoProps>({
        per_page: 10,
        page: 1,
        last_page: 0,
        total: 0,
        to: 0
    })

    const [data, setData] = useState<SubcategoryProps[]>([] as SubcategoryProps[])
    const [departments, setDepartments] = useState<SelectOptionProps[]>([])
    const [categories, setCategories] = useState<SelectOptionProps[]>([])

    const getCategories = async (departmentId: number) => {
        const response = await getAllCategories(departmentId)
        if (response.status !== 200) {
            setCategories([])
            setData([])
            if (resetField) {
                resetField('category', {defaultValue: null})
            }
            return
        }
        setCategories(response.data.map((category: CategoryProps) => {
            return {
                value: category.id,
                label: category.name
            }
        }))
        if (resetField) {
            resetField('category', {defaultValue: {value: response.data[0].id, label: response.data[0].name}})
        }
    }

    const getDepartments = async () => {
        const response = await getAllDepartments()
        if (response.isAxiosError) {
            return
        }
        setDepartments(response.data.map((department: DepartmentProps) => {
            return {
                value: department.id,
                label: department.name
            }
        }))
        if (resetField) {
            resetField('department', {defaultValue: {value: response.data[0].id, label: response.data[0].name}})
        }
    }

    const getData = async (page?: number) => {
        const payload = getValues()
        dataIsLoading.current = true
        const response = await getSubcategories(payload.category.value, {
            per_page: paginationInfo.per_page,
            page: page ? page : paginationInfo.page,
            search: payload.search
        })
        dataIsLoading.current = false
        if (response.status !== 200) {
            setData([])
            return
        }
        setData(response.data.map((subcategory: UserProps) => {
            subcategory.created_at = new Date(subcategory.created_at).toLocaleString('pt-br')
            if (subcategory.updated_at) {
                subcategory.updated_at = new Date(subcategory.updated_at).toLocaleString('pt-br')
            }
            return subcategory
        }))
        setPaginationInfo(oldValue => {
            return {
                ...oldValue,
                last_page: response.data.total_pages,
                total: response.data.total_pages,
                to: response.data.total_count,
                page: response.data.page
            }
        })
    }
    const onPageChange = (page: number) => {
        getData(page + 1)
    }

    const watchCalled = useRef(false)
    useEffect(() => {
        if (watch && !watchCalled.current) {
            watchCalled.current = true
            watch((data, {type, name}) => {
                const firstCall = type === undefined && name === undefined

                if (firstCall) {
                }

                if (data?.category?.value && !dataIsLoading.current) {
                    getData(1)
                }

                if (name === "department") {
                    getCategories(data.department.value)
                }
            })
        }
    }, [])

    useEffect(() => {
        getDepartments()
    }, [])

    const handleEdit = (subcategoryId: number) => {
        return navigate(`/subcategories/${subcategoryId}`)
    }
    const handleDelete = async () => {
        const response = await deleteSubcategory(deleteId)
        if (response?.status !== 204) {
            return toast({
                status: "error",
                title: "Erro ao deletar subcategoria",
                description: response.data.message || "Houve um erro deletar a subcategoria, por favor tente novamente",
                isClosable: true
            })
        }
        getData()
        return toast({
            status: "success",
            title: "Subcategoria deletada com sucesso",
            description: response.data.message || "A subcategoria foi deletada com sucesso",
            isClosable: true
        })
    }
    return (
        <>
            <Dialog
                onAccept={handleDelete}
                isOpen={deleteDialogIsOpen}
                onClose={deleteDialogOnClose}
                title={"Deletar subcategoria"}
                description={"Ao confirmar, a subcategoria será deletada"}
            />
            <NewItemBox title={"Nova subcategoria"} className={""} onClick={() => navigate(`/subcategories/create`)} />
            <Accordion title={"Digite sua busca"} defaultExpanded={false} className={""}>
                <SearchBox departments={departments} categories={categories} control={control} />
            </Accordion>
            <Accordion title={"Subcategorias"} panelClassName={`overflow-auto max-h-screen`}>
                <Table data={data} columns={tableColumns({
                    onEdit: handleEdit,
                    onDelete:(subcategoryId) => {
                        setDeleteId(subcategoryId)
                        deleteDialogOnOpen()
                    }
                })} onPageChange={onPageChange} pageCount={paginationInfo.last_page} />
            </Accordion>
        </>
    )
}