import {SimpleGrid} from "@chakra-ui/react";
import {Input} from "../../../../../components/Input";
import React, {useEffect, useState} from "react";
import {Control, UseFormResetField, UseFormWatch} from "react-hook-form";
import {MediaInput} from "../../../../../components/MediaInput";
import {getDepartments as getAllDepartments} from "../../../../../services/departments";
import {CategoryProps, DepartmentProps, FilterProps, SelectOptionProps, UserProps} from "../../../../../types";
import {Select} from "../../../../../components/Select";
import {List} from "../../../../../components/List";

type CreateAndEditBoxProps = {
    control: Control<any>
    isEditing?: boolean
    isLoading?: boolean
    onSelectInvalidType?: () => void
    departments?: SelectOptionProps[]
    values: FilterProps
}
const types = [
    {value: "string", label: "String"},
    {value: "boolean", label: "Boolean"}
]
export function CreateAndEditBox({values, control, isEditing, departments, onSelectInvalidType, isLoading}: CreateAndEditBoxProps) {
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Select control={control} labelClassName={"flex-1 col-span-2"} options={departments} name={"department"} label={"Departamento *"}
                    placeholder={"Selecione um departamento *"}
            />
            <Input control={control} name={'name'} labelClassName={"flex-1"}
                   label={"Nome *"} placeholder={"Digite o nome *"}
            />
            <Select control={control} labelClassName={"flex-1"} options={types} name={"type"} label={"Tipo *"}
                    placeholder={"Selecione um tipo *"}
            />
        </SimpleGrid>
    )
}