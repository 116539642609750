import {SimpleGrid} from "@chakra-ui/react";
import {Input} from "../../components/Input";
import {Control, UseFormResetField} from "react-hook-form";
import {Select} from "../../components/Select";
import {useEffect, useState} from "react";
import {CategoryProps, DepartmentProps, SelectOptionProps} from "../../types";
import {getDepartments as getAllDepartments} from "../../services/departments"
import {getCategories as getAllCategories} from "../../services/categories";
type SearchBoxProps = {
    control: Control<any>
    values?: Partial<CategoryProps>
    resetField?: UseFormResetField<CategoryProps>
    departments: SelectOptionProps[]
    categories: SelectOptionProps[]
}
export function SearchBox({control, departments, categories, resetField}: SearchBoxProps) {
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Input control={control} name={'search'} labelClassName={"flex-1 col-span-2"}
                   placeholder={"Busque por alguma coisa"} label={"Busque por alguma coisa"}
            />
            <Select control={control} labelClassName={"flex-1"} options={departments} name={"department"} label={"Departamento"}
                    placeholder={"Selecione um departamento"}
            />
            <Select control={control} labelClassName={"flex-1"} options={categories} name={"category"} label={"Categoria"}
                    placeholder={"Selecione uma categoria"}
            />
        </SimpleGrid>
    )
}