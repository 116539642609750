import {Flex, SimpleGrid, Stack} from "@chakra-ui/react";
import React from "react";
import { Control } from "react-hook-form";
import {IoMdAdd} from "react-icons/io"
import {List} from "../../../../../components/List";
import {CartProps} from "../../../../../types";
import {Text} from "../../../../../components/Text";
import {currencyFormat} from "../../../../../helpers";

type ProductsBoxProps = {
    isEditing?: boolean
    isLoading?: boolean
    onSelectInvalidType?: () => void
    onAddProduct?: () => void
    onRemoveProduct?: (productId: number) => void
    values: CartProps
}

export function CartBox({values, isEditing, onSelectInvalidType, onAddProduct, onRemoveProduct, isLoading}: ProductsBoxProps) {
    return (
        <Stack spacing={1} className={"flex-1 p-2 bg-gray-200 dark:bg-neutral-800 text-sm"}>
            <Text><b>ID :</b> {values?.id}</Text>
            <Text><b>ID CLIENTE :</b> {values?.id_client}</Text>
            <Text><b>STATUS :</b> {values?.shopping_cart_status?.name}</Text>
            <Text><b>VALOR TOTAL :</b> {currencyFormat(values?.total_price)}</Text>
            <Text><b>PRODUTOS :</b> {values?.shopping_cart_products?.length}</Text>
        </Stack>
    )
}