import BackgroundImage from "../../assets/templates/default/login/background.jpg";
import StepWizard from "react-step-wizard";
import {SignIn} from "./steps/SignIn";
import {EmailConfirm} from "./steps/EmailConfirm";
import {CodeConfirm} from "./steps/CodeConfirm";

export function Login() {
    return (
        <div className={"w-full h-screen flex flex-col md:flex-row overflow-y"}>
            <div className={"md:w-3/5 md:!h-full z-50 bg-cover bg-center"} style={{backgroundBlendMode: "color", backgroundColor: `rgba(0, 0, 0, .8)`, backgroundImage: `url(${BackgroundImage})`, minHeight: `30%`}}>
            </div>
            <div className={"p-5 flex-1 md:w-2/5 md:max-w-2/5 md:h-full !min-h-max flex items-center"} >
                <StepWizard className={"w-full"}>
                    <SignIn />
                    <EmailConfirm />
                    <CodeConfirm />
                </StepWizard>
            </div>
        </div>
    )
}