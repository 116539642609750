import {ColumnProps} from "../../types/table";
import {Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import React from "react";
type TableColumnsProps = {
    onDelete: (administratorId: number) => void
    onEdit: (administratorId: number) => void
}
export const tableColumns = ({onDelete, onEdit}: TableColumnsProps): ColumnProps[] => {
    return [
        {
            header: "AÇÕES",
            cell: (info) => {
                return (
                    <Flex gap={2}>
                        <AiFillEdit className={`cursor-pointer`} onClick={() => onEdit(info.row.original.id)} />
                        <AiFillDelete className={`cursor-pointer fill-red-500`} onClick={() => onDelete(info.row.original.id)} />
                    </Flex>
                )
            }
        },
        {
            id: "id",
            header: "ID"
        },
        {
            id: "name",
            header: "NOME"
        },
        {
            id: "type",
            header: "TIPO"
        },
        {
            id: "products_length",
            header: "PRODUTOS",
            cell: info => info.row.original?.product_final_has_filter_and_value?.length
        },
        {
            id: "values_length",
            header: "OPÇÕES",
            cell: info => info.row.original?.type === "string" && info.row.original?.product_department_filter_values?.length
        },
        {
            id: "departament",
            header: "DEPARTAMENTO",
            cell: info => info.row.original?.product_departments?.name
        },
        {
            id: "created_at",
            header: "CRIADO EM"
        },
        {
            id: "updated_at",
            header: "ATUALIZADO EM"
        }
    ]
}