import {api} from "./axios";
import {UserProps} from "../types";

export const getClients = async (params?: any) => {
    return await api.get(`/client`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getClientById = async (clientId?: number) => {
    return await api.get(`/client/${clientId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateClient = async (clientId: number, payload?: any) => {
    return await api.put(`/client/${clientId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createClient = async (payload?: any) => {
    return await api.post(`/client`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteClient = async (clientId: number) => {
    return await api.delete(`/client/${clientId}`, {
    }).catch(({response}) => {
        return response
    })
}