import {api} from "./axios";
import {UserProps} from "../types";

export const getManufacturers = async (params?: any) => {
    return await api.get(`/product/manufacturer`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getManufacturerById = async (manufacturerId?: number) => {
    return await api.get(`/product/manufacturer/${manufacturerId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateManufacturer = async (manufacturerId: number, payload?: any) => {
    return await api.put(`/product/manufacturer/${manufacturerId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createManufacturer = async (payload?: any) => {
    return await api.post(`/product/manufacturer`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteManufacturer = async (manufacturerId: number) => {
    return await api.delete(`/product/manufacturer/${manufacturerId}`, {
    }).catch(({response}) => {
        return response
    })
}