import {Button as ChakraUiButton, ButtonProps as ChakraUiButtonProps} from "@chakra-ui/react"
import {HTMLProps} from "react";
type ButtonProps = (HTMLProps<HTMLButtonElement> & ChakraUiButtonProps) & {

}

export function Button({children, ...rest}: ButtonProps) {
    return (
        <ChakraUiButton {...rest}>
            {children}
        </ChakraUiButton>
    )
}