import {Container} from "../../../components/Container";
import {Box, Flex, Text} from "@chakra-ui/react";
import {AiFillEdit} from "react-icons/ai";
import {RiLogoutBoxRFill} from "react-icons/ri";
import React from "react";
import {useUser} from "../../../hooks/useUser";
import {UserProps} from "../../../types";
import {useNavigate} from "react-router-dom";

export function TopNav() {
    const navigate = useNavigate()
    const {user, storeUser} = useUser()
    const handleEditUser = () => {
        switch (user()?.type) {
            case "owner":
                return navigate(`/owners/${user()?.id}`)
            case "administrator":
                return navigate(`/administrators/${user()?.id}`)
        }
    }
    const handleLogout = () => {
        storeUser({} as UserProps)
        localStorage.removeItem("Find@SelectedFindPage")
        return navigate('/login')
    }

    return (
        <Container>
            <Flex className={"items-center justify-end gap-2"}>
                <Text className={"font-medium"}>Seja bem vindo, {user()?.first_name}</Text>
                <Box className={`p-1 text-white rounded-full bg-red-500 cursor-pointer`} onClick={handleEditUser}>
                    <AiFillEdit/>
                </Box>
                <Box className={`p-1 text-white rounded-full bg-neutral-800 cursor-pointer`} onClick={handleLogout}>
                    <RiLogoutBoxRFill/>
                </Box>
                <Box
                    className={"block h-12 w-12 rounded-full bg-gray-200 dark:bg-white block bg-center bg-no-repeat bg-cover "}
                    style={{minHeight: `3rem`, minWidth: `3rem`}}
                />
                {/*{isOpen && (*/}
                {/*    <>*/}
                {/*        <Text className={"font-bold line-clamp-1 flex-1"}>{user()?.first_name}</Text>*/}
                {/*        <Box className={`p-1 rounded-full bg-red-500 cursor-pointer`} onClick={handleEditUser}>*/}
                {/*            <AiFillEdit />*/}
                {/*        </Box>*/}
                {/*        <Box className={`p-1 rounded-full bg-neutral-800 cursor-pointer`} onClick={logout}>*/}
                {/*            <RiLogoutBoxRFill />*/}
                {/*        </Box>*/}
                {/*    </>*/}
                {/*)}*/}
            </Flex>
        </Container>
    )
}