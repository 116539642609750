import {api} from "./axios";
import {UserProps} from "../types";

export const getStock = async (params?: any) => {
    return await api.get(`/stock`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const stockIn = async (productId: number, quantity: number) => {
    return await api.post(`/stock/input/product/${productId}`, {quantity}, {
    }).catch(({response}) => {
        return response
    })
}
export const stockOut = async (productId: number, quantity: number) => {
    return await api.post(`/stock/output/product/${productId}`, {quantity}, {
    }).catch(({response}) => {
        return response
    })
}
export const getStockById = async (stockId?: number) => {
    return await api.get(`/stock/${stockId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateStock = async (stockId: number, payload?: any) => {
    return await api.put(`/product/stock/${stockId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createStock = async (payload?: any) => {
    return await api.post(`/product/stock`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteStock = async (stockId: number) => {
    return await api.delete(`/product/stock/${stockId}`, {
    }).catch(({response}) => {
        return response
    })
}