import {ColumnProps} from "../../types/table";
import {Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import {MdOutlineViewHeadline} from "react-icons/md"
import {BiBlock} from "react-icons/bi"
import {FcApproval} from "react-icons/fc"
import React from "react";
import {currencyFormat} from "../../helpers";
import {DropdownMenu} from "../../components/DropdownMenu";
type TableColumnsProps = {
    onDelete: (orderId: number) => void
    onEdit: (orderId: number) => void
    onDetail?: (orderId: number) => void
    onGenerateLabels: (orderId: number) => void
    onPost: (orderId: number) => void
}
export const tableColumns = ({onDelete, onDetail, onGenerateLabels, onPost}: TableColumnsProps): ColumnProps[] => {
    return [
        {
            header: "AÇÕES",
            cell: (info) => {
                const options = [
                    {
                        label: "Details",
                        onClick: () => onDetail && onDetail(info.row.original.id)
                    },

                ]

                if (!info.row.original?.id_shipengine_label && info.row.original?.id_shipengine_shipment) {
                    options.push({
                        label: "Generate labels",
                        onClick: () => onGenerateLabels(info.row.original.id)
                    })
                }

                if (info.row.original?.id_delivery_status === 2) {
                    options.push({
                        label: "Post this order",
                        onClick: () => onPost(info.row.original.id)
                    })
                }
                return (

                    <Flex gap={2}>
                        <DropdownMenu options={options}/>
                    </Flex>
                )
            }
        },
        {
            id: "id",
            header: "ID"
        },
        {
            id: "id_cart",
            header: "CARRINHO"
        },
        {
            id: "status_name",
            header: "STATUS",
            cell: (info) => `${info.row.original.order_status?.name}`
        },
        {
            id: "delivery_status.name",
            header: "DELIVERY",
            cell: (info) => `${info.row.original.delivery_status?.name}`
        },
        {
            id: "client_id",
            header: "ID CLIENTE",
            cell: (info) => `${info.row.original.clients.id}`
        },
        {
            id: "client_name",
            header: "CLIENTE",
            cell: (info) => `${info.row.original.clients.first_name} ${info.row.original.clients.last_name}`
        },
        {
            id: "price",
            header: "VALOR TOTAL",
            cell: (info) => currencyFormat(Number(info.row.original.price))
        },
        {
            id: "price",
            header: "VALOR PRODUTOS",
            cell: (info) => currencyFormat((Number(info.row.original.price) - Number(info.row.original.tax_price)) - Number(info.row.original.delivery_price))
        },
        {
            id: "tax_price",
            header: "VALOR TAXA",
            cell: (info) => currencyFormat(Number(info.row.original.tax_price))
        },
        {
            id: "delivery_price",
            header: "VALOR FRETE",
            cell: (info) => currencyFormat(Number(info.row.original.delivery_price))
        },
        {
            id: "id_shipengine_shipment",
            header: "ID SHIPMENT"
        },
        {
            id: "id_shipengine_label",
            header: "ID LABEL"
        },
        {
            id: "created_at",
            header: "CRIADO EM"
        },
        {
            id: "updated_at",
            header: "ATUALIZADO EM"
        }
    ]
}