import {Carts as CartsTemplate} from "../../templates/Carts"
import {CartDetails as CartDetailsTemplate} from "../../templates/Carts/Details";

export function Carts() {
    if (window.location.pathname.includes("/carts/") && window.location.pathname !== "/carts") {
        return <CartDetailsTemplate />
    }
    return (
        <CartsTemplate />
    )
}