import {Flex, SimpleGrid} from "@chakra-ui/react";
import {Input} from "../../../../../components/Input";
import {Control} from "react-hook-form";
import {Select} from "../../../../../components/Select";
import React from "react";
import {MediaInput} from "../../../../../components/MediaInput";
import {FilterProps, ProductProps, SelectOptionProps} from "../../../../../types";
import {Text} from "../../../../../components/Text";
import {AiFillCloseCircle} from "react-icons/ai";
import {InputMask} from "../../../../../components/InputMask";

type SearchBoxProps = {
    control: Control<any>
    isEditing?: boolean
    isLoading?: boolean
    onSelectInvalidType?: () => void
    onRemoveProduct: (variantIndex: number, index: number) => void
    values?: ProductProps
    attributeValue: string
    filters?: any
    index: number
    length: number
}

const Product = (
    {
        i,
        onRemoveProduct,
        index,
        values,
        control,
        withQuantity
    }: {
        i: number,
        onRemoveProduct: (variantIndex: number, index: number) => void,
        index: number,
        values: ProductProps,
        control: Control<any>,
        withQuantity?: boolean
    }) => {

    return (
        <SimpleGrid key={i} columns={2} className={"flex-1 gap-2 bg-gray-100 dark:bg-neutral-800 p-2 rounded-md col-span-2"}>
            <Flex className={`col-span-2 justify-between`}>
                <Text className={`font-medium`}>Versão  {i + 1}</Text>
                <AiFillCloseCircle className={`cursor-pointer fill-red-500`} onClick={() => onRemoveProduct(index, i)}/>
            </Flex>
            {values?.filters?.map((filter: Partial<FilterProps> & SelectOptionProps, key: number) => {
                if (filter.type === "string") {
                    return (
                        <Select control={control} label={`${filter.name} *`} placeholder={`${filter.name} *`}
                                name={`product_variant.${index}.product_final.${i}.filters[${key}]`}
                                options={filter?.product_department_filter_values?.map((value) => {
                                    return {
                                        id: filter.id,
                                        id_filter: filter.id,
                                        id_value: value.id,
                                        value: value.id,
                                        label: `${value.value} ${value.termination ? value.termination : ''}`
                                    }
                                })}/>
                    )
                }
            })}
            {values?.filters?.filter((item: Partial<FilterProps> & SelectOptionProps) => item.type !== "string")?.length > 0 && (
                <Select isMulti={true} label={"Outras opções"} control={control} defaultValue={values?.boolean_filters} labelClassName={"col-span-2"}
                        placeholder={"Outras opções"} name={`product_variant.${index}.product_final.${i}.boolean_filters`}
                        options={values?.filters?.filter((item: Partial<FilterProps> & SelectOptionProps) => item.type !== "string").map((filter: Partial<FilterProps> & SelectOptionProps, index: number) => {
                            return {
                                ...filter,
                                value: filter.id,
                                id_filter: filter.id,
                                label: filter.name
                            }
                        })}/>
            )}
            <InputMask mask={"currency"} label={"Preço *"} control={control} labelClassName={`${!withQuantity ? `col-span-2` : ``}`}
                   placeholder={"Preço *"} name={`product_variant.${index}.product_final.${i}.price`} />
            {withQuantity && (
                <Input type={"number"} label={"Quantidade inicial *"} control={control} labelClassName={""}
                       placeholder={"Quantidade inicial *"} name={`product_variant.${index}.product_final.${i}.quantity`} />
            )}
        </SimpleGrid>
    )
}

export function ProductBox({
                               values,
                               control,
                               attributeValue,
                               index,
                               length,
                               onRemoveProduct,
                               isEditing,
                               onSelectInvalidType,
                               isLoading
                           }: SearchBoxProps) {

    return (
        <>
            <Input control={control} name={`product_variant.${index}.attribute_value`} defaultValue={attributeValue} labelClassName={"flex-1 col-span-2 hidden"} />
            <MediaInput control={control} onSelectInvalidType={onSelectInvalidType}
                        name={`product_variant.${index}.medias`} labelClassName={"flex-1 col-span-2"}
                        label={"Fotos do produto"}/>
            {values?.product_variant[index]?.product_final?.length > 0 ? values?.product_variant[index]?.product_final?.map((item: any, i: number) => {
                return (
                    <Product
                        i={i}
                        withQuantity={!item?.id}
                        onRemoveProduct={onRemoveProduct}
                        control={control}
                        index={index}
                        values={values}
                    />
                )
            }) : (
                <Product
                    i={0}
                    withQuantity={true}
                    onRemoveProduct={onRemoveProduct}
                    control={control}
                    index={index}
                    values={values || {} as ProductProps}
                />
                )
            }
        </>
    )
}