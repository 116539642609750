import {Textarea as ChakraUiTextarea, TextareaProps as ChakraUiTextareaProps, Text} from '@chakra-ui/react'
import {Control, useController, UseControllerProps, useForm} from "react-hook-form";
import {useEffect} from "react";
type TextareaProps = (ChakraUiTextareaProps & Partial<UseControllerProps>) & {
    label?: string
    labelClassName?: string
    name?: string
}
export function Textarea({label, labelClassName, name = "", ...textarea}: TextareaProps) {
    const {control: controlDefault} = useForm()
    const {fieldState, field} = useController({
        control: textarea.control || controlDefault,
        rules: textarea.rules,
        name: name,
        defaultValue: textarea.defaultValue
    })
    return (
        <>
            <label className={`flex flex-col ${labelClassName ? labelClassName : ``}`}>
                {label && (
                    <Text className={"mb-2 whitespace-nowrap overflow-ellipsis overflow-hidden max-w-full"}>{label}</Text>
                )}
                <ChakraUiTextarea
                    {...textarea}
                    {...field}
                    className={`!border-gray-300 p-1 ${!!textarea.className && textarea.className} placeholder:text-black dark:placeholder:text-white`}
                    onChange={(e) => {
                        // console.log({e})
                        if (textarea.onChange) {
                            textarea.onChange(e)
                        }
                        field.onChange(e)
                    }}
                />
            </label>
        </>
    )
}