import {Accordion} from "../../components/Accordion";
import {PaginationInfoProps, CartProps} from "../../types";
import { Table } from "../../components/Table";
import {tableColumns} from "./tableColumns"
import {deleteCart, getCarts} from "../../services/carts";
import React, {useEffect, useState} from "react";
import {Control, FieldValues, useForm} from "react-hook-form";
import {SearchBox} from "./searchBox";
import {NewItemBox} from "../../components/NewItemBox";
import {useNavigate} from "react-router-dom";
import {useDisclosure, useToast} from "@chakra-ui/react";
import {Dialog} from "../../components/Dialog";

export function Carts() {
    const {onOpen: deleteDialogOnOpen, isOpen: deleteDialogIsOpen, onClose: deleteDialogOnClose} = useDisclosure()
    const [deleteId, setDeleteId] = useState(0)
    const toast = useToast()
    const navigate = useNavigate()
    const {
        control,
        watch
    } = useForm()
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfoProps>({
        per_page: 10,
        page: 1,
        last_page: 0,
        total: 0,
        to: 0
    })

    const [data, setData] = useState<CartProps[]>([] as CartProps[])

    useEffect(() => {
    }, [data])

    const getData = async (page?: number) => {
        const response = await getCarts({
            per_page: paginationInfo.per_page,
            page: page ? page : paginationInfo.page
        })
        if (response.isAxiosError) {
            return
        }
        setData(response.data.carts.map((cart: CartProps) => {
            cart.created_at = new Date(cart.created_at).toLocaleString('pt-br')
            if (cart.updated_at) {
                cart.updated_at = new Date(cart.updated_at).toLocaleString('pt-br')
            }
            return cart
        }))
        setPaginationInfo(oldValue => {
            return {
                ...oldValue,
                last_page: response.data.total_pages,
                total: response.data.total_pages,
                to: response.data.total_count,
                page: response.data.page
            }
        })
    }
    const onPageChange = (page: number) => {
        getData(page + 1)
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        watch((data, {name}) => {
            console.log({data, name})
        })
    }, [])
    const handleEdit = (cartId: number) => {
        return navigate(`/carts/${cartId}`)
    }
    const handleDetail = (cartId: number) => {
        return navigate(`/carts/${cartId}`)
    }
    const handleDelete = async () => {
        const response = await deleteCart(deleteId)
        if (response?.status !== 204) {
            return toast({
                status: "error",
                title: "Erro ao deletar carrinho",
                description: response.data.message || "Houve um erro deletar o carrinho, por favor tente novamente",
                isClosable: true
            })
        }
        getData()
        return toast({
            status: "success",
            title: "Carrinho deletado com sucesso",
            description: response.data.message || "O carrinho foi deletado com sucesso",
            isClosable: true
        })
    }
    return (
        <>
            <Dialog
                onAccept={handleDelete}
                isOpen={deleteDialogIsOpen}
                onClose={deleteDialogOnClose}
                title={"Deletar carrinho"}
                description={"Ao confirmar, o carrinho será deletado"}
            />
            <Accordion title={"Digite sua busca"} defaultExpanded={false} className={""}>
                <SearchBox control={control} />
            </Accordion>
            <Accordion title={"Carrinhos"} panelClassName={`overflow-auto max-h-screen`}>
                <Table data={data} columns={tableColumns({
                    onEdit: handleEdit,
                    onDelete:(cartId) => {
                        setDeleteId(cartId)
                        deleteDialogOnOpen()
                    },
                    onDetail: handleDetail
                })} onPageChange={onPageChange} pageCount={paginationInfo.last_page} />
            </Accordion>
        </>
    )
}