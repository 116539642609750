import {Accordion} from "../../components/Accordion";
import {PaginationInfoProps, OrderProps} from "../../types";
import { Table } from "../../components/Table";
import {tableColumns} from "./tableColumns"
import {deleteOrder, generateLabels, getOrders, postOrder} from "../../services/orders";
import React, {useEffect, useState} from "react";
import {Control, FieldValues, useForm} from "react-hook-form";
import {SearchBox} from "./searchBox";
import {NewItemBox} from "../../components/NewItemBox";
import {useNavigate} from "react-router-dom";
import {useDisclosure, useToast} from "@chakra-ui/react";
import {Dialog} from "../../components/Dialog";

export function Orders() {
    const {onOpen: deleteDialogOnOpen, isOpen: deleteDialogIsOpen, onClose: deleteDialogOnClose} = useDisclosure()
    const [deleteId, setDeleteId] = useState(0)
    const toast = useToast()
    const navigate = useNavigate()
    const {
        control,
        watch
    } = useForm()
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfoProps>({
        per_page: 10,
        page: 1,
        last_page: 0,
        total: 0,
        to: 0
    })

    const [data, setData] = useState<OrderProps[]>([] as OrderProps[])

    useEffect(() => {
    }, [data])

    const getData = async (page?: number) => {
        const response = await getOrders({
            per_page: paginationInfo.per_page,
            page: page ? page : paginationInfo.page
        })
        if (response.isAxiosError) {
            return
        }
        setData(response.data.orders.map((order: OrderProps) => {
            order.created_at = new Date(order.created_at).toLocaleString('pt-br')
            if (order.updated_at) {
                order.updated_at = new Date(order.updated_at).toLocaleString('pt-br')
            }
            return order
        }))
        setPaginationInfo(oldValue => {
            return {
                ...oldValue,
                last_page: response.data.total_pages,
                total: response.data.total_pages,
                to: response.data.total_count,
                page: response.data.page
            }
        })
    }
    const onPageChange = (page: number) => {
        getData(page + 1)
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        watch((data, {name}) => {
            console.log({data, name})
        })
    }, [])
    const handleEdit = (orderId: number) => {
        return navigate(`/orders/${orderId}`)
    }
    const handleDetail = (orderId: number) => {
        return navigate(`/orders/${orderId}`)
    }
    const handleDelete = async () => {
        const response = await deleteOrder(deleteId)
        if (response?.status !== 204) {
            return toast({
                status: "error",
                title: "Erro ao deletar pedido",
                description: response.data.message || "Houve um erro deletar o pedido, por favor tente novamente",
                isClosable: true
            })
        }
        getData()
        return toast({
            status: "success",
            title: "Pedido deletado com sucesso",
            description: response.data.message || "O pedido foi deletado com sucesso",
            isClosable: true
        })
    }

    const handleGenerateLabels = async (orderId: number) => {
        const response = await generateLabels(orderId)
        if (response?.status !== 200) {
            return toast({
                status: "error",
                title: "Erro ao gerar labels",
                description: response.data.message || "Houve um erro ao gerar labels, por favor tente novamente",
                isClosable: true
            })
        }
        getData()
        return toast({
            status: "success",
            title: "Labels gerado com sucesso",
            description: response.data.message || "O pedido foi atualizado com sucesso",
            isClosable: true
        })
    }
    const handlePost = async (orderId: number) => {
        const response = await postOrder(orderId)
        if (response?.status !== 201) {
            return toast({
                status: "error",
                title: "Erro ao postar pedido",
                description: response.data.message || "Houve um erro ao postar pedido, por favor tente novamente",
                isClosable: true
            })
        }
        getData()
        return toast({
            status: "success",
            title: "Pedido postado com sucesso",
            description: response.data.message || "O pedido foi atualizado com sucesso",
            isClosable: true
        })
    }
    return (
        <>
            <Dialog
                onAccept={handleDelete}
                isOpen={deleteDialogIsOpen}
                onClose={deleteDialogOnClose}
                title={"Deletar pedido"}
                description={"Ao confirmar, o pedido será deletado"}
            />
            <Accordion title={"Digite sua busca"} defaultExpanded={false} className={""}>
                <SearchBox control={control} />
            </Accordion>
            <Accordion title={"Pedidos"} panelClassName={`overflow-auto max-h-screen`}>
                <Table data={data} columns={tableColumns({
                    onEdit: handleEdit,
                    onDetail: handleDetail,
                    onDelete:(orderId) => {
                        setDeleteId(orderId)
                        deleteDialogOnOpen()
                    },
                    onGenerateLabels: handleGenerateLabels,
                    onPost: handlePost
                })} onPageChange={onPageChange} pageCount={paginationInfo.last_page} />
            </Accordion>
        </>
    )
}