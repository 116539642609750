import {SimpleGrid} from "@chakra-ui/react";
import {Input} from "../../../../../components/Input";
import React, {useEffect, useState} from "react";
import {Control, UseFormResetField, UseFormWatch} from "react-hook-form";
import {MediaInput} from "../../../../../components/MediaInput";
import {getDepartments as getAllDepartments} from "../../../../../services/departments";
import {CategoryProps, DepartmentProps, SelectOptionProps, UserProps} from "../../../../../types";
import {Select} from "../../../../../components/Select";

type CreateAndEditBoxProps = {
    control: Control<any>
    isEditing?: boolean
    isLoading?: boolean
    onSelectInvalidType?: () => void
    departments?: SelectOptionProps[]
    categories?: SelectOptionProps[]
}

export function CreateAndEditBox({control, isEditing, departments, categories, onSelectInvalidType, isLoading}: CreateAndEditBoxProps) {
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Select control={control} labelClassName={"flex-1 col-span-2"} options={departments} name={"department"} label={"Departamento *"}
                    placeholder={"Selecione um departamento *"}
            />
            <Select control={control} labelClassName={"flex-1 col-span-2"} options={categories} name={"category"} label={"Categoria *"}
                    placeholder={"Selecione uma categoria *"}
            />
            <Input control={control} name={'name'} labelClassName={"flex-1"}
                   label={"Nome *"} placeholder={"Digite o nome *"}
            />
            <Input control={control} name={'slug'} labelClassName={"flex-1"}
                   label={"Slug *"} placeholder={"Digite o slug *"}
            />
        </SimpleGrid>
    )
}