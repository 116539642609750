import {ColumnProps} from "../../types/table";
import {Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import {MdOutlineViewHeadline} from "react-icons/md"
import {BiBlock} from "react-icons/bi"
import {FcApproval} from "react-icons/fc"
import React from "react";
import {currencyFormat} from "../../helpers";
type TableColumnsProps = {
    onDelete: (productId: number) => void
    onEdit: (productId: number) => void
}
export const tableColumns = ({onDelete, onEdit}: TableColumnsProps): ColumnProps[] => {
    return [
        {
            header: "AÇÕES",
            cell: (info) => {
                return (
                    <Flex gap={2}>
                        <AiFillEdit className={`cursor-pointer`} onClick={() => onEdit(info.row.original.product_variants.products.id)} />
                        <AiFillDelete className={`cursor-pointer fill-red-500`} onClick={() => onDelete(info.row.original.id)} />
                    </Flex>
                )
            }
        },
        {
            id: "id_product_final",
            header: "ID",
            cell: (info) => info.row.original.product_variants.products.id
        },
        {
            id: "id",
            header: "ID FINAL",
        },
        {
            id: "name",
            header: "PRODUTO",
        },
        {
            id: "price",
            header: "VALOR",
            cell: (info) => currencyFormat(Number(info.row.original.price))
        },
        {
            id: "id_product_variant",
            header: "ID VARIANTE",
            cell: (info) => info.row.original.product_variants.id
        },
        {
            id: "manufacturer",
            header: "FABRICANTE",
            cell: (info) => info.row.original.product_variants.products.product_manufacturers.name
        },
        {
            id: "department",
            header: "DEPARTAMENTO",
            cell: (info) => info.row.original.product_variants.products.product_departments?.name
        },
        {
            id: "category",
            header: "CATEGORIA",
            cell: (info) => info.row.original.product_variants.products.product_categories?.name
        },
        {
            id: "subcategory",
            header: "SUBCATEGORIA",
            cell: (info) => info.row.original.product_variants.products.product_subcategories?.name
        },
        {
            id: "slug",
            header: "SLUG",
        },
        {
            id: "created_at",
            header: "CRIADO EM"
        },
        {
            id: "updated_at",
            header: "ATUALIZADO EM"
        }
    ]
}