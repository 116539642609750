import React from "react";

type TextLogoProps = React.HTMLProps<HTMLDivElement> & {}

export function TextLogo({...rest}: TextLogoProps) {
    return (
        <div className={`flex flex-col ${rest.className}`} {...rest}>
            <h1 className={"text-red-500 text-5xl font-light"}>SOLUTION4</h1>
            <h1 className={"text-red-500 text-5xl font-bold"}>IMPORT</h1>
        </div>
    )
}