import {api} from "./axios";
import {UserProps} from "../types";

export const getCarts = async (params?: any) => {
    return await api.get(`/shopping-cart/all`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getCartById = async (cartId?: number) => {
    return await api.get(`/shopping-cart/${cartId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateCart = async (cartId: number, payload?: any) => {
    return await api.put(`/shopping-cart/${cartId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createCart = async (payload?: any) => {
    return await api.post(`/shopping-cart`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteCart = async (cartId: number) => {
    return await api.delete(`/shopping-cart/${cartId}`, {
    }).catch(({response}) => {
        return response
    })
}