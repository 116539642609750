import {
    Modal as ChakraUiModal,
    ModalProps as ChakraUiModalProps,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, useDisclosure,
} from '@chakra-ui/react'
import {Button} from "../Button";
import React, {useEffect} from "react";

type ModalProps = React.HTMLProps<HTMLDivElement> & {
    isOpen: boolean
    onOpen?: () => void
    onClose?: () => void
    title?: React.ReactNode
    footer?: React.ReactNode
}
export function Modal({isOpen, onOpen, onClose, title, footer, children} : ModalProps) {
    const { isOpen: modalIsOpen, onOpen: modalOnOpen, onClose: modalOnClose } = useDisclosure()

    useEffect(() => {
        if (isOpen) {
            if (!modalIsOpen) {
                openModal()
            }
        } else {
            if (modalIsOpen) {
                closeModal()
            }
        }
    }, [isOpen])

    const openModal = () => {
        modalOnOpen()
        if (onOpen) {
            onOpen()
        }
    }
    const closeModal = () => {
        modalOnClose()
        if (onClose) {
            onClose()
        }
    }
    return (
        <>
            <ChakraUiModal isOpen={modalIsOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent className={`bg-white dark:bg-neutral-700`}>
                    <ModalHeader className={'!px-4'}>
                        {title && (
                            title
                        )}
                    </ModalHeader>
                    <ModalCloseButton className={`!top-4 !right-4 bg-gray-100 dark:bg-neutral-800 hover:dark:!bg-neutral-900`} />
                    <ModalBody className={'!px-4'}>
                        {children}
                    </ModalBody>

                    <ModalFooter className={'!px-4'}>
                        {footer && (
                            footer
                        )}
                    </ModalFooter>
                </ModalContent>
            </ChakraUiModal>
        </>
    )
}