import {Flex, SimpleGrid} from "@chakra-ui/react";
import {Input} from "../../../../../components/Input";
import React from "react";
import { Control } from "react-hook-form";
import {IoMdAdd} from "react-icons/io"
import {MediaInput} from "../../../../../components/MediaInput";
import {Textarea} from "../../../../../components/Textarea";
import {Select} from "../../../../../components/Select";
import {Button} from "../../../../../components/Button";
import {List} from "../../../../../components/List";
import {listColumns} from "./listColumns";
import {FilterProps, PromotionProps, SelectOptionProps} from "../../../../../types";

type ValuesBoxProps = {
    control: Control<any>
    isEditing?: boolean
    isLoading?: boolean
    onSelectInvalidType?: () => void
    onAddValue?: () => void
    onRemoveValue: (value: string) => void
    values: FilterProps
}

export function ValuesBox({control, values, isEditing, onSelectInvalidType, onAddValue, onRemoveValue, isLoading}: ValuesBoxProps) {
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Flex className={"flex-1 col-span-2 gap-2 items-end"}>
                <Input control={control} labelClassName={"flex-1"} label={"Valor *"}
                       placeholder={"Digite um valor *"} name={"value_to_add"}/>
                <Button className={'!bg-red-500'} onClick={onAddValue}><IoMdAdd className={`fill-white`} /></Button>
            </Flex>
            {values?.values?.length > 0 && (
                <List className={`col-span-2`} columns={listColumns({
                    onRemoveValue
                })} data={values?.values} />
            )}
        </SimpleGrid>
    )
}