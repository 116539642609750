import {SimpleGrid} from "@chakra-ui/react";
import {Input} from "../../components/Input";
import {Control, UseFormResetField} from "react-hook-form";
import {Select} from "../../components/Select";
import {useEffect, useState} from "react";
import {CategoryProps, DepartmentProps, SelectOptionProps} from "../../types";
import {getDepartments as getAllDepartments} from "../../services/departments";
type SearchBoxProps = {
    control: Control<any>
    values?: Partial<CategoryProps>
    resetField?: UseFormResetField<CategoryProps>
}
export function SearchBox({control, resetField}: SearchBoxProps) {
    const [departments, setDepartments] = useState<SelectOptionProps[]>([])
    const getDepartments = async () => {
        const response = await getAllDepartments()
        if (response.isAxiosError) {
            return
        }
        setDepartments(response.data.map((department: DepartmentProps) => {
            return {
                value: department.id,
                label: department.name
            }
        }))
        if (resetField) {
            resetField('department', {defaultValue: {value: response.data[0].id, label: response.data[0].name}})
        }
    }

    useEffect(() => {
        getDepartments()
    }, [])
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Input control={control} name={'search'} labelClassName={"flex-1"}
                   placeholder={"Busque por alguma coisa"} label={"Busque por alguma coisa"}
            />
            <Select control={control} labelClassName={"flex-1"} options={departments} name={"department"} label={"Departamento"}
                    placeholder={"Selecione um departamento"}
            />
        </SimpleGrid>
    )
}