import {ColumnProps} from "../../types/table";
import {Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import {BsFillArrowUpSquareFill, BsFillArrowDownSquareFill} from "react-icons/bs"
import React from "react";
type TableColumnsProps = {
    onStockIn: (productId: number) => void
    onStockOut: (productId: number) => void
}
export const tableColumns = ({onStockIn, onStockOut}: TableColumnsProps): ColumnProps[] => {
    return [
        {
            header: "AÇÕES",
            cell: (info) => {
                return (
                    <Flex gap={2}>
                        <BsFillArrowDownSquareFill className={`cursor-pointer fill-green-500`} onClick={() => onStockIn(info.row.original.product_final.id)} />
                        <BsFillArrowUpSquareFill className={`cursor-pointer fill-red-500`} onClick={() => onStockOut(info.row.original.product_final.id)} />
                    </Flex>
                )
            }
        },
        {
            id: "id",
            header: "ID"
        },
        {
            id: "product_name",
            header: "PRODUTO",
            cell: (info) => info.row.original.product_final.name
        },
        {
            id: "total",
            header: "TOTAL"
        },
        {
            id: "stock_control_length",
            header: "MOVIMENTAÇÕES",
            cell: (info) => info.row.original.product_final_stock_control?.length
        },
        {
            id: "created_at",
            header: "CRIADO EM"
        },
        {
            id: "updated_at",
            header: "ATUALIZADO EM"
        }
    ]
}