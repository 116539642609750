import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control, useForm} from "react-hook-form";
import {UserBox} from "../../../components/FormBox/UserBox";
import React, {useEffect, useState} from "react";
import {AddressProps, ManufacturerProps, UserProps} from "../../../types";
import {createAddress, getAddressById} from "../../../services/address";
import {createManufacturer, getManufacturerById, updateManufacturer} from "../../../services/manufacturers";
import {Box, Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {
    getAddressCreateAndEditErrors,
    getManufacturerCreateAndEditErrors,
    getUserCreateAndEditErrors
} from "../../../helpers";
import {CancelItemBox} from "../../../components/CancelItemBox";
import {Dialog} from "../../../components/Dialog";
import {Container} from "../../../components/Container";
import {Button} from "../../../components/Button";
import {PasswordBox} from "../../../components/FormBox/PasswordBox";
import {CreateAndEditBox} from "./components/CreateAndEditBox";
import {NewItemBox} from "../../../components/NewItemBox";

export function ManufacturerCreateAndEdit() {
    const [isSaving, setIsSaving] = useState(false)

    const {onOpen: saveDialogOnOpen, isOpen: saveDialogIsOpen, onClose: saveDialogOnClose} = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()
    const {
        control: manufacturerControl,
        watch: manufacturerWatch,
        getValues: manufacturerGetValues,
        reset: manufacturerReset,
        resetField: manufacturerResetField
    } = useForm<Partial<ManufacturerProps>>()
    const params = useParams()
    const manufacturerId = Number(params.manufacturerId)

    const getManufacturerData = async () => {
        const response = await getManufacturerById(manufacturerId)
        console.log({data: response.data})
        manufacturerReset({
            ...response.data
        })
    }
    useEffect(() => {

        if (manufacturerId) {
            getManufacturerData()
        }
    }, [])


    const handleSave = async () => {
        const manufacturerPayload = manufacturerGetValues()

        let hasErrors = false

        const manufacturerErrors = getManufacturerCreateAndEditErrors(manufacturerPayload)

        const errors = [...manufacturerErrors]

        if (errors.length > 0) {
            hasErrors = true
            for (const error of errors) {
                toast({
                    status: "error",
                    title: error.title,
                    description: error.description,
                    isClosable: true
                })
            }
        }

        if (hasErrors) {
            return
        }

        let manufacturerResponse: any
        setIsSaving(true)
        if (manufacturerId) {
            manufacturerResponse = await updateManufacturer(manufacturerId, {...manufacturerPayload})
        } else {
            manufacturerResponse = await createManufacturer({...manufacturerPayload})
        }
        if (manufacturerResponse.status !== 201 && manufacturerResponse.status !== 200) {
            setIsSaving(false)
            return toast({
                status: "error",
                title: "Erro ao salvar fabricante",
                description: manufacturerResponse.data.message || "Houve um erro salvar o fabricante, por favor tente novamente",
                isClosable: true
            })
        }
        setIsSaving(false)

        navigate('/manufacturers')
        return toast({
            status: "success",
            title: "Fabricante salvo com sucesso",
            description: manufacturerResponse.data.message || "O fabricante foi salvo com sucesso",
            isClosable: true
        })

    }
    const handleCancel = () => {
        return navigate("/manufacturers")
    }

    return (
        <>
            <Dialog
                onAccept={handleSave}
                isOpen={saveDialogIsOpen}
                onClose={saveDialogOnClose}
                title={"Salvar fabricante"}
                description={"Ao confirmar, o fabricante será salvo"}
            />
            <CancelItemBox title={"Voltar para lista de fabricantes"} onClick={() => navigate('/manufacturers')} />
            <Accordion title={"Dados do fabricante"} defaultExpanded={true}>
                <CreateAndEditBox control={manufacturerControl as Control<any>} isEditing={!!manufacturerId} />
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => handleCancel()}>Voltar</Button>
                    <Button className={`!bg-red-500 !text-white`} onClick={() => saveDialogOnOpen()}> Salvar {isSaving && <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                </Flex>
            </Container>
        </>
    )
}