import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control, useForm} from "react-hook-form";
import {UserBox} from "../../../components/FormBox/UserBox";
import React, {useEffect, useState} from "react";
import {AddressProps, ProductInHighlight, HighlightProps, SelectOptionProps, UserProps} from "../../../types";
import {createAddress, getAddressById} from "../../../services/address";
import {createHighlight, getHighlightById, updateHighlight} from "../../../services/highlights";
import {Box, Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {
    getAddressCreateAndEditErrors,
    getHighlightCreateAndEditErrors, getHighlightProductAddErrors,
    getUserCreateAndEditErrors
} from "../../../helpers";
import {CancelItemBox} from "../../../components/CancelItemBox";
import {Dialog} from "../../../components/Dialog";
import {Container} from "../../../components/Container";
import {Button} from "../../../components/Button";
import {PasswordBox} from "../../../components/FormBox/PasswordBox";
import {CreateAndEditBox} from "./components/CreateAndEditBox";
import {NewItemBox} from "../../../components/NewItemBox";
import {ProductsBox} from "./components/ProductsBox";
import {getProducts as getAllProducts} from "../../../services/products";

export function HighlightCreateAndEdit() {
    const [isSaving, setIsSaving] = useState(false)
    const [products, setProducts] = useState<SelectOptionProps[]>([])

    const {onOpen: saveDialogOnOpen, isOpen: saveDialogIsOpen, onClose: saveDialogOnClose} = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()
    const {
        control: highlightControl,
        watch: highlightWatch,
        getValues: highlightGetValues,
        reset: highlightReset,
        resetField: highlightResetField
    } = useForm<HighlightProps>()
    const params = useParams()
    const highlightId = Number(params.highlightId)

    const getHighlightData = async () => {
        const response = await getHighlightById(highlightId)
        console.log({data: response.data})
        highlightReset({
            ...response.data,
            available_at: new Date(response.data.available_at).toLocaleDateString(),
            expire_at: new Date(response.data.expire_at).toLocaleDateString(),
            products: response.data.product_in_highlights.map((product: ProductInHighlight) => {
                return {
                    id: product.id_product_final,
                    name: product?.product_final?.name
                }
            })
        })
    }

    const getProducts = async () => {
        const response = await getAllProducts({per_page: 1000})
        console.log({response})
        if (response.status !== 200) {
            return toast({
                status: "error",
                title: "Erro ao buscar produtos",
                description: "Erro ao buscar produtos, tente novamente mais tarde",
                isClosable: true
            })
        }

        setProducts(response.data.products.map((product: any) => {
            return {
                value: product.id,
                label: product.name,
            }
        }))
    }
    useEffect(() => {

        if (highlightId) {
            getHighlightData()
        }
        getProducts()
    }, [])


    const handleSave = async () => {
        const highlightPayload = highlightGetValues()

        delete highlightPayload.product_to_add

        highlightPayload.products = highlightPayload?.products?.map((item: any) => item?.id)

        let hasErrors = false

        const highlightErrors = getHighlightCreateAndEditErrors(highlightPayload)

        const errors = [...highlightErrors]

        if (errors.length > 0) {
            hasErrors = true
            for (const error of errors) {
                toast({
                    status: "error",
                    title: error.title,
                    description: error.description,
                    isClosable: true
                })
            }
        }

        if (hasErrors) {
            return
        }

        if (highlightPayload.available_at) {
            highlightPayload.available_at = new Date(highlightPayload.available_at)
        }
        if (highlightPayload.expire_at) {
            highlightPayload.expire_at = new Date(highlightPayload.expire_at)
        }

        let highlightResponse: any
        setIsSaving(true)
        if (highlightId) {
            highlightResponse = await updateHighlight(highlightId, {...highlightPayload})
        } else {
            highlightResponse = await createHighlight({...highlightPayload})
        }
        if (highlightResponse.status !== 201 && highlightResponse.status !== 200) {
            setIsSaving(false)
            return toast({
                status: "error",
                title: "Erro ao salvor destaque",
                description: highlightResponse.data.message || "Houve um erro salvor o destaque, por favor tente novamente",
                isClosable: true
            })
        }
        setIsSaving(false)

        navigate('/highlights')
        return toast({
            status: "success",
            title: "Destaque salvo com sucesso",
            description: highlightResponse.data.message || "O destaque foi salvo com sucesso",
            isClosable: true
        })

    }
    const handleCancel = () => {
        return navigate("/highlights")
    }

    const handleAddProduct = () => {
        const data = highlightGetValues()

        let hasErrors = false
        const errors = getHighlightProductAddErrors(data)

        if (errors.length > 0) {
            hasErrors = true
            for (const error of errors) {
                toast({
                    status: "error",
                    title: error.title,
                    description: error.description,
                    isClosable: true
                })
            }
        }

        if (hasErrors) {
            return
        }
        const products = data?.products || []
        const productExists = data?.products?.find((product: any) => product?.id === data?.product_to_add?.value)

        if (productExists) {
            return toast({
                status: "error",
                title: "Produto já cadastrado",
                description: "O produto já se encontra cadastrado na lista, salve para persistir",
                isClosable: true
            })
        }

        highlightReset({
            ...data,
            products: [...products, {id: data.product_to_add.value, name: data.product_to_add.label}],
            product_to_add: null
        })
        console.log({data})
    }

    const handleRemoveProduct = (productId: number) => {
        const data = highlightGetValues()

        const products = data?.products?.filter((product: any) => product?.id !== productId)

        highlightReset({
            ...data,
            products: [...products],
            product_to_add: null
        })
        console.log({data})
    }

    return (
        <>
            <Dialog
                onAccept={handleSave}
                isOpen={saveDialogIsOpen}
                onClose={saveDialogOnClose}
                title={"Salvar destaque"}
                description={"Ao confirmar, o destaque será salvo"}
            />
            <CancelItemBox title={"Voltar para lista de destaques"} onClick={() => navigate('/highlights')} />
            <Accordion title={"Produtos em destaque"} >
                <ProductsBox onRemoveProduct={handleRemoveProduct} values={highlightWatch()} onAddProduct={handleAddProduct} products={products} control={highlightControl as Control<any>} isEditing={!!highlightId} />
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => handleCancel()}>Voltar</Button>
                    <Button className={`!bg-red-500 !text-white`} onClick={() => saveDialogOnOpen()}> Salvar {isSaving && <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                </Flex>
            </Container>
        </>
    )
}