import React, {createContext, useContext} from "react";
type ThemeContextProps = {
    toggleTheme: () => void
}
type ThemeProviderProps = {
    children?: React.ReactNode
}
const ThemeContext = createContext({} as ThemeContextProps)

export function ThemeProvider({children}: ThemeProviderProps) {
    const toggleTheme = () => {
        if (document.getElementsByTagName("html")[0].className === "dark") {
            localStorage.setItem('theme', 'light')
            document.getElementsByTagName("html")[0].className = "light"
            document.getElementsByTagName('html')[0].setAttribute("data-theme", "light")
            document.getElementsByTagName('html')[0].style.colorScheme = "light"
        } else {
            localStorage.setItem('theme', 'dark')
            document.getElementsByTagName("html")[0].className = "dark"
            document.getElementsByTagName('html')[0].setAttribute("data-theme", "dark")
            document.getElementsByTagName('html')[0].style.colorScheme = "dark"
        }
    }
    return (
        <ThemeContext.Provider value={{toggleTheme}}>
            {children}
        </ThemeContext.Provider>
    )

}

export function useTheme() {
    return useContext(ThemeContext)
}