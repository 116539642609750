import {TextLogo} from "../../../components/TextLogo";
import {Input} from "../../../components/Input";
import {Button} from "../../../components/Button";
import {Link} from "../../../components/Link";
import {WizardStepProps} from "../../../types/wizard";
import {useNavigate} from "react-router-dom"
import {Flex, useToast} from "@chakra-ui/react";
import {Control, useForm} from "react-hook-form";
import {LoginProps, UserProps} from "../../../types";
import {getAddressCreateAndEditErrors, getLoginErrors, getUserCreateAndEditErrors} from "../../../helpers";
import {administratorLogin, ownerLogin} from "../../../services/login"
import {useUser} from "../../../hooks/useUser";


export function SignIn({...props}: Partial<WizardStepProps>) {
    const toast = useToast()
    const {storeUser} = useUser()
    const navigate = useNavigate()
    const {
        control,
        watch,
        getValues,
        reset,
        resetField
    } = useForm<Partial<LoginProps>>()
    const handleSignIn = async () => {
        const payload = getValues()
        console.log({payload})
        let hasErrors = false

        const errors = getLoginErrors(payload)

        if (errors.length > 0) {
            hasErrors = true
            for (const error of errors) {
                toast({
                    status: "error",
                    title: error.title,
                    description: error.description,
                    isClosable: true
                })
            }
        }

        if (hasErrors) {
            return
        }

        let userData: UserProps

        const administratorResponse = await administratorLogin({...payload} as LoginProps)

        console.log({administratorResponse})
        if (administratorResponse.status !== 200) {

            return toast({
                status: "error",
                title: "Não autorizado",
                description: "E-mail ou/e senha incorretos",
                isClosable: true
            })
        } else {
            userData = {
                ...administratorResponse.data.administrator,
                token: administratorResponse.data.jwt,
                type: "administrator"
            }
        }

        storeUser(userData)

        toast({
            status: "success",
            title: "Autorizado",
            description: "Login efetuado com sucesso",
            isClosable: true
        })
        return navigate('/administrators')
    }
    return (
        <Flex className={"w-full flex-col items-center justify-center gap-5"}>
            <TextLogo className={"w-full"}/>
            <Input control={control as Control<any>} name={"email"} label={"Seu e-mail"} className={"w-full"}
                   labelClassName={"w-full"}/>
            <Input control={control as Control<any>} name={"password"} type={"password"} label={"Sua senha"}
                   labelClassName={"w-full"}/>
            <Button onClick={handleSignIn} className={`text-neutral-800`}>Entrar</Button>
            <Link onClick={() => props?.goToStep && props.goToStep(2)}>Esqueci minha senha</Link>
        </Flex>
    )
}