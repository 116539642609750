import {Box, Flex, SimpleGrid, Stack} from "@chakra-ui/react";
import React from "react";
import {Control} from "react-hook-form";
import {IoMdAdd} from "react-icons/io"
import {List} from "../../../../../components/List";
import {listColumns} from "./listColumns";
import {OrderProps} from "../../../../../types";
import {Text} from "../../../../../components/Text";
import {Link} from "../../../../../components/Link";
import {useNavigate} from "react-router-dom";
import {currencyFormat} from "../../../../../helpers";

type ProductsBoxProps = {
    isEditing?: boolean
    isLoading?: boolean
    onSelectInvalidType?: () => void
    onAddProduct?: () => void
    onRemoveProduct?: (productId: number) => void
    values: OrderProps
}

export function DeliveryBox({
                                values,
                                isEditing,
                                onSelectInvalidType,
                                onAddProduct,
                                onRemoveProduct,
                                isLoading
                            }: ProductsBoxProps) {
    const navigate = useNavigate()
    console.log({values})
    return (
        <>
                    <Stack spacing={1} className={"flex-1 p-2 bg-gray-200 dark:bg-neutral-800 text-sm"}>
                        <Text><b>ID SHIPENGINE SHIPMENT :</b> {values?.id_shipengine_shipment}</Text>
                        <Text><b>ID SHIPENGINE RATE :</b> {values?.id_shipengine_rate}</Text>
                        <Text><b>ID SHIPENGINE LABEL :</b> {values?.id_shipengine_label}</Text>
                        <Link href={values?.shipengine_label_url} target={'_blank'}><b>SHIPENGINE LABEL URL:</b> {values?.shipengine_label_url}</Link>
                        <Text><b>ID SHIPENGINE TRACKING :</b> {values?.id_shipengine_tracking}</Text>
                        <Text><b>TRACKING STATUS:</b> {values?.shipengine_tracking_status}</Text>
                        <Text><b>PRICE :</b> {currencyFormat(Number(values?.delivery_price))}</Text>
                        <Text><b>STATUS :</b> {values?.delivery_status?.name}</Text>
                    </Stack>
        </>
    )
}