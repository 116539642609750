import {Accordion} from "../../components/Accordion";
import {PaginationInfoProps, UserProps} from "../../types";
import { Table } from "../../components/Table";
import {tableColumns} from "./tableColumns"
import {deleteFilter, getFilters} from "../../services/filters";
import React, {useEffect, useState} from "react";
import {Control, FieldValues, useForm} from "react-hook-form";
import {SearchBox} from "./searchBox";
import {NewItemBox} from "../../components/NewItemBox";
import {useNavigate} from "react-router-dom";
import {useDisclosure, useToast} from "@chakra-ui/react";
import {Dialog} from "../../components/Dialog";

export function Filters() {
    const {onOpen: deleteDialogOnOpen, isOpen: deleteDialogIsOpen, onClose: deleteDialogOnClose} = useDisclosure()
    const [deleteId, setDeleteId] = useState(0)
    const toast = useToast()
    const navigate = useNavigate()
    const {
        control,
        watch,
        resetField,
        getValues
    } = useForm()
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfoProps>({
        per_page: 10,
        page: 1,
        last_page: 0,
        total: 0,
        to: 0
    })

    const [data, setData] = useState<UserProps[]>([] as UserProps[])

    useEffect(() => {
    }, [data])

    const getData = async (page?: number) => {
        const payload = getValues()
        const response = await getFilters(payload.department.value,{
            per_page: paginationInfo.per_page,
            page: page ? page : paginationInfo.page
        })
        if (response.status !== 200) {
            setData([])
            return
        }
        setData(response.data.map((filter: UserProps) => {
            filter.created_at = new Date(filter.created_at).toLocaleString('pt-br')
            if (filter.updated_at) {
                filter.updated_at = new Date(filter.updated_at).toLocaleString('pt-br')
            }
            return filter
        }))
        setPaginationInfo(oldValue => {
            return {
                ...oldValue,
                last_page: response.data.total_pages,
                total: response.data.total_pages,
                to: response.data.total_count,
                page: response.data.page
            }
        })
    }
    const onPageChange = (page: number) => {
        getData(page + 1)
    }


    useEffect(() => {
        watch((data, {name}) => {
            if (data.department.value) {
                getData(1)
            }
        })
    }, [])

    useEffect(() => {
        watch((data, {name}) => {
            console.log({data, name})
        })
    }, [])
    const handleEdit = (filterId: number) => {
        return navigate(`/filters/${filterId}`)
    }
    const handleDelete = async () => {
        const response = await deleteFilter(deleteId)
        if (response?.status !== 204) {
            return toast({
                status: "error",
                title: "Erro ao deletar filtro",
                description: response.data.message || "Houve um erro deletar o filtro, por favor tente novamente",
                isClosable: true
            })
        }
        getData()
        return toast({
            status: "success",
            title: "Filtro deletado com sucesso",
            description: response.data.message || "O filtro foi deletado com sucesso",
            isClosable: true
        })
    }
    return (
        <>
            <Dialog
                onAccept={handleDelete}
                isOpen={deleteDialogIsOpen}
                onClose={deleteDialogOnClose}
                title={"Deletar filtro"}
                description={"Ao confirmar, o filtro será deletado"}
            />
            <NewItemBox title={"Novo filtro"} className={""} onClick={() => navigate(`/filters/create`)} />
            <Accordion title={"Digite sua busca"} defaultExpanded={false} className={""}>
                <SearchBox control={control} resetField={resetField} />
            </Accordion>
            <Accordion title={"Filtros"} panelClassName={`overflow-auto max-h-screen`}>
                <Table data={data} columns={tableColumns({
                    onEdit: handleEdit,
                    onDelete:(filterId) => {
                        setDeleteId(filterId)
                        deleteDialogOnOpen()
                    }
                })} onPageChange={onPageChange} pageCount={paginationInfo.last_page} />
            </Accordion>
        </>
    )
}