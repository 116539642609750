import {Login} from "../views/Login";
import {RouteProps} from "../types/router";
import {Administrators} from "../views/Administrators";
import {AdministratorCreateAndEdit} from "../templates/Administrators/CreateAndEdit";
import {Departments} from "../views/Departments";
import {Categories} from "../views/Categories";
import {Subcategories} from "../views/Subcategories";
import {Manufacturers} from "../views/Manufacturers";
import {Orders} from "../views/Orders";
import {Payments} from "../views/Payments";
import {Carts} from "../views/Carts";
import {Products} from "../views/Products";
import {Stock} from "../views/Stock";
import {Promotions} from "../views/Promotions";
import {Filters} from "../views/Filters";
import {Highlights} from "../views/Highligths";
import {Refunds} from "../views/Refunds";
import {Ads} from "../views/Ads";
import {Clients} from "../views/Clients";
import {Invoices} from "../views/Invoices";

export const routes: RouteProps[] = [
    {
        path: "login",
        element: <Login />,
        empty: true
    },
    {
        path: "administrators",
        element: <Administrators />
    },
    {
        path: "administrators/create",
        element: <AdministratorCreateAndEdit />
    },
    {
        path: "administrators/:administratorId",
        element: <AdministratorCreateAndEdit />
    },
    {
        path: "clients",
        element: <Clients />
    },
    {
        path: "clients/create",
        element: <Clients />
    },
    {
        path: "clients/:clientId",
        element: <Clients />
    },
    {
        path: "products",
        element: <Products />
    },
    {
        path: "products/create",
        element: <Products />
    },
    {
        path: "products/:productId",
        element: <Products />
    },
    {
        path: "stock",
        element: <Stock />
    },
    {
        path: "stock/create",
        element: <Stock />
    },
    {
        path: "stock/:stockId",
        element: <Stock />
    },
    {
        path: "promotions",
        element: <Promotions />
    },
    {
        path: "promotions/create",
        element: <Promotions />
    },
    {
        path: "promotions/:promotionId",
        element: <Promotions />
    },
    {
        path: "manufacturers",
        element: <Manufacturers />
    },
    {
        path: "manufacturers/create",
        element: <Manufacturers />
    },
    {
        path: "manufacturers/:manufacturerId",
        element: <Manufacturers />
    },
    {
        path: "departments",
        element: <Departments />
    },
    {
        path: "departments/create",
        element: <Departments />
    },
    {
        path: "departments/:departmentId",
        element: <Departments />
    },
    {
        path: "filters",
        element: <Filters />
    },
    {
        path: "filters/create",
        element: <Filters />
    },
    {
        path: "filters/:filterId",
        element: <Filters />
    },
    {
        path: "categories",
        element: <Categories />
    },
    {
        path: "categories/create",
        element: <Categories />
    },
    {
        path: "categories/:categoryId",
        element: <Categories />
    },
    {
        path: "subcategories",
        element: <Subcategories />
    },
    {
        path: "subcategories/create",
        element: <Subcategories />
    },
    {
        path: "subcategories/:subcategoryId",
        element: <Subcategories />
    },
    {
        path: "manufacturers",
        element: <></>
    },
    {
        path: "manufacturers/create",
        element: <></>
    },
    {
        path: "manufacturers/:manufacturerId",
        element: <></>
    },
    {
        path: "filters",
        element: <></>
    },
    {
        path: "filters/create",
        element: <></>
    },
    {
        path: "filters/:filterId",
        element: <></>
    },
    {
        path: "carts",
        element: <Carts />
    },
    {
        path: "carts/:cartId",
        element: <Carts />
    },
    {
        path: "orders",
        element: <Orders />
    },
    {
        path: "orders/:orderId",
        element: <Orders />
    },
    {
        path: "payments",
        element: <Payments />
    },
    {
        path: "payments/:paymentId",
        element: <Payments />
    },
    {
        path: "refunds",
        element: <Refunds />
    },
    {
        path: "refunds/:refundId",
        element: <Refunds />
    },
    {
        path: "invoices",
        element: <Invoices />
    },
    {
        path: "invoices/create",
        element: <Invoices />
    },
    {
        path: "invoices/:orderId",
        element: <Invoices />
    },
    {
        path: "tickets",
        element: <></>
    },
    {
        path: "tickets/create",
        element: <></>
    },
    {
        path: "tickets/:ticketId",
        element: <></>
    },
    {
        path: "highlights",
        element: <Highlights />
    },
    {
        path: "highlights/create",
        element: <Highlights />
    },
    {
        path: "highlights/:highlightId",
        element: <Highlights />
    },
    {
        path: "ads",
        element: <Ads />
    },
    {
        path: "ads/create",
        element: <Ads />
    },
    {
        path: "ads/:adId",
        element: <Ads />
    },
]