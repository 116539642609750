import {Flex, SimpleGrid, Spinner, useToast} from "@chakra-ui/react";
import {Input} from "../../../../../components/Input";
import {Control} from "react-hook-form";
import {Select} from "../../../../../components/Select";
import {Button} from "../../../../../components/Button";
import React, {useEffect, useState} from "react";
import {Textarea} from "../../../../../components/Textarea";
import {AttributeProps, DepartmentProps, SelectOptionProps} from "../../../../../types";
import {createAttribute, listAttribute} from "../../../../../services/products";
import {CreatableSelect} from "../../../../../components/CreatableSelect";

type SearchBoxProps = {
    control: Control<any>
    isEditing?: boolean
    isLoading?: boolean
    filters?: SelectOptionProps[]
}

export function ProductDefinitionsBox({control, filters, isEditing, isLoading}: SearchBoxProps) {
    const toast = useToast()
    const [attributes, setAttributes] = useState<Partial<AttributeProps> & SelectOptionProps[]>([])
    const getAttributesData = async () => {
            const response = await listAttribute()
            if (response.status !== 200) {
                return
            }
            setAttributes(
                response.data.map((attribute: AttributeProps) => {
                    return {
                        value: attribute.id,
                        label: attribute.name,
                        data: attribute
                    }
                })
            )
            // if (productResetField) {
            //     productResetField('department', {defaultValue: {value: response.data[0].id, label: response.data[0].name}})
            // }
    }

    const handleRegisterAttribute = async (value: string) => {
        try {
            const response = await createAttribute({name: value})

            if (response.status !== 201) {
                return toast({
                    status: "error",
                    title: response?.data?.message || 'Error to register attribute',
                    isClosable: true
                })
            }
            getAttributesData()
            return toast({
                status: "success",
                title: response?.data?.message || 'Attribute registered successfully',
                isClosable: true
            })
        } catch (e: any) {
            console.log({e})
            return toast({
                status: "error",
                title: 'Error to register attribute',
                isClosable: true
            })
        }
    }

    useEffect(() => {
        getAttributesData()
    }, [])
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <CreatableSelect onCreateOption={handleRegisterAttribute} control={control} name={'attribute'} options={attributes} labelClassName={"flex-1 col-span-2"}
                    label={"Attribute *"} placeholder={"Select or create the attribute *"}
            />
            <CreatableSelect isMulti={true} control={control} name={'attribute_values'} labelClassName={"flex-1 col-span-2"}
                    label={"Attribute values *"} placeholder={"Select or create the attribute values *"}
            />
            <Select isMulti={true} control={control} name={'filters'} options={filters} labelClassName={"flex-1 col-span-2"}
                    label={"Filtros"} placeholder={"Selecione os filtros disponíveis"}
            />
        </SimpleGrid>
    )
}