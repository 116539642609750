import {CancelItemBox} from "../../../components/CancelItemBox";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control} from "react-hook-form";
import {ProductsBox} from "./components/ProductsBox"
import {OrderProps, ProductInHighlight, SelectOptionProps} from "../../../types";
import {getOrderById} from "../../../services/orders";
import {OrderBox} from "./components/OrderBox";
import {PaymentsBox} from "./components/PaymentsBox";
import {Container} from "../../../components/Container";
import {Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {Button} from "../../../components/Button";
import {Dialog} from "../../../components/Dialog";
import {createRefund} from "../../../services/refunds";
import {DeliveryBox} from "./components/DeliveryBox";

export function OrderDetails () {
    const {onOpen: refundDialogOnOpen, isOpen: refundDialogIsOpen, onClose: refundDialogOnClose} = useDisclosure()
    const params = useParams()
    const orderId = Number(params.orderId)
    const navigate = useNavigate()
    const toast = useToast()
    const [order, setOrder] = useState<OrderProps>({} as OrderProps)
    const [isSaving, setIsSaving] = useState(false)

    const getOrderData = async () => {
        const response = await getOrderById(orderId)
        console.log({data: response.data})
        setOrder({
            ...response.data
        })
    }
    const handleRefund = async () => {
        setIsSaving(true)
        const response = await createRefund(order?.payments[0]?.id)

        if (response.status !== 201) {
            setIsSaving(false)
            return toast({
                status: "error",
                title: "Erro ao criar estorno",
                description: response.data.message || "Houve um erro ao criar o estorno, por favor tente novamente",
                isClosable: true
            })
        }
        refundDialogOnClose()
        await getOrderData()
        setIsSaving(false)
        return toast({
            status: "success",
            title: "Estorno criado",
            description: response.data.message || "Estorno criado com sucesso",
            isClosable: true
        })

    }

    useEffect(() => {
        getOrderData()
    }, [])

    return (
        <>
            <Dialog
                onAccept={handleRefund}
                isOpen={refundDialogIsOpen}
                onClose={refundDialogOnClose}
                title={"Confirmar cancelamento"}
                description={"Ao confirmar, o cancelamento será realizado e o valor do pedido será estornado"}
            />
            <CancelItemBox title={"Voltar para lista de pedidos"} onClick={() => navigate('/orders')} />
            <Accordion title={"Dados do pedido"} defaultExpanded={true} >
                <OrderBox values={order} />
            </Accordion>
            <Accordion title={"Pagamentos do pedido"} defaultExpanded={true} >
                <PaymentsBox values={order} />
            </Accordion>
            <Accordion title={"Delivery do pedido"} defaultExpanded={true} >
                <DeliveryBox values={order} />
            </Accordion>
            <Accordion title={"Produtos do pedido"} defaultExpanded={true} >
                <ProductsBox values={order} />
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => navigate("/orders")}>Voltar</Button>
                    {order?.id_status === 3 && (
                        <Button className={`!bg-red-500 !text-white`} onClick={() => refundDialogOnOpen()}>Cancelamento {isSaving && <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                    )}
                </Flex>
            </Container>
        </>
    )
}