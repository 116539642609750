import {api} from "./axios";
import {UserProps} from "../types";

export const getRefunds = async (params?: any) => {
    return await api.get(`/refund`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getRefundById = async (refundId?: number) => {
    return await api.get(`/refund/${refundId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateRefund = async (refundId: number, payload?: any) => {
    return await api.put(`/refund/${refundId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createRefund = async (paymentId: number, payload?: any) => {
    return await api.post(`/refund/maverick/${paymentId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteRefund = async (refundId: number) => {
    return await api.delete(`/refund/${refundId}`, {
    }).catch(({response}) => {
        return response
    })
}