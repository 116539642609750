import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control, useForm} from "react-hook-form";
import {UserBox} from "../../../components/FormBox/UserBox";
import React, {useEffect, useState} from "react";
import {AddressProps, UserProps} from "../../../types";
import {createAddress, getAddressById} from "../../../services/address";
import {createAdministrator, getAdministratorById, updateAdministrator} from "../../../services/administrators";
import {Box, Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {getAddressCreateAndEditErrors, getUserCreateAndEditErrors} from "../../../helpers";
import {CancelItemBox} from "../../../components/CancelItemBox";
import {Dialog} from "../../../components/Dialog";
import {Container} from "../../../components/Container";
import {Button} from "../../../components/Button";
import {PasswordBox} from "../../../components/FormBox/PasswordBox";

export function AdministratorCreateAndEdit() {
    const [isSaving, setIsSaving] = useState(false)

    const {onOpen: saveDialogOnOpen, isOpen: saveDialogIsOpen, onClose: saveDialogOnClose} = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()
    const {
        control: administratorControl,
        watch: administratorWatch,
        getValues: administratorGetValues,
        reset: administratorReset,
        resetField: administratorResetField
    } = useForm<Partial<UserProps>>()
    const params = useParams()
    const administratorId = Number(params.administratorId)

    const getAdministratorData = async () => {
        const response = await getAdministratorById(administratorId)
        administratorReset({
            ...response.data,
            password: undefined
        })
    }
    useEffect(() => {

        if (administratorId) {
            getAdministratorData()
        }
    }, [])


    const handleSave = async () => {
        const administratorPayload = administratorGetValues()

        let hasErrors = false

        const administratorErrors = getUserCreateAndEditErrors(administratorPayload, !!administratorId)

        const errors = [...administratorErrors]

        if (errors.length > 0) {
            hasErrors = true
            for (const error of errors) {
                toast({
                    status: "error",
                    title: error.title,
                    description: error.description,
                    isClosable: true
                })
            }
        }

        if (hasErrors) {
            return
        }

        delete administratorPayload.password_repeat
        let administratorResponse: any
        setIsSaving(true)
        if (administratorId) {
            administratorResponse = await updateAdministrator(administratorId, {...administratorPayload})
        } else {
            administratorResponse = await createAdministrator({...administratorPayload})
        }
        if (administratorResponse.status !== 201 && administratorResponse.status !== 200) {
            setIsSaving(false)
            return toast({
                status: "error",
                title: "Erro ao salvar administrador",
                description: administratorResponse.data.message || "Houve um erro salvar o administrador, por favor tente novamente",
                isClosable: true
            })
        }
        setIsSaving(false)

        navigate('/administrators')
        return toast({
            status: "success",
            title: "Administrador salvo com sucesso",
            description: administratorResponse.data.message || "O administrador foi salvo com sucesso",
            isClosable: true
        })

    }
    const handleCancel = () => {
        return navigate("/administrators")
    }

    return (
        <>
            <Dialog
                onAccept={handleSave}
                isOpen={saveDialogIsOpen}
                onClose={saveDialogOnClose}
                title={"Salvar administrador"}
                description={"Ao confirmar, o administrador será salvo"}
            />
            <CancelItemBox title={"Voltar para lista de administradores"} onClick={() => navigate('/administrators')} />
            <Accordion title={"Dados do administrador"} defaultExpanded={true}>
                <UserBox control={administratorControl as Control<any>} isEditing={!!administratorId} />
            </Accordion>
            <Accordion title={"Senha do administrador"} defaultExpanded={false}>
                <PasswordBox control={administratorControl as Control<any>} isEditing={!!administratorId} />
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => handleCancel()}>Voltar</Button>
                    <Button className={`!bg-red-500 !text-white`} onClick={() => saveDialogOnOpen()}> Salvar {isSaving && <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                </Flex>
            </Container>
        </>
    )
}