import {ColumnProps} from "../../types/table";
import {Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import {MdOutlineViewHeadline} from "react-icons/md"
import {BiBlock} from "react-icons/bi"
import {FcApproval} from "react-icons/fc"
import React from "react";
import {currencyFormat} from "../../helpers";
type TableColumnsProps = {
    onDelete: (cartId: number) => void
    onEdit: (cartId: number) => void
    onDetail?: (cartId: number) => void
}
export const tableColumns = ({onDelete, onEdit, onDetail}: TableColumnsProps): ColumnProps[] => {
    return [
        {
            header: "AÇÕES",
            cell: (info) => {
                return (
                    <Flex gap={2}>
                        <MdOutlineViewHeadline className={`cursor-pointer dark:fill-white`} onClick={() => onDetail && onDetail(info.row.original.id)} />
                    </Flex>
                )
            }
        },
        {
            id: "id",
            header: "ID"
        },
        {
            id: "id_order",
            header: "ID ORDER",
            cell: (info) => info.row.original?.orders[0]?.id
        },
        {
            id: "id_client",
            header: "CLIENTE"
        },
        {
            id: "status",
            header: "STATUS",
            cell: (info) => info.row.original?.shopping_cart_status?.name
        },
        // {
        //     id: "id_order",
        //     header: "PEDIDO"
        // },
        // {
        //     id: "client_name",
        //     header: "CLIENTE",
        //     cell: (info) => `${info.row.original.clients.first_name} ${info.row.original.clients.last_name}`
        // },
        {
            id: "total_price",
            header: "VALOR",
            cell: (info) => currencyFormat(Number(info.row.original?.total_price))
        },
        {
            id: "products_length",
            header: "PRODUTOS",
            cell: (info) => info.row.original?.products_amount
        },
        {
            id: "created_at",
            header: "CRIADO EM"
        },
        {
            id: "updated_at",
            header: "ATUALIZADO EM"
        }
    ]
}