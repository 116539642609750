import {ColumnProps} from "../../types/table";
import {Flex} from "@chakra-ui/react";
import {MdOutlineViewHeadline} from "react-icons/md"
import React from "react";
import {currencyFormat} from "../../helpers";
type TableColumnsProps = {
    onDelete: (paymentId: number) => void
    onEdit: (paymentId: number) => void
    onDetail?: (paymentId: number) => void
}
export const tableColumns = ({onDelete, onEdit, onDetail}: TableColumnsProps): ColumnProps[] => {
    return [
        {
            header: "AÇÕES",
            cell: (info) => {
                return (
                    <Flex gap={2}>
                        <MdOutlineViewHeadline className={`cursor-pointer dark:fill-white`} onClick={() => onDetail && onDetail(info.row.original.id)} />
                    </Flex>
                )
            }
        },
        {
            id: "id",
            header: "ID"
        },
        {
            id: "id_order",
            header: "PEDIDO",
            cell: info => info.row.original?.payments?.orders?.id
        },
        {
            id: "id_payment",
            header: "PAGAMENTO",
            cell: info => info.row.original?.payments?.id
        },
        {
            id: "status_name",
            header: "STATUS",
            cell: info => info.row.original?.refund_status?.name
        },
        {
            id: "price",
            header: "VALOR",
            cell: (info) => currencyFormat(Number(info.row.original.price))
        },
        {
            id: "id_maverick_refund",
            header: "ID MAVERICK"
        },
        {
            id: "confirmed_at",
            header: "CONFIRMADO EM"
        },
        {
            id: "refused_at",
            header: "RECUSADO EM"
        },
        {
            id: "created_at",
            header: "CRIADO EM"
        },
        {
            id: "updated_at",
            header: "ATUALIZADO EM"
        }
    ]
}