import {CancelItemBox} from "../../../components/CancelItemBox";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control} from "react-hook-form";
import {PaymentProps, ProductInHighlight, SelectOptionProps} from "../../../types";
import {getPaymentById} from "../../../services/payments";
import {PaymentBox} from "./components/PaymentBox";
import {Container} from "../../../components/Container";
import {Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {Button} from "../../../components/Button";
import {Dialog} from "../../../components/Dialog";
import {createRefund} from "../../../services/refunds";

export function PaymentDetails () {
    const {onOpen: refundDialogOnOpen, isOpen: refundDialogIsOpen, onClose: refundDialogOnClose} = useDisclosure()
    const params = useParams()
    const toast = useToast()
    const paymentId = Number(params.paymentId)
    const navigate = useNavigate()
    const [payment, setPayment] = useState<PaymentProps>({} as PaymentProps)
    const [isSaving, setIsSaving] = useState(false)

    const getPaymentData = async () => {
        const response = await getPaymentById(paymentId)
        console.log({data: response.data})
        setPayment({
            ...response.data
        })
    }

    const handleRefund = async () => {
        setIsSaving(true)
        const response = await createRefund(Number(paymentId))

        if (response.status !== 201) {
            setIsSaving(false)
            return toast({
                status: "error",
                title: "Erro ao criar estorno",
                description: response.data.message || "Houve um erro ao criar o estorno, por favor tente novamente",
                isClosable: true
            })
        }
        refundDialogOnClose()
        await getPaymentData()
        setIsSaving(false)
        return toast({
            status: "success",
            title: "Estorno criado",
            description: response.data.message || "Estorno criado com sucesso",
            isClosable: true
        })

    }

    useEffect(() => {
        getPaymentData()
    }, [])

    return (
        <>
            <Dialog
                onAccept={handleRefund}
                isOpen={refundDialogIsOpen}
                onClose={refundDialogOnClose}
                title={"Confirmar estorno"}
                description={"Ao confirmar, o estorno será realizado"}
            />
            <CancelItemBox title={"Voltar para lista de pagamentos"} onClick={() => navigate('/payments')} />
            <Accordion title={"Dados do pagamento"} defaultExpanded={true} >
                <PaymentBox values={payment} />
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`}  onClick={() => navigate("/payments")}>Voltar</Button>
                    {payment?.id_status === 3 && (
                        <Button className={`!bg-red-500 !text-white`} onClick={() => refundDialogOnOpen()}> Gerar estorno {isSaving && <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                    )}
                </Flex>
            </Container>
        </>
    )
}