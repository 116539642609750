import {
    Box,
    Flex,
    Input as ChakraUiInput,
    InputProps as ChakraUiInputProps,
    Stack,
    Text,
    useToast
} from '@chakra-ui/react'
import {Control, useController, UseControllerProps, useForm} from "react-hook-form";
import React, {ChangeEvent, createRef, useEffect, useRef, useState} from "react";
import {AiFillCloseCircle} from "react-icons/ai";
import {v4 as uuid} from 'uuid';

type InputProps = (ChakraUiInputProps & Partial<UseControllerProps>) & {
    label?: string
    labelClassName?: string
    name?: string
    onSelectInvalidType?: () => void
    onRemoveMedia?: (media: MediaProps) => void
    onlyImage?: boolean
    onlyVideo?: boolean
}
export type MediaProps = {
    id?: number
    uuid: string
    url: string
    type?: "video" | "image"
    file?: File
}
const imageTypesAllowed = [
    "image/png",
    "image/jpeg",
    "image/jpg"
]
const videoTypesAllowed = [
    "video/mpeg",
    "video/mp4",
    "video/avi"
]
const mimeTypesAllowed = [
    ...imageTypesAllowed,
    ...videoTypesAllowed
]

export function MediaInput({
                               label,
                               labelClassName,
                               onSelectInvalidType,
                               onRemoveMedia,
                               name = "",
                               multiple = true,
                               accept = mimeTypesAllowed.join(", "),
                               ...input
                           }: InputProps) {
    const toast = useToast()
    const inputRef = createRef<HTMLInputElement>()
    const uniqueVideoRef = createRef<HTMLVideoElement>()
    const [medias, setMedias] = useState<MediaProps[]>([])
    const mediaFirstRender = useRef(false)
    const {control: controlDefault} = useForm()
    const {fieldState, field} = useController({
        control: input.control || controlDefault,
        rules: input.rules,
        name: name,
        defaultValue: input.defaultValue
    })
    const updateFileList = () => {
        if (inputRef?.current) {
            const dt = new DataTransfer()

            for (const media of medias) {
                if (media.file) {
                    dt.items.add(media.file)
                }
            }

            inputRef.current.files = dt.files
        }
    }

    const onSelectMedia = (e: ChangeEvent<HTMLInputElement>) => {
        if (e?.target?.files && e?.target?.files?.length > 0) {
            const urls: MediaProps[] = []

            Array.from(e?.target?.files).forEach((file) => {
                urls.push({
                    url: URL.createObjectURL(file),
                    file,
                    uuid: uuid(),
                    type: file.type.includes("image") ? "image" : "video"
                })
            })

            setMedias(prev => {
                let newMedias = urls
                if (multiple) {
                    newMedias = [...prev, ...urls]
                }
                if (newMedias.length === 1) {
                    field.onChange(newMedias[0])
                } else {
                    field.onChange(newMedias)
                }
                return newMedias
            })
        }
        updateFileList()
    }
    const validateMedia = (e: ChangeEvent<HTMLInputElement>) => {

        if (e?.target?.files && e?.target?.files?.length > 0) {

            const file = e.target.files[0]

            if (!mimeTypesAllowed.find(item => item === file.type as string)) {
                toast({
                    status: "error",
                    title: "Tipo inválido",
                    description: "O tipo de arquivo não é aceito",
                    isClosable: true
                })
                return false
            }
            return true
        }
        return true
    }

    useEffect(() => {
        if (mediaFirstRender.current) {
            return
        }
        if (field?.value) {
            setMedias((prev) => {
                if (field.value.length) {
                    return field.value.map((item: MediaProps) => {
                        return {
                            ...item,
                            uuid: uuid()
                        }
                    })
                } else {
                    return [{
                        ...field.value,
                        uuid: uuid()
                    }]
                }
            })
            mediaFirstRender.current = true
        }
    }, [field])
    useEffect(() => {
        if (!field?.value && medias?.length > 0) {
            setMedias([])
        }
    }, [field])

    const handleRemoveMedia = (uuid: string) => {
        setMedias(prev => {
            if (onRemoveMedia) {
                onRemoveMedia(prev.find(item => item.uuid === uuid)!)
            }
            const newMedias = prev.filter(item => item.uuid !== uuid)
            field.onChange(newMedias.length === 1 ? newMedias[0] : newMedias.length === 0 ? undefined : newMedias)
            return newMedias
        })
    }

    useEffect(() => {
        updateFileList()
    }, [medias])

    useEffect(() => {
        if (uniqueVideoRef?.current) {
            uniqueVideoRef.current.load()
        }
    }, [uniqueVideoRef])
    console.log({medias})
    return (
        <>
            <Stack spacing={2} className={`${labelClassName ? labelClassName : ``}`}>
                {medias?.length > 0 && medias?.map((media, key) => {
                    return (
                        <Box key={key}
                             className={`px-2 bg-gray-300 dark:bg-neutral-800 rounded-md mb-2 flex justify-center relative`}>
                            {media.type === "image" && (
                                <img src={media.url} className={"h-60 w-auto"}/>
                            )}
                            {media.type === "video" && (
                                <video ref={medias.length === 1 ? uniqueVideoRef : undefined} className={"h-60"}
                                       controls>
                                    <source src={media.url}/>
                                </video>
                            )}
                            <Flex gap={2} className={`absolute right-2 top-2`}>
                                <Box className={`p-1 rounded-full bg-neutral-800 text-white fill-white cursor-pointer`}
                                     onClick={() => handleRemoveMedia(media.uuid)}>
                                    <AiFillCloseCircle/>
                                </Box>
                            </Flex>
                        </Box>
                    )
                })
                }
            </Stack>
            <label className={`flex flex-col ${labelClassName ? labelClassName : ``}`}>
                {label && (
                    <Text
                        className={"mb-2 whitespace-nowrap overflow-ellipsis overflow-hidden max-w-full"}>{label}</Text>
                )}
                <ChakraUiInput
                    {...input}
                    {...field}
                    ref={inputRef}
                    value={input.value}
                    type={"file"}
                    multiple={multiple}
                    accept={(input.onlyImage ? imageTypesAllowed.join(`, `) : (input.onlyVideo ? videoTypesAllowed.join(`, `) : accept))}
                    className={`!border-gray-300 p-1 ${!!input.className && input.className} placeholder:text-black dark:placeholder:text-white ${fieldState.error && `!border-red-500`}`}
                    onChange={(e) => {
                        if (!validateMedia(e)) {
                            if (onSelectInvalidType) {
                                onSelectInvalidType()
                            }
                        }
                        if (input.onChange) {
                            input.onChange(e)
                        }
                        onSelectMedia(e)
                    }}
                />
            </label>
        </>
    )
}