import {SimpleGrid} from "@chakra-ui/react";
import {Input} from "../../../../../components/Input";
import React from "react";
import { Control } from "react-hook-form";
import {MediaInput} from "../../../../../components/MediaInput";

type CreateAndEditBoxProps = {
    control: Control<any>
    isEditing?: boolean
    isLoading?: boolean
    onSelectInvalidType?: () => void
}

export function CreateAndEditBox({control, isEditing, onSelectInvalidType, isLoading}: CreateAndEditBoxProps) {
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Input control={control} name={'name'} labelClassName={"flex-1"}
                   label={"Nome *"} placeholder={"Digite o nome *"}
            />
            <Input control={control} name={'slug'} labelClassName={"flex-1"}
                   label={"Slug *"} placeholder={"Digite o slug *"}
            />
        </SimpleGrid>
    )
}