import {Flex, SimpleGrid, Stack} from "@chakra-ui/react";
import React from "react";
import { Control } from "react-hook-form";
import {IoMdAdd} from "react-icons/io"
import {List} from "../../../../../components/List";
import {listColumns} from "./listColumns";
import {OrderProps} from "../../../../../types";
import {Text} from "../../../../../components/Text";
import {currencyFormat} from "../../../../../helpers";

type ProductsBoxProps = {
    isEditing?: boolean
    isLoading?: boolean
    onSelectInvalidType?: () => void
    onAddProduct?: () => void
    onRemoveProduct?: (productId: number) => void
    values: OrderProps
}

export function OrderBox({values, isEditing, onSelectInvalidType, onAddProduct, onRemoveProduct, isLoading}: ProductsBoxProps) {
    return (
        <Stack spacing={1} className={"flex-1 p-2 bg-gray-200 dark:bg-neutral-800 text-sm"}>
            <Text><b>ID :</b> {values?.id}</Text>
            <Text><b>ID CLIENTE :</b> {values?.id_client}</Text>
            <Text><b>NOME CLIENTE :</b> {values?.clients?.first_name} {values?.clients?.last_name}</Text>
            <Text><b>E-MAIL CLIENTE :</b> {values?.clients?.email}</Text>
            <Text><b>STATUS :</b> {values?.order_status?.name}</Text>
            <Text><b>VALOR TOTAL :</b> {currencyFormat(Number(values?.price))}</Text>
            <Text><b>VALOR PEDIDOS :</b> {currencyFormat((Number(values?.price) - Number(values?.delivery_price)) - Number(values?.tax_price))}</Text>
            <Text><b>VALOR FRETE :</b> {currencyFormat(Number(values?.delivery_price))}</Text>
            <Text><b>VALOR TAXA :</b> {currencyFormat(Number(values?.tax_price))}</Text>
            <Text><b>PRODUTOS :</b> {values?.order_products?.length}</Text>
        </Stack>
    )
}