import {BoxProps, Flex} from "@chakra-ui/react";
import {Text} from "../Text";
import React from "react";
import {IoIosAddCircle} from "react-icons/io";
import {Container} from "../Container";
type NewItemBox = BoxProps & {
    title?: string
}
export function NewItemBox({title = "Novo", ...rest}: NewItemBox) {
    return (
        <>
            <Container {...rest} className={`cursor-pointer justify-between items-center flex ${rest?.className}`}>
                <Text className={"font-medium"}>
                    {title}
                </Text>
                <IoIosAddCircle className={`fill-red-500`} />
            </Container>
        </>
    )
}