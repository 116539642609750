import {Accordion} from "../../components/Accordion";
import {PaginationInfoProps, ProductProps} from "../../types";
import { Table } from "../../components/Table";
import {tableColumns} from "./tableColumns"
import {deleteProduct, deleteProductFinal, getProducts} from "../../services/products";
import React, {useEffect, useState} from "react";
import {Control, FieldValues, useForm} from "react-hook-form";
import {SearchBox} from "./searchBox";
import {NewItemBox} from "../../components/NewItemBox";
import {useNavigate} from "react-router-dom";
import {useDisclosure, useToast} from "@chakra-ui/react";
import {Dialog} from "../../components/Dialog";
import {debounce} from "debounce";

export function Products() {
    const {onOpen: deleteDialogOnOpen, isOpen: deleteDialogIsOpen, onClose: deleteDialogOnClose} = useDisclosure()
    const [deleteId, setDeleteId] = useState(0)
    const toast = useToast()
    const navigate = useNavigate()
    const {
        control,
        watch
    } = useForm()
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfoProps>({
        per_page: 10,
        page: 1,
        last_page: 0,
        total: 0,
        to: 0
    })

    const [data, setData] = useState<ProductProps[]>([] as ProductProps[])

    useEffect(() => {
    }, [data])

    const getData = debounce(async (page?: number) => {
        const response = await getProducts({
            per_page: paginationInfo.per_page,
            page: page ? page : paginationInfo.page,
            ...watch()
        })
        if (response.isAxiosError) {
            return
        }
        setData(response.data.products.map((product: ProductProps) => {
            product.created_at = new Date(product.created_at).toLocaleString('pt-br')
            if (product.updated_at) {
                product.updated_at = new Date(product.updated_at).toLocaleString('pt-br')
            }
            return product
        }))
        setPaginationInfo(oldValue => {
            return {
                ...oldValue,
                last_page: response.data.total_pages,
                total: response.data.total_pages,
                to: response.data.total_count,
                page: response.data.page
            }
        })
    }, 750)
    const onPageChange = (page: number) => {
        getData(page + 1)
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        watch((data, {name}) => {
            getData(1)
        })
    }, [])
    const handleEdit = (productId: number) => {
        return navigate(`/products/${productId}`)
    }
    const handleDelete = async () => {
        const response = await deleteProductFinal(deleteId)
        if (response?.status !== 204) {
            return toast({
                status: "error",
                title: "Erro ao deletar produto",
                description: response.data.message || "Houve um erro deletar o produto, por favor tente novamente",
                isClosable: true
            })
        }
        getData()
        return toast({
            status: "success",
            title: "Produto deletado com sucesso",
            description: response.data.message || "O produto foi deletado com sucesso",
            isClosable: true
        })
    }
    return (
        <>
            <Dialog
                onAccept={handleDelete}
                isOpen={deleteDialogIsOpen}
                onClose={deleteDialogOnClose}
                title={"Deletar produto"}
                description={"Ao confirmar, o produto será deletado"}
            />
            <NewItemBox title={"Novo produto"} className={""} onClick={() => navigate(`/products/create`)} />
            <Accordion title={"Digite sua busca"} defaultExpanded={false} className={""}>
                <SearchBox control={control} />
            </Accordion>
            <Accordion title={"Produtos"} panelClassName={`overflow-auto max-h-screen`}>
                <Table data={data} columns={tableColumns({
                    onEdit: handleEdit,
                    onDelete:(productId) => {
                        setDeleteId(productId)
                        deleteDialogOnOpen()
                    }
                })} onPageChange={onPageChange} pageCount={paginationInfo.last_page} />
            </Accordion>
        </>
    )
}