import {Flex, SimpleGrid} from "@chakra-ui/react";
import React from "react";
import { Control } from "react-hook-form";
import {IoMdAdd} from "react-icons/io"
import {List} from "../../../../../components/List";
import {listColumns} from "./listColumns";
import {CartProps} from "../../../../../types";

type ProductsBoxProps = {
    isEditing?: boolean
    isLoading?: boolean
    onSelectInvalidType?: () => void
    onAddProduct?: () => void
    onRemoveProduct?: (productId: number) => void
    values: CartProps
}

export function ProductsBox({values, isEditing, onSelectInvalidType, onAddProduct, onRemoveProduct, isLoading}: ProductsBoxProps) {
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            {values?.shopping_cart_products?.length > 0 && (
                <List className={`col-span-2`} columns={listColumns()} data={values?.shopping_cart_products} />
            )}
        </SimpleGrid>
    )
}