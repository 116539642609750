import {CancelItemBox} from "../../../components/CancelItemBox";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control} from "react-hook-form";
import {ProductsBox} from "./components/ProductsBox"
import {CartProps, ProductInHighlight, SelectOptionProps} from "../../../types";
import {getCartById} from "../../../services/carts";
import {CartBox} from "./components/CartBox";

export function CartDetails () {
    const params = useParams()
    const cartId = Number(params.cartId)
    const navigate = useNavigate()
    const [cart, setCart] = useState<CartProps>({} as CartProps)

    const getCartData = async () => {
        const response = await getCartById(cartId)
        console.log({data: response.data})
        setCart({
            ...response.data
        })
    }

    useEffect(() => {
        getCartData()
    }, [])

    return (
        <>
            <CancelItemBox title={"Voltar para lista de carrinhos"} onClick={() => navigate('/carts')} />
            <Accordion title={"Dados do carrinho"} defaultExpanded={true} >
                <CartBox values={cart} />
            </Accordion>
            <Accordion title={"Produtos do carrinho"} defaultExpanded={true} >
                <ProductsBox values={cart} />
            </Accordion>
        </>
    )
}