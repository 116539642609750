import {Flex, SimpleGrid, Spinner} from "@chakra-ui/react";
import {Input} from "../../../../../components/Input";
import {InputMask} from "../../../../../components/InputMask";
import {Control} from "react-hook-form";
import {Select} from "../../../../../components/Select";
import {Button} from "../../../../../components/Button";
import React, {useState} from "react";
import {Textarea} from "../../../../../components/Textarea";

type SearchBoxProps = {
    control: Control<any>
    isEditing?: boolean
    isLoading?: boolean
}

const deliveryFormats = [
    {value: 1, label: `Formato caixa/pacote`},
    {value: 2, label: `Formato rolo/prisma`},
    {value: 3, label: `Envelope`}
]

export function ProductDimensionsBox({control, isEditing, isLoading}: SearchBoxProps) {
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Select control={control} name={'delivery_format'} labelClassName={"flex-1 col-span-2"}
                    label={"Formato de envio *"} options={deliveryFormats} placeholder={"Selecione o formato de envio *"}
            />
            {/*<InputMask mask={"float"} control={control} name={'diameter_with_box'} labelClassName={"flex-1 col-span-2"}*/}
            {/*       label={"Diâmetro da caixa (m³) *"} placeholder={"Digite o diâmetro *"}*/}
            {/*/>*/}
            {/*<InputMask mask={"float"} control={control} name={'width_with_box'} labelClassName={"flex-1"}*/}
            {/*       label={"Largura da caixa (m) *"} placeholder={"Digite a largura *"}*/}
            {/*/>*/}
            {/*<InputMask mask={"float"} control={control} name={'height_with_box'} labelClassName={"flex-1"}*/}
            {/*       label={"Altura da caixa (m) *"} placeholder={"Digite a altura *"}*/}
            {/*/>*/}
            {/*<InputMask mask={"float"} control={control} name={'length_with_box'} labelClassName={"flex-1"}*/}
            {/*       label={"Comprimento da caixa (m) *"} placeholder={"Digite o comprimento *"}*/}
            {/*/>*/}
            {/*<InputMask mask={"float"} control={control} name={'weight_with_box'} labelClassName={"flex-1"}*/}
            {/*       label={"Peso com a caixa (kg) *"} placeholder={"Digite o peso *"}*/}
            {/*/>*/}
            <InputMask mask={"float"} rules={{required: true}} control={control} name={'diameter'} labelClassName={"flex-1 col-span-2"}
                   label={"Diâmetro (m³) *"} placeholder={"Digite o diâmetro *"}
            />
            <InputMask mask={"float"} rules={{required: true}} control={control} name={'width'} labelClassName={"flex-1"}
                   label={"Largura (m) *"} placeholder={"Digite a largura *"}
            />
            <InputMask mask={"float"} rules={{required: true}} control={control} name={'height'} labelClassName={"flex-1"}
                   label={"Altura (m) *"} placeholder={"Digite a altura *"}
            />
            <InputMask mask={"float"} rules={{required: true}} control={control} name={'length'} labelClassName={"flex-1"}
                   label={"Comprimento (m) *"} placeholder={"Digite o comprimento *"}
            />
            <InputMask mask={"float"} rules={{required: true}} control={control} name={'weight'} labelClassName={"flex-1"}
                   label={"Peso (kg) *"} placeholder={"Digite o peso *"}
            />
        </SimpleGrid>
    )
}