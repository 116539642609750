import {Accordion} from "../../components/Accordion";
import {PaginationInfoProps, UserProps} from "../../types";
import { Table } from "../../components/Table";
import {tableColumns} from "./tableColumns"
import {deleteStock, getStock, stockIn, stockOut} from "../../services/stock";
import React, {useEffect, useState} from "react";
import {Control, FieldValues, useForm} from "react-hook-form";
import {SearchBox} from "./searchBox";
import {NewItemBox} from "../../components/NewItemBox";
import {useNavigate} from "react-router-dom";
import {Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {Dialog} from "../../components/Dialog";
import {Modal} from "../../components/Modal";
import {Input} from "../../components/Input";
import {Button} from "../../components/Button";
import {getStockCreateAndEditErrors} from "../../helpers";

export function Stock() {
    const {onOpen: deleteDialogOnOpen, isOpen: deleteDialogIsOpen, onClose: deleteDialogOnClose} = useDisclosure()
    const [isStockInModalOpen, setIsStockInModalOpen] = useState(false)
    const [isStockOutModalOpen, setIsStockOutModalOpen] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [productId, setProductId] = useState<number|undefined>(undefined)
    const [stockQuantity, setStockQuantity] = useState<number|undefined>(undefined)
    const [deleteId, setDeleteId] = useState(0)
    const toast = useToast()
    const navigate = useNavigate()
    const {
        control,
        watch
    } = useForm()
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfoProps>({
        per_page: 10,
        page: 1,
        last_page: 0,
        total: 0,
        to: 0
    })

    const [data, setData] = useState<UserProps[]>([] as UserProps[])

    useEffect(() => {
    }, [data])

    const getData = async (page?: number) => {
        const response = await getStock({
            per_page: paginationInfo.per_page,
            page: page ? page : paginationInfo.page
        })
        if (response.isAxiosError) {
            return
        }
        setData(response.data.stock.map((stock: UserProps) => {
            stock.created_at = new Date(stock.created_at).toLocaleString('pt-br')
            if (stock.updated_at) {
                stock.updated_at = new Date(stock.updated_at).toLocaleString('pt-br')
            }
            return stock
        }))
        setPaginationInfo(oldValue => {
            return {
                ...oldValue,
                last_page: response.data.total_pages,
                total: response.data.total_pages,
                to: response.data.total_count,
                page: response.data.page
            }
        })
    }
    const onPageChange = (page: number) => {
        getData(page + 1)
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        watch((data, {name}) => {
            console.log({data, name})
        })
    }, [])
    const handleEdit = (stockId: number) => {
        return navigate(`/stock/${stockId}`)
    }
    const handleDelete = async () => {
        const response = await deleteStock(deleteId)
        if (response?.status !== 204) {
            return toast({
                status: "error",
                title: "Erro ao deletar estoque",
                description: response.data.message || "Houve um erro deletar o estoque, por favor tente novamente",
                isClosable: true
            })
        }
        getData()
        return toast({
            status: "success",
            title: "Estoque deletado com sucesso",
            description: response.data.message || "O estoque foi deletado com sucesso",
            isClosable: true
        })
    }

    const handleStockIn = (productId: number) => {
        setProductId(productId)
        setIsStockInModalOpen(true)
    }

    const handleStockOut = (productId: number) => {
        setProductId(productId)
        setIsStockOutModalOpen(true)
    }

    const saveStockIn = async () => {
        const errors = getStockCreateAndEditErrors({quantity: stockQuantity})

        let hasErrors = false

        if (errors.length > 0) {
            hasErrors = true
            for (const error of errors) {
                toast({
                    status: "error",
                    title: error.title,
                    description: error.description,
                    isClosable: true
                })
            }
        }

        if (hasErrors) {
            return
        }

        setIsSaving(true)
        const response = await stockIn(productId!, stockQuantity!)
        setIsSaving(false)

        if (response.status !== 201) {
            return toast({
                status: "error",
                title: "Erro ao cadastrar entrada",
                description: "Erro ao cadastrar entrada, tente novamente mais tarde",
                isClosable: true
            })
        }
        setProductId(undefined)
        setStockQuantity(undefined)
        setIsStockInModalOpen(false)
        getData()
        return toast({
            status: "success",
            title: "Entrada cadastrada",
            description: "Entrada cadastrada com sucesso",
            isClosable: true
        })
    }

    const saveStockOut = async () => {
        const errors = getStockCreateAndEditErrors({quantity: stockQuantity!})

        let hasErrors = false

        if (errors.length > 0) {
            hasErrors = true
            for (const error of errors) {
                toast({
                    status: "error",
                    title: error.title,
                    description: error.description,
                    isClosable: true
                })
            }
        }

        if (hasErrors) {
            return
        }

        setIsSaving(true)
        const response = await stockOut(productId!, stockQuantity!)
        setIsSaving(false)

        if (response.status !== 201) {
            return toast({
                status: "error",
                title: "Erro ao cadastrar saída",
                description: "Erro ao cadastrar saída, tente novamente mais tarde",
                isClosable: true
            })
        }
        setProductId(undefined)
        setStockQuantity(undefined)
        setIsStockOutModalOpen(false)
        getData()
        return toast({
            status: "success",
            title: "Saída cadastrada",
            description: "Saída cadastrada com sucesso",
            isClosable: true
        })
    }

    const StockFooter = ({action} : {action: "in" | "out"}) => {
        return (
            <Flex gap={2} className={`flex-1 justify-end`}>
                <Button className={`!bg-neutral-800 !text-white`} onClick={() => action === "in" ? setIsStockInModalOpen(false) : setIsStockOutModalOpen(false)}>Voltar</Button>
                <Button className={`!bg-red-500 !text-white`} onClick={() => action === "in" ? saveStockIn() : saveStockOut()}> Salvar {isSaving && <Spinner size={"sm"} className={`ml-1`}/>}</Button>
            </Flex>
        )
    }
    return (
        <>
            <Modal
                isOpen={isStockInModalOpen}
                onClose={() => {
                    setIsStockInModalOpen(false)
                    setProductId(undefined)
                    setStockQuantity(undefined)
                }}
                title={"Entrada de produto"}
                footer={<StockFooter action={`in`} />}
            >
                <Input type={"number"} placeholder={"Quantidade *"} label={"Quantidade *"} onChange={(e) => setStockQuantity(Number(e.target.value))} />
            </Modal>
            <Modal
                isOpen={isStockOutModalOpen}
                onClose={() => {
                    setIsStockOutModalOpen(false)
                    setProductId(undefined)
                    setStockQuantity(undefined)
                }}
                title={"Saída de produto"}
                footer={<StockFooter action={`out`} />}
            >
                <Input type={"number"} placeholder={"Quantidade *"} label={"Quantidade *"} onChange={(e) => setStockQuantity(Number(e.target.value))} />
            </Modal>
            <Dialog
                onAccept={handleDelete}
                isOpen={deleteDialogIsOpen}
                onClose={deleteDialogOnClose}
                title={"Deletar estoque"}
                description={"Ao confirmar, o estoque será deletado"}
            />
            <Accordion title={"Digite sua busca"} defaultExpanded={false} className={""}>
                <SearchBox control={control} />
            </Accordion>
            <Accordion title={"Estoque"} panelClassName={`overflow-auto max-h-screen`}>
                <Table data={data} columns={tableColumns({
                    onStockIn: handleStockIn,
                    onStockOut: handleStockOut
                })} onPageChange={onPageChange} pageCount={paginationInfo.last_page} />
            </Accordion>
        </>
    )
}