import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control, useForm} from "react-hook-form";
import {UserBox} from "../../../components/FormBox/UserBox";
import React, {useEffect, useState} from "react";
import {AddressProps, ProductInPromotion, PromotionProps, SelectOptionProps, UserProps} from "../../../types";
import {createAddress, getAddressById} from "../../../services/address";
import {createPromotion, getPromotionById, updatePromotion} from "../../../services/promotions";
import {Box, Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {
    getAddressCreateAndEditErrors,
    getPromotionCreateAndEditErrors, getPromotionProductAddErrors,
    getUserCreateAndEditErrors
} from "../../../helpers";
import {CancelItemBox} from "../../../components/CancelItemBox";
import {Dialog} from "../../../components/Dialog";
import {Container} from "../../../components/Container";
import {Button} from "../../../components/Button";
import {PasswordBox} from "../../../components/FormBox/PasswordBox";
import {CreateAndEditBox} from "./components/CreateAndEditBox";
import {NewItemBox} from "../../../components/NewItemBox";
import {ProductsBox} from "./components/ProductsBox";
import {getProducts as getAllProducts} from "../../../services/products";

export function PromotionCreateAndEdit() {
    const [isSaving, setIsSaving] = useState(false)
    const [products, setProducts] = useState<SelectOptionProps[]>([])

    const {onOpen: saveDialogOnOpen, isOpen: saveDialogIsOpen, onClose: saveDialogOnClose} = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()
    const {
        control: promotionControl,
        watch: promotionWatch,
        getValues: promotionGetValues,
        reset: promotionReset,
        resetField: promotionResetField
    } = useForm<PromotionProps>()
    const params = useParams()
    const promotionId = Number(params.promotionId)

    const getPromotionData = async () => {
        const response = await getPromotionById(promotionId)
        console.log({data: response.data})
        promotionReset({
            ...response.data,
            available_at: new Date(response.data.available_at).toLocaleDateString(),
            expire_at: new Date(response.data.expire_at).toLocaleDateString(),
            products: response.data.product_in_promotions.map((product: ProductInPromotion) => {
                return {
                    id: product.id_product_final,
                    name: product?.product_final?.name,
                    discount_percent: product?.discount_percent
                }
            })
        })
    }

    const getProducts = async () => {
        const response = await getAllProducts({per_page: 10000})
        console.log({response})
        if (response.status !== 200) {
            return toast({
                status: "error",
                title: "Erro ao buscar produtos",
                description: "Erro ao buscar produtos, tente novamente mais tarde",
                isClosable: true
            })
        }

        setProducts(response.data.products.map((product: any) => {
            return {
                value: product.id,
                label: product.name,
            }
        }))
    }
    useEffect(() => {

        if (promotionId) {
            getPromotionData()
        }
        getProducts()
    }, [])


    const handleSave = async () => {
        const promotionPayload = promotionGetValues()

        delete promotionPayload.discount_percent_to_add
        delete promotionPayload.product_to_add

        promotionPayload.products = promotionPayload?.products?.map((item) => item?.id)

        let hasErrors = false

        const promotionErrors = getPromotionCreateAndEditErrors(promotionPayload)

        const errors = [...promotionErrors]

        if (errors.length > 0) {
            hasErrors = true
            for (const error of errors) {
                toast({
                    status: "error",
                    title: error.title,
                    description: error.description,
                    isClosable: true
                })
            }
        }

        if (hasErrors) {
            return
        }

        promotionPayload.discount_percent = Number(promotionPayload.discount_percent)
        promotionPayload.available_at = new Date(promotionPayload.available_at)
        promotionPayload.expire_at = new Date(promotionPayload.expire_at)

        let promotionResponse: any
        setIsSaving(true)
        if (promotionId) {
            promotionResponse = await updatePromotion(promotionId, {...promotionPayload})
        } else {
            promotionResponse = await createPromotion({...promotionPayload})
        }
        if (promotionResponse.status !== 201 && promotionResponse.status !== 200) {
            setIsSaving(false)
            return toast({
                status: "error",
                title: "Erro ao salvar promoção",
                description: promotionResponse.data.message || "Houve um erro salvar a promoção, por favor tente novamente",
                isClosable: true
            })
        }
        setIsSaving(false)

        navigate('/promotions')
        return toast({
            status: "success",
            title: "Promoção salva com sucesso",
            description: promotionResponse.data.message || "A promoção foi salva com sucesso",
            isClosable: true
        })

    }
    const handleCancel = () => {
        return navigate("/promotions")
    }

    const handleAddProduct = () => {
        const data = promotionGetValues()

        let hasErrors = false
        const errors = getPromotionProductAddErrors(data)

        if (errors.length > 0) {
            hasErrors = true
            for (const error of errors) {
                toast({
                    status: "error",
                    title: error.title,
                    description: error.description,
                    isClosable: true
                })
            }
        }

        if (hasErrors) {
            return
        }
        const products = data?.products || []
        const productExists = data?.products?.find((product: any) => product?.id === data?.product_to_add?.value)

        if (productExists) {
            return toast({
                status: "error",
                title: "Produto já cadastrado",
                description: "O produto já se encontra cadastrado na lista, salve para persistir",
                isClosable: true
            })
        }

        promotionReset({
            ...data,
            products: [...products, {id: data.product_to_add.value, name: data.product_to_add.label, discount_percent: data.discount_percent_to_add}],
            product_to_add: null,
            discount_percent_to_add: ""
        })
        console.log({data})
    }

    const handleRemoveProduct = (productId: number) => {
        const data = promotionGetValues()

        const products = data?.products?.filter((product: any) => product?.id !== productId)

        promotionReset({
            ...data,
            products: [...products],
            product_to_add: null,
            discount_percent_to_add: ""
        })
        console.log({data})
    }

    return (
        <>
            <Dialog
                onAccept={handleSave}
                isOpen={saveDialogIsOpen}
                onClose={saveDialogOnClose}
                title={"Salvar promoção"}
                description={"Ao confirmar, a promoção será salvo"}
            />
            <CancelItemBox title={"Voltar para lista de promoções"} onClick={() => navigate('/promotions')} />
            <Accordion title={"Dados da promoção"} defaultExpanded={true}>
                <CreateAndEditBox control={promotionControl as Control<any>} isEditing={!!promotionId} />
            </Accordion>
            <Accordion title={"Produtos da promoção"} >
                <ProductsBox onRemoveProduct={handleRemoveProduct} values={promotionWatch()} onAddProduct={handleAddProduct} products={products} control={promotionControl as Control<any>} isEditing={!!promotionId} />
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => handleCancel()}>Voltar</Button>
                    <Button className={`!bg-red-500 !text-white`} onClick={() => saveDialogOnOpen()}> Salvar {isSaving && <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                </Flex>
            </Container>
        </>
    )
}