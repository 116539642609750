import {api} from "./axios";
import {UserProps} from "../types";

export const getOrders = async (params?: any) => {
    return await api.get(`/order`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getOrderById = async (orderId?: number) => {
    return await api.get(`/order/${orderId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateOrder = async (orderId: number, payload?: any) => {
    return await api.put(`/order/${orderId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const postOrder = async (orderId: number) => {
    return await api.post(`/shipping/order/${orderId}/post`, {
    }).catch(({response}) => {
        return response
    })
}
export const generateLabels = async (orderId: number) => {
    return await api.get(`/shipping/order/${orderId}/label`, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteOrder = async (orderId: number) => {
    return await api.delete(`/order/${orderId}`, {
    }).catch(({response}) => {
        return response
    })
}