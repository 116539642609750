import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control, useForm} from "react-hook-form";
import {UserBox} from "../../../components/FormBox/UserBox";
import React, {useEffect, useRef, useState} from "react";
import {AddressProps, SubcategoryProps, DepartmentProps, SelectOptionProps, UserProps} from "../../../types";
import {createAddress, getAddressById} from "../../../services/address";
import {createSubcategory, getSubcategoryById, updateSubcategory} from "../../../services/subcategories";
import {Box, Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {
    getAddressCreateAndEditErrors,
    getSubcategoryCreateAndEditErrors,
    getUserCreateAndEditErrors
} from "../../../helpers";
import {CancelItemBox} from "../../../components/CancelItemBox";
import {Dialog} from "../../../components/Dialog";
import {Container} from "../../../components/Container";
import {Button} from "../../../components/Button";
import {PasswordBox} from "../../../components/FormBox/PasswordBox";
import {CreateAndEditBox} from "./components/CreateAndEditBox";
import {NewItemBox} from "../../../components/NewItemBox";
import {getDepartments as getAllDepartments} from "../../../services/departments";
import {getCategories as getAllCategories} from "../../../services/categories";

export function SubcategoryCreateAndEdit() {
    const [isSaving, setIsSaving] = useState(false)
    const [departments, setDepartments] = useState<SelectOptionProps[]>([])
    const [categories, setCategories] = useState<SelectOptionProps[]>([])
    const dataIsLoading = useRef(false)
    const {onOpen: saveDialogOnOpen, isOpen: saveDialogIsOpen, onClose: saveDialogOnClose} = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()
    const {
        control: subcategoryControl,
        watch: subcategoryWatch,
        getValues: subcategoryGetValues,
        reset: subcategoryReset,
        resetField: subcategoryResetField
    } = useForm<Partial<SubcategoryProps>>()
    const params = useParams()
    const subcategoryId = Number(params.subcategoryId)

    const getSubcategoryData = async () => {
        dataIsLoading.current = true
        const response = await getSubcategoryById(subcategoryId)
        dataIsLoading.current = false
        subcategoryReset({
            ...response.data,
            department: {value: response.data.product_categories.product_departments.id, label: response.data.product_categories.product_departments.name},
            category: {value: response.data.product_categories.id, label: response.data.product_categories.name}
        })
    }


    const handleSave = async () => {
        const subcategoryPayload = subcategoryGetValues()

        let hasErrors = false

        const subcategoryErrors = getSubcategoryCreateAndEditErrors(subcategoryPayload)

        const errors = [...subcategoryErrors]

        if (errors.length > 0) {
            hasErrors = true
            for (const error of errors) {
                toast({
                    status: "error",
                    title: error.title,
                    description: error.description,
                    isClosable: true
                })
            }
        }

        if (hasErrors) {
            return
        }

        const {category} = subcategoryPayload
        delete subcategoryPayload.department
        delete subcategoryPayload.category
        delete subcategoryPayload.product_categories
        delete subcategoryPayload.filters_hidden

        let subcategoryResponse: any
        setIsSaving(true)
        if (subcategoryId) {
            if (category?.value) {
                subcategoryPayload.id_category = category.value
            }
            subcategoryResponse = await updateSubcategory(subcategoryId, {...subcategoryPayload})
        } else {
            subcategoryResponse = await createSubcategory(category?.value, {...subcategoryPayload})
        }
        if (subcategoryResponse.status !== 201 && subcategoryResponse.status !== 200) {
            setIsSaving(false)
            return toast({
                status: "error",
                title: "Erro ao salvar subcategoria",
                description: subcategoryResponse.data.message || "Houve um erro salvar a subcategoria, por favor tente novamente",
                isClosable: true
            })
        }
        setIsSaving(false)

        navigate('/subcategories')
        return toast({
            status: "success",
            title: "Subcategoria salva com sucesso",
            description: subcategoryResponse.data.message || "A subcategoria foi salva com sucesso",
            isClosable: true
        })

    }
    const handleCancel = () => {
        return navigate("/subcategories")
    }

    const getCategories = async (departmentId: number) => {
        subcategoryResetField('category', {defaultValue: null})
        const response = await getAllCategories(departmentId)
        if (response.status !== 200) {
            setCategories([])
            return
        }
        setCategories(response.data.map((department: DepartmentProps) => {
            return {
                value: department.id,
                label: department.name
            }
        }))
    }

    const getDepartments = async () => {
        const response = await getAllDepartments()
        if (response.isAxiosError) {
            return
        }
        setDepartments(response.data.map((department: DepartmentProps) => {
            return {
                value: department.id,
                label: department.name
            }
        }))
    }

    const getInitialData = async () => {
        if (departments.length < 1) {
            await getDepartments()
        }
        if (subcategoryId) {
            await getSubcategoryData()
        }
    }

    useEffect(() => {
        getInitialData()
    }, [departments])

    const watchCalled = useRef(false)
    useEffect(() => {
        if (subcategoryWatch && !watchCalled.current) {
            watchCalled.current = true
            subcategoryWatch((data, {type, name}) => {
                const firstCall = type === undefined && name === undefined

                if (firstCall) {
                }

                if (name === "department") {
                    getCategories(data.department.value)
                }
            })
        }
    }, [])

    return (
        <>
            <Dialog
                onAccept={handleSave}
                isOpen={saveDialogIsOpen}
                onClose={saveDialogOnClose}
                title={"Salvar subcategoria"}
                description={"Ao confirmar, a subcategoria será salvo"}
            />
            <CancelItemBox title={"Voltar para lista de subcategorias"} onClick={() => navigate('/subcategories')}/>
            <Accordion title={"Dados da subcategoria"} defaultExpanded={true}>
                <CreateAndEditBox departments={departments} categories={categories} control={subcategoryControl as Control<any>}
                                  isEditing={!!subcategoryId}/>
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => handleCancel()}>Voltar</Button>
                    <Button className={`!bg-red-500 !text-white`}
                            onClick={() => saveDialogOnOpen()}> Salvar {isSaving &&
                    <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                </Flex>
            </Container>
        </>
    )
}