import {Flex, SimpleGrid, Spinner} from "@chakra-ui/react";
import {Input} from "../../../../../components/Input";
import {Control} from "react-hook-form";
import {Select} from "../../../../../components/Select";
import {Button} from "../../../../../components/Button";
import React, {useState} from "react";
import {Textarea} from "../../../../../components/Textarea";
import {SelectOptionProps} from "../../../../../types";
import {TextEditor} from "../../../../../components/TextEditor";

type SearchBoxProps = {
    control: Control<any>
    isEditing?: boolean
    isLoading?: boolean
    departments?: SelectOptionProps[]
    categories?: SelectOptionProps[]
    subcategories?: SelectOptionProps[]
    manufacturers?: SelectOptionProps[]
}

export function ProductDataBox({control, departments, categories, subcategories, manufacturers, isEditing, isLoading}: SearchBoxProps) {
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Input control={control} name={'name'} labelClassName={"flex-1 col-span-2"}
                   label={"Nome *"} placeholder={"Digite o nome *"}
            />
            <TextEditor control={control} name={'description'} labelClassName={"flex-1 col-span-2"}
                       label={"Descrição *"} placeholder={"Digite a descrição *"}
            />
            <Select control={control} options={manufacturers} name={'manufacturer'} labelClassName={"flex-1"}
                    label={"Fabricante *"} placeholder={"Selecione o fabricante *"}
            />
            <Select control={control} options={departments} name={'department'} labelClassName={"flex-1"}
                    label={"Departamento *"} placeholder={"Selecione o departamento *"}
            />
            <Select control={control} options={categories} name={'category'} labelClassName={"flex-1"}
                    label={"Categoria *"} placeholder={"Selecione a categoria *"}
            />
            <Select control={control} options={subcategories} name={'subcategory'} labelClassName={"flex-1"}
                    label={"Subcategoria"} placeholder={"Selecione a subcategoria"}
            />
        </SimpleGrid>
    )
}