import {Accordion} from "../../components/Accordion";
import {AdProps, PaginationInfoProps, ProductProps, UserProps} from "../../types";
import { Table } from "../../components/Table";
import {tableColumns} from "./tableColumns"
import {deleteAd, getAds} from "../../services/ads";
import React, {useEffect, useState} from "react";
import {Control, FieldValues, useForm} from "react-hook-form";
import {SearchBox} from "./searchBox";
import {NewItemBox} from "../../components/NewItemBox";
import {useNavigate} from "react-router-dom";
import {useDisclosure, useToast} from "@chakra-ui/react";
import {Dialog} from "../../components/Dialog";

export function Ads() {
    const {onOpen: deleteDialogOnOpen, isOpen: deleteDialogIsOpen, onClose: deleteDialogOnClose} = useDisclosure()
    const [deleteId, setDeleteId] = useState(0)
    const toast = useToast()
    const navigate = useNavigate()
    const {
        control,
        watch
    } = useForm()
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfoProps>({
        per_page: 10,
        page: 1,
        last_page: 0,
        total: 0,
        to: 0
    })

    const [data, setData] = useState<UserProps[]>([] as UserProps[])

    useEffect(() => {
    }, [data])

    const getData = async (page?: number) => {
        const response = await getAds({
            per_page: paginationInfo.per_page,
            page: page ? page : paginationInfo.page
        })
        if (response.status !== 200) {
            return
        }
        setData(response.data.ads.map((ad: AdProps) => {
            return {
                ...ad,
                created_at: new Date(ad.created_at).toLocaleDateString(),
                updated_at:  ad.updated_at && new Date(ad.updated_at).toLocaleDateString(),
                start_date: ad.start_date && new Date(ad.start_date).toLocaleDateString(),
                end_date: ad.end_date && new Date(ad.end_date).toLocaleDateString(),
            }
        }))
        setPaginationInfo(oldValue => {
            return {
                ...oldValue,
                last_page: response.data.total_pages,
                total: response.data.total_pages,
                to: response.data.total_count,
                page: response.data.page
            }
        })
    }
    const onPageChange = (page: number) => {
        getData(page + 1)
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        watch((data, {name}) => {
            console.log({data, name})
        })
    }, [])
    const handleEdit = (adId: number) => {
        return navigate(`/ads/${adId}`)
    }
    const handleDelete = async () => {
        const response = await deleteAd(deleteId)
        if (response?.status !== 204) {
            return toast({
                status: "error",
                title: "Erro ao deletar anúncio",
                description: response.data.message || "Houve um erro deletar o anúncio, por favor tente novamente",
                isClosable: true
            })
        }
        getData()
        return toast({
            status: "success",
            title: "Anúncio deletado com sucesso",
            description: response.data.message || "O anúncio foi deletado com sucesso",
            isClosable: true
        })
    }
    return (
        <>
            <Dialog
                onAccept={handleDelete}
                isOpen={deleteDialogIsOpen}
                onClose={deleteDialogOnClose}
                title={"Deletar anúncio"}
                description={"Ao confirmar, o anúncio será deletado"}
            />
            <NewItemBox title={"Novo anúncio"} className={""} onClick={() => navigate(`/ads/create`)} />
            <Accordion title={"Digite sua busca"} defaultExpanded={false} className={""}>
                <SearchBox control={control} />
            </Accordion>
            <Accordion title={"Anúncios"} panelClassName={`overflow-auto max-h-screen`}>
                <Table data={data} columns={tableColumns({
                    onEdit: handleEdit,
                    onDelete:(adId) => {
                        setDeleteId(adId)
                        deleteDialogOnOpen()
                    }
                })} onPageChange={onPageChange} pageCount={paginationInfo.last_page} />
            </Accordion>
        </>
    )
}