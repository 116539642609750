import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control, useForm} from "react-hook-form";
import {UserBox} from "../../../components/FormBox/UserBox";
import React, {useEffect, useState} from "react";
import {AddressProps, UserProps} from "../../../types";
import {createAddress, getAddressById} from "../../../services/address";
import {createClient, getClientById, updateClient} from "../../../services/clients";
import {Box, Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {getAddressCreateAndEditErrors, getUserCreateAndEditErrors} from "../../../helpers";
import {CancelItemBox} from "../../../components/CancelItemBox";
import {Dialog} from "../../../components/Dialog";
import {Container} from "../../../components/Container";
import {Button} from "../../../components/Button";
import {PasswordBox} from "../../../components/FormBox/PasswordBox";

export function ClientCreateAndEdit() {
    const [isSaving, setIsSaving] = useState(false)

    const {onOpen: saveDialogOnOpen, isOpen: saveDialogIsOpen, onClose: saveDialogOnClose} = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()
    const {
        control: clientControl,
        watch: clientWatch,
        getValues: clientGetValues,
        reset: clientReset,
        resetField: clientResetField
    } = useForm<Partial<UserProps>>()
    const params = useParams()
    const clientId = Number(params.clientId)

    const getClientData = async () => {
        const response = await getClientById(clientId)
        clientReset({
            ...response.data,
            password: undefined
        })
    }
    useEffect(() => {

        if (clientId) {
            getClientData()
        }
    }, [])


    const handleSave = async () => {
        const clientPayload = clientGetValues()

        let hasErrors = false

        const clientErrors = getUserCreateAndEditErrors(clientPayload, !!clientId)

        const errors = [...clientErrors]

        if (errors.length > 0) {
            hasErrors = true
            for (const error of errors) {
                toast({
                    status: "error",
                    title: error.title,
                    description: error.description,
                    isClosable: true
                })
            }
        }

        if (hasErrors) {
            return
        }

        delete clientPayload.password_repeat
        let clientResponse: any
        setIsSaving(true)
        if (clientId) {
            clientResponse = await updateClient(clientId, {...clientPayload})
        } else {
            clientResponse = await createClient({...clientPayload})
        }
        if (clientResponse.status !== 201 && clientResponse.status !== 200) {
            setIsSaving(false)
            return toast({
                status: "error",
                title: "Erro ao salvar cliente",
                description: clientResponse.data.message || "Houve um erro salvar o cliente, por favor tente novamente",
                isClosable: true
            })
        }
        setIsSaving(false)

        navigate('/clients')
        return toast({
            status: "success",
            title: "Cliente salvo com sucesso",
            description: clientResponse.data.message || "O cliente foi salvo com sucesso",
            isClosable: true
        })

    }
    const handleCancel = () => {
        return navigate("/clients")
    }

    return (
        <>
            <Dialog
                onAccept={handleSave}
                isOpen={saveDialogIsOpen}
                onClose={saveDialogOnClose}
                title={"Salvar cliente"}
                description={"Ao confirmar, o cliente será salvo"}
            />
            <CancelItemBox title={"Voltar para lista de clientes"} onClick={() => navigate('/clients')} />
            <Accordion title={"Dados do cliente"} defaultExpanded={true}>
                <UserBox isClient={true} isReadOnly={true} control={clientControl as Control<any>} isEditing={!!clientId} />
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => handleCancel()}>Voltar</Button>
                    {/*<Button className={`!bg-red-500 !text-white`} onClick={() => saveDialogOnOpen()}> Salvar {isSaving && <Spinner size={"sm"} className={`ml-1`}/>}</Button>*/}
                </Flex>
            </Container>
        </>
    )
}