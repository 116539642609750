import {Box, Flex, SimpleGrid, Stack} from "@chakra-ui/react";
import React from "react";
import {Control} from "react-hook-form";
import {IoMdAdd} from "react-icons/io"
import {List} from "../../../../../components/List";
import {listColumns} from "./listColumns";
import {RefundProps} from "../../../../../types";
import {Text} from "../../../../../components/Text";
import { Link } from "../../../../../components/Link";
import {useNavigate} from "react-router-dom";
import {currencyFormat} from "../../../../../helpers";

type ProductsBoxProps = {
    isEditing?: boolean
    isLoading?: boolean
    onSelectInvalidType?: () => void
    onAddProduct?: () => void
    onRemoveProduct?: (productId: number) => void
    values: RefundProps
}

export function RefundBox({
                               values,
                               isEditing,
                               onSelectInvalidType,
                               onAddProduct,
                               onRemoveProduct,
                               isLoading
                           }: ProductsBoxProps) {
    const navigate = useNavigate()
    return (
        <Stack spacing={1} className={"flex-1 p-2 bg-gray-200 dark:bg-neutral-800 text-sm"}>
            <Text><b>ID :</b> {values?.id}</Text>
            <Text><b>ID MAVERICK REFUND :</b> {values?.id_maverick_refund}</Text>
            <Text><b>ID PAGAMENTO :</b> <Link onClick={() => navigate(`/payments/${values?.payments?.id}`)}>{values?.payments?.id}</Link></Text>
            <Text><b>ID PEDIDO :</b> <Link onClick={() => navigate(`/orders/${values?.payments?.orders?.id}`)}>{values?.payments?.orders?.id}</Link></Text>
            <Text><b>STATUS :</b> {values?.refund_status?.name}</Text>
            <Text><b>VALOR TOTAL :</b> {currencyFormat(Number(values?.price))}</Text>
        </Stack>
    )
}