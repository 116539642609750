import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import {Routes} from "./routes";
import { ChakraProvider,
    theme,
    ColorModeProvider
} from '@chakra-ui/react'
import {ThemeProvider} from "./hooks/useTheme";
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
      <ChakraProvider theme={theme} resetCSS={true}>
          <ColorModeProvider>
              <ThemeProvider>
                              <Routes />
              </ThemeProvider>
          </ColorModeProvider>
      </ChakraProvider>
  // </React.StrictMode>
);
