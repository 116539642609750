import {Link as ChakraUiLink, LinkProps as ChakraUiLinkProps} from "@chakra-ui/react"
type LinkProps = ChakraUiLinkProps & {

}
export function Link({children, ...rest}: LinkProps) {
    return (
        <>
            <ChakraUiLink {...rest}>
                {children}
            </ChakraUiLink>
        </>
    )
}