import {TextLogo} from "../../../components/TextLogo";
import {Input} from "../../../components/Input";
import {Button} from "../../../components/Button";
import {Link} from "../../../components/Link";
import {Text} from "@chakra-ui/react";
import {WizardStepProps} from "../../../types/wizard";

export function CodeConfirm({...props}: Partial<WizardStepProps>) {
    return (
        <div className={"w-full flex flex-col items-center justify-center gap-5"}>
            <TextLogo className={"w-full"} />
            <Text>Digite o código recebido em seu e-mail para continuar</Text>
            <Input label={"Seu usuário"} className={"w-full"} labelClassName={"w-full"} />
            <Button className={`text-neutral-800`}>Confirmar código</Button>
            <Link onClick={() => props.goToStep && props.goToStep(2)}>Alterar o e-mail</Link>
            <Link onClick={() => props.goToStep && props.goToStep(1)}>Voltar para o login</Link>
        </div>
    )
}