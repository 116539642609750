import {Flex, SimpleGrid, Spinner} from "@chakra-ui/react";
import {Input} from "../../Input";
import {Control} from "react-hook-form";
import {Select} from "../../Select";
import {Button} from "../../Button";
import React, {useState} from "react";

type SearchBoxProps = {
    control: Control<any>
    isEditing?: boolean
    isLoading?: boolean
    isReadOnly?: boolean
    isClient?: boolean
}

export function UserBox({control, isEditing, isLoading, isReadOnly = false}: SearchBoxProps) {
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            {isReadOnly && (
                <>
                    <Input isReadOnly={isReadOnly} control={control} name={'id'} labelClassName={"flex-1"}
                           label={"ID"}
                    />
                    <Input isReadOnly={isReadOnly} control={control} name={'cpf'} labelClassName={"flex-1"}
                           label={"CPF"}
                    />
                </>
            )}
            <Input isReadOnly={isReadOnly} control={control} name={'first_name'} labelClassName={"flex-1"}
                   label={"Nome"}
            />
            <Input isReadOnly={isReadOnly} control={control} name={'last_name'} labelClassName={"flex-1"}
                   label={"Sobrenome"}
            />
            <Input isReadOnly={isReadOnly} control={control} name={'email'} labelClassName={"flex-1"}
                   label={"E-mail (Será enviado a senha neste e-mail)"}
            />
            <Input isReadOnly={isReadOnly} control={control} name={'phone'} labelClassName={"flex-1"}
                   label={"Telefone"}
            />
            {isReadOnly && (
                <>
                    <Input isReadOnly={isReadOnly} control={control} name={'created_at'} labelClassName={"flex-1"}
                           label={"Criado em"}
                    />
                    <Input isReadOnly={isReadOnly} control={control} name={'updated_at'} labelClassName={"flex-1"}
                           label={"Última atualização"}
                    />
                </>
            )}
        </SimpleGrid>
    )
}