import {api} from "./axios";
import {UserProps} from "../types";

export const getFilters = async (departmentId: number, params?: any) => {
    return await api.get(`/product/department/${departmentId}/filter`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getFilterById = async (filterId?: number) => {
    return await api.get(`/product/department/filter/${filterId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateFilter = async (filterId: number, payload?: any) => {
    return await api.put(`/product/department/filter/${filterId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createFilter = async (departmentId: number, payload?: any) => {
    return await api.post(`/product/department/${departmentId}/filter`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteFilter = async (filterId: number) => {
    return await api.delete(`/product/department/filter/${filterId}`, {
    }).catch(({response}) => {
        return response
    })
}