import {
    Box,
    Flex,
    Stack,
    Text, useMediaQuery,
    useToast
} from "@chakra-ui/react";
import {Item} from "./Item";
import {BiCategoryAlt, BiCategory} from "react-icons/bi"
import {BsFacebook, BsInstagram, BsImage, BsFillCartFill, BsFilterSquareFill} from "react-icons/bs"
import {FaFileInvoiceDollar, FaBox, FaThList, FaHandsHelping, FaUserFriends} from "react-icons/fa"
import {IoMdMegaphone} from "react-icons/io"
import {ImBoxAdd} from "react-icons/im"
import {MdCategory, MdPayment} from "react-icons/md"
import {RiAdminFill, RiRefund2Line, RiMoneyDollarCircleFill} from "react-icons/ri"
import {useLocation, useNavigate} from "react-router-dom"
import React, {useEffect, useRef, useState} from "react";
import {Button} from "../../../components/Button";
import {ImMenu} from "react-icons/im";
import {AiFillStar, AiFillPropertySafety} from "react-icons/ai";
import {useUser} from "../../../hooks/useUser";
import {CgDarkMode} from "react-icons/cg";
import {useTheme} from "../../../hooks/useTheme";

type NavigationProps = {
    admin?: boolean
    route: string
    title: string
    remove?: string
    icon: React.ReactNode
    isLoading?: boolean,
    validate?: () => boolean
    notFound?: boolean
    selected?: boolean
}

export function LeftNav() {
    const [isOpen, setIsOpen] = useState(false)
    const [isMobile] = useMediaQuery('(max-width: 768px)')
    const toast = useToast()
    const {user} = useUser()
    const location = useLocation()
    const navigate = useNavigate()
    const {toggleTheme} = useTheme()


    useEffect(() => {
        if (localStorage) {
            if (localStorage.getItem("FindScrap@LeftNavIsOpen") === "true") {
                setIsOpen(true)
            } else {
                localStorage.setItem("FindScrap@LeftNavIsOpen", "false")
                setIsOpen(false)
            }
        }
    }, [localStorage])

    const disabledRoute = () => {
        return false
    }
    const validateDisabledRoute = () => {
        toast({
            status: "error",
            title: "Recurso indisponível",
            description: "O recurso encontra-se indisponível no momento",
            isClosable: true
        })
        return false
    }
    const navigation: NavigationProps[] = [
        {
            route: "administrators",
            title: "Administradores",
            icon: <RiAdminFill/>,
            admin: true
        },
        {
            route: "clients",
            title: "Clientes",
            icon: <FaUserFriends/>,
            admin: true
        },
        {
            route: "products",
            title: "Produtos",
            icon: <FaBox/>,
            admin: true
        },
        {
            route: "stock",
            title: "Estoque",
            icon: <ImBoxAdd/>,
            admin: true
        },
        {
            route: "departments",
            title: "Departamentos",
            icon: <MdCategory/>,
            admin: true
        },
        {
            route: "categories",
            remove: "sub",
            title: "Categorias",
            icon: <BiCategory/>,
            admin: true
        },
        {
            route: "subcategories",
            title: "Subcategorias",
            icon: <BiCategoryAlt/>,
            admin: true
        },
        {
            route: "manufacturers",
            title: "Fabricantes",
            icon: <AiFillPropertySafety/>
        },
        {
            route: "filters",
            title: "Filtros",
            icon: <BsFilterSquareFill/>
        },
        {
            route: "carts",
            title: "Carrinhos",
            icon: <BsFillCartFill/>
        },
        {
            route: "orders",
            title: "Pedidos",
            icon: <FaThList/>
        },
        {
            route: "payments",
            title: "Pagamentos",
            icon: <MdPayment/>
        },
        {
            route: "refunds",
            title: "Estornos",
            icon: <RiRefund2Line/>
        },
        {
            route: "invoices",
            title: "Notas fiscais",
            icon: <FaFileInvoiceDollar/>
        },
        {
            route: "tickets",
            title: "Atendimento",
            icon: <FaHandsHelping/>,
            notFound: true
        },
        {
            route: "promotions",
            title: "Promoções",
            icon: <RiMoneyDollarCircleFill/>
        },
        {
            route: "highlights",
            title: "Destaques",
            icon: <AiFillStar/>
        },
        {
            route: "ads",
            title: "Anúncios",
            icon: <IoMdMegaphone/>
        }
    ]

    const handleNavigateTo = (item: NavigationProps) => {
        closeMobileMenu()
        if (item.notFound) {
            return toast({
                status: "error",
                title: "Recurso indisponível",
                description: "O recurso encontra-se indisponível no momento",
                isClosable: true
            })
        }
        if (item.isLoading) {
            return toast({
                status: "error",
                title: "Aguardar carregamento",
                description: "Os dados estão sendo carregados",
                isClosable: true
            })
        }

        if (item.validate && !item.validate()) {
            return
        }

        return navigate(`/${item.route}`)

    }
    const closeMobileMenu = () => {
        if (isMobile) {
            setIsOpen(false)
        }
    }
    return (
        <>

            <Stack spacing={"0"}
                   className={`w-16 h-full transition-all bg-white dark:bg-neutral-700 ${isOpen && `!w-full md:!w-60 absolute z-50 left-0 top-0 md:!relative`}`}>
                <Flex className={`justify-end w-full p-2 gap-3 bg-gray-300 dark:bg-neutral-900`}>
                    {isOpen ? (
                        <>
                            <Button className={`!bg-neutral-800 dark:!bg-red-500 font-bold !text-white flex-1`}
                                    onClick={() => {
                                        toggleTheme()
                                    }}
                            >
                                <CgDarkMode/>
                            </Button>
                            <Button className={`!bg-neutral-800 font-bold !text-white`}
                                    onClick={() => {
                                        setIsOpen(false)
                                        localStorage.setItem("FindScrap@LeftNavIsOpen", "false")
                                    }}
                            >
                                <ImMenu/>
                            </Button>

                        </>
                    ) : (
                        <Button className={`!bg-red-500 font-bold !text-white flex-1`}
                                onClick={() => {
                                    setIsOpen(true)
                                    localStorage.setItem("FindScrap@LeftNavIsOpen", "true")
                                }}
                        >
                            <ImMenu/>
                        </Button>
                    )}
                </Flex>
                <Stack spacing={"1"} className={`p-3 text-neutral-800 dark:text-white overflow-y-auto flex-1`}>
                    {navigation?.map(item => {
                        return ((user()?.type === "administrator" && item.admin) || !item.admin) && (
                            <Item icon={item.icon} title={isOpen ? item.title : undefined} disabled={item?.notFound}
                                  loading={item?.isLoading}
                                  selected={isOpen ? item?.selected : false}
                                  active={location.pathname.includes(item.route === "scrap/home" ? "scrap" : item.route) && (item.remove ? !location.pathname.includes(item.remove) : true)}
                                  onClick={() => handleNavigateTo(item)}/>
                        )
                    })}
                </Stack>
            </Stack>
        </>
    )
}