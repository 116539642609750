import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control, useForm} from "react-hook-form";
import {UserBox} from "../../../components/FormBox/UserBox";
import {serialize} from 'object-to-formdata';
import React, {useEffect, useRef, useState} from "react";
import {v4 as uuid} from 'uuid';
import cloneDeep from "clone-deep"
import {
    AddressProps,
    CategoryProps,
    DepartmentProps,
    FilterProps, ManufacturerProps,
    ProductProps,
    SelectOptionProps, SubcategoryProps,
    UserProps
} from "../../../types";
import {createAddress, getAddressById} from "../../../services/address";
import {createProduct, getProductById, updateProduct} from "../../../services/products";
import {Box, Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {getProductCreateAndEditErrors, getUserCreateAndEditErrors} from "../../../helpers";
import {CancelItemBox} from "../../../components/CancelItemBox";
import {Dialog} from "../../../components/Dialog";
import {Container} from "../../../components/Container";
import {Button} from "../../../components/Button";
import {PasswordBox} from "../../../components/FormBox/PasswordBox";
import {ProductDataBox} from "./components/ProductDataBox";
import {ProductDimensionsBox} from "./components/ProductDimensionsBox";
import {ProductDefinitionsBox} from "./components/ProductDefinitionsBox";
import {ProductBox} from "./components/ProductBox";
import {getCategories as getAllCategories} from "../../../services/categories";
import {getSubcategories as getAllSubcategories} from "../../../services/subcategories";
import {getDepartments as getAllDepartments} from "../../../services/departments";
import {getManufacturers as getAllManufacturers} from "../../../services/manufacturers";
import {IoIosAddCircle} from "react-icons/io";
import {storageURL} from "../../../services/axios";

export function ProductCreateAndEdit() {
    const [isSaving, setIsSaving] = useState(false)
    const [departments, setDepartments] = useState<Partial<DepartmentProps> & SelectOptionProps[]>([])
    const [categories, setCategories] = useState<Partial<CategoryProps> & SelectOptionProps[]>([])
    const [subcategories, setSubcategories] = useState<Partial<SubcategoryProps> & SelectOptionProps[]>([])
    const [manufacturers, setManufacturers] = useState<Partial<ManufacturerProps> & SelectOptionProps[]>([])
    const [filters, setFilters] = useState<Partial<FilterProps> & SelectOptionProps[]>([])

    const {onOpen: saveDialogOnOpen, isOpen: saveDialogIsOpen, onClose: saveDialogOnClose} = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()
    const {
        control: productControl,
        watch: productWatch,
        getValues: productGetValues,
        reset: productReset,
        resetField: productResetField
    } = useForm<ProductProps>()
    const params = useParams()
    const productId = Number(params.productId)

    const getProductData = async () => {
        const response = await getProductById(productId)
        const filters: any = []
        response?.data?.product_variants?.map((variant: any) => {
            variant?.product_final?.map((final: any) => {
                final?.product_final_has_filter_and_value?.map((filter: any) => {
                    const filterAdded = filters.find((item: any) => item.value === filter?.product_department_filters?.id)
                    if (!filterAdded) {
                        filters.push({
                            ...filter?.product_department_filters,
                            value: filter?.product_department_filters?.id,
                            label: filter?.product_department_filters?.name,
                            type: filter?.id_value ? `string` : `boolean`,
                            product_department_filter_values: filter?.product_department_filters?.product_department_filter_values
                        })
                    }
                })
            })
        })

        const productVariant = response.data.product_variants.map((variant: any) => {
            const finals = variant.product_final.map((final: any) => {
                const filters: any = []
                final.price = (String(final.price).replace(".", ","))
                final?.product_final_has_filter_and_value?.filter((item: any) => !!item?.id_value).map((filter: any) => {

                    if (!filters.find((item: any) => Number(item.id_filter) === Number(filter.id_filter))) {
                        filters.push({
                            value: filter?.product_department_filter_values?.id,
                            label: `${filter?.product_department_filter_values?.value} ${filter?.product_department_filter_values?.termination ? filter?.product_department_filter_values?.termination : ``}`,
                            id_filter: filter?.id_filter,
                            id_value: filter?.id_value,
                            id: filter?.id
                        })
                    }
                })
                const booleanFilters: any = []
                final?.product_final_has_filter_and_value?.filter((item: any) => !item?.id_value).map((filter: any) => {
                    if (!booleanFilters.find((item: any) => Number(item.id_filter) === Number(filter.id_filter))) {
                        booleanFilters.push({
                            value: filter?.product_department_filters?.id,
                            label: filter?.product_department_filters?.name,
                            id_filter: filter?.id_filter,
                            id_value: filter?.id_value,
                            id: filter?.id
                        })
                    }
                })


                delete final.product_final_has_filter_and_value
                delete final.product_final_stock

                return {...final, filters, boolean_filters: booleanFilters}
            })

            const medias = variant.product_variant_media?.map((item: any) => {
                console.log({item})
                return {
                    url: `${storageURL}${item.source}`,
                    type: item?.id_type === 1 ? `image` : 'video',
                    uuid: uuid(),
                    id: item?.id
                }
            })

            delete variant.product_variant_media

            return {...variant, medias, product_final: finals, attribute_value_id: variant.attribute_value}
        })

        productReset({
            ...response.data,
            filters: filters,
            product_variant: productVariant,
            delivery_format: {
                value: response?.data?.product_delivery_formats?.id,
                label: response?.data?.product_delivery_formats?.name
            },
            manufacturer: {
                value: response?.data?.product_manufacturers?.id,
                label: response?.data?.product_manufacturers?.name
            },
            department: {
                value: response?.data?.product_departments?.id,
                label: response?.data?.product_departments?.name,
                product_department_filters: response?.data?.product_departments?.product_department_filters
            },
            category: response?.data?.product_categories ? {
                value: response?.data?.product_categories?.id,
                label: response?.data?.product_categories?.name
            } : null,
            subcategory: response?.data?.product_subcategories ? {
                value: response?.data?.product_subcategories?.id,
                label: response?.data?.product_subcategories?.name
            } : null,
            attribute: {
                value: response?.data?.product_variants[0]?.attribute?.id,
                label: response?.data?.product_variants[0]?.attribute?.name
            },
            attribute_values: response.data.product_variants.map((item: any) => {
                console.log({item})
                return {value: item.attribute_value, label: item.attribute_value}
            })
        })
    }
    const getSubcategories = async (categoryId: number) => {
        const response = await getAllSubcategories(categoryId)
        if (response.status !== 200) {
            setSubcategories([])
            if (productResetField) {
                productResetField('subcategory', {defaultValue: null})
            }
            return
        }
        setSubcategories(response.data.map((subcategory: SubcategoryProps) => {
            return {
                value: subcategory.id,
                label: subcategory.name
            }
        }))
    }


    const getCategories = async (departmentId: number) => {
        const response = await getAllCategories(departmentId)
        if (response.status !== 200) {
            setCategories([])
            if (productResetField) {
                productResetField('category', {defaultValue: null})
            }
            return
        }
        setCategories(response.data.map((category: CategoryProps) => {
            return {
                value: category.id,
                label: category.name
            }
        }))
    }

    const getDepartments = async () => {
        const response = await getAllDepartments()
        if (response.status !== 200) {
            return
        }
        setDepartments(
            response.data.map((department: DepartmentProps) => {
                return {
                    value: department.id,
                    label: department.name,
                    product_department_filters: department?.product_department_filters
                }
            })
        )
        // if (productResetField) {
        //     productResetField('department', {defaultValue: {value: response.data[0].id, label: response.data[0].name}})
        // }
    }
    const getManufacturers = async () => {
        const response = await getAllManufacturers()
        if (response.status !== 200) {
            return
        }
        setManufacturers(response.data.map((manufacturers: ManufacturerProps) => {
            return {
                value: manufacturers.id,
                label: manufacturers.name
            }
        }))
        // if (productResetField) {
        //     productResetField('department', {defaultValue: {value: response.data[0].id, label: response.data[0].name}})
        // }
    }

    useEffect(() => {
        getDepartments()
        getManufacturers()
    }, [])

    const watchCalled = useRef(false)
    useEffect(() => {
        if (productWatch && !watchCalled.current) {
            watchCalled.current = true
            productWatch((data, {type, name}) => {
                const firstCall = type === undefined && name === undefined

                if (firstCall) {
                }

                // if (data?.category?.value && !dataIsLoading.current) {
                //     getData(1)
                // }
                setFilters(data.department.product_department_filters?.map((filter: FilterProps) => {
                    return {...filter, value: filter.id, label: filter.name}
                }))

                if (name === "department") {
                    getCategories(data.department.value)

                    const formattedProductVariant = data?.product_variant?.map((variant: any) => {
                        const productFinal = variant?.product_final?.map((final: any) => {
                            return {...final, filters: [], boolean_filters: []}
                        })
                        return {...variant, product_final: productFinal}
                    })

                    productReset({
                        ...data,
                        product_variant: formattedProductVariant,
                        category: null,
                        subcategory: null,
                        filters: null
                    })
                }
                if (name === "category") {
                    getSubcategories(data.category.value)
                    productReset({
                        ...data,
                        subcategory: null
                    })
                }
                if (name === "attribute_values") {

                    if (data?.attribute_values?.length > data?.product_variant?.length || !data?.product_variant?.length) {
                        const attribute_value = data?.attribute_values[data?.attribute_values?.length - 1]
                        const formattedProductVariant = [...(data?.product_variant?.length ? data?.product_variant : []), {
                            attribute_value: attribute_value.label,
                            attribute_value_id: attribute_value.value
                        }]
                        productReset({...data, product_variant: formattedProductVariant})
                    } else {
                        const formattedProductVariant = data?.product_variant?.filter((item: any) => {
                            return data?.attribute_values?.find((attribute_value: any) => {
                                return item?.attribute_value_id === attribute_value?.value
                            })
                        })
                        productReset({...data, product_variant: formattedProductVariant})
                    }
                }
                if (name === "filters") {
                    const formattedProductVariant = data?.product_variant?.map((variant: any) => {
                        const productFinal = variant?.product_final?.map((final: any) => {
                            let formattedFilters = final?.filters?.filter((filter: any) => {
                                return data?.filters?.find((find: any) => find?.id === filter?.id_filter )
                            })
                            const newFilters = data?.filters?.filter((filter: any) => {
                                return !final?.filters?.find((find: any) => find?.id_filter === filter?.id)
                            }).filter((filter: any) => filter?.type === "string").map((filter: any) => {
                                return {
                                    id_filter: filter?.id,
                                    label: filter?.name
                                }
                            })
                            if (newFilters) {
                                formattedFilters = [...formattedFilters, ...newFilters]
                            }
                            return {...final, filters: [...formattedFilters]}
                        })
                        return {...variant, product_final: productFinal}
                    })
                    productReset({...data, product_variant: formattedProductVariant})
                }
            })
        }
    }, [])

    const handleSave = async () => {
        const productPayload = cloneDeep(productGetValues())


        productPayload.id_department = productPayload.department?.value
        productPayload.id_category = productPayload.category?.value
        productPayload.id_subcategory = productPayload.subcategory?.value
        productPayload.id_delivery_format = productPayload.delivery_format?.value
        productPayload.id_manufacturer = productPayload.manufacturer?.value

        const {product_variant, attribute} = productPayload

        delete productPayload.attribute
        delete productPayload.category
        delete productPayload.product_variant
        delete productPayload.delivery_format
        delete productPayload.department
        delete productPayload.boolean_filters
        delete productPayload.manufacturer
        delete productPayload.subcategory
        delete productPayload.category
        delete productPayload.product_department_filters
        delete productPayload.product_departments
        delete productPayload.product_variants
        delete productPayload.product_categories
        delete productPayload.product_subcategories
        delete productPayload.product_delivery_formats


        productPayload.product_variant = product_variant?.map((item: any) => {
            if (item.medias?.id) {
                item.medias = [item.medias]
            }
            // const {product_final} = item
            // delete item.product_final
            const variantPayload = {
                ...item,
                name: `${productPayload?.name} ${item.attribute_value}`,
                id_attribute: attribute?.value,
                product_final: item?.product_final?.map((final: any) => {
                    final.price = final.price.replace("$ ", "")
                    const boolean_filters = final?.boolean_filters?.map((item: any) => ({...item, value: true})) || []
                    const filters = final?.filters || []
                    delete final?.boolean_filters
                    delete final?.filters
                    return {
                        ...final,
                        name: `${productPayload?.name} ${item.attribute_value}, ${[...filters, ...boolean_filters].map((filter: any) => filter?.label).join(", ")}`,
                        filters: [...filters, ...boolean_filters]
                    }
                })
            }

            delete variantPayload.attribute
            delete variantPayload.attribute_value_id

            return variantPayload
        })

        let hasErrors = false

        const productErrors = getProductCreateAndEditErrors(productPayload, !!productId)

        const errors = [...productErrors]

        if (errors.length > 0) {
            hasErrors = true
            for (const error of errors) {
                toast({
                    status: "error",
                    title: error.title,
                    description: error.description,
                    isClosable: true
                })
            }
        }

        if (hasErrors) {
            return
        }

        delete productPayload.attribute_values
        delete productPayload.filters

        productPayload.weight_with_box = productPayload.weight
        productPayload.diameter_with_box = productPayload.diameter
        productPayload.height_with_box = productPayload.height
        productPayload.width_with_box = productPayload.width
        productPayload.length_with_box = productPayload.length

        const productFormData = serialize(productPayload, {indices: true})

        let productResponse: any
        setIsSaving(true)
        if (productId) {

            productResponse = await updateProduct(productId, productFormData)
        } else {
            productResponse = await createProduct(productFormData)
        }
        if (productResponse.status !== 201 && productResponse.status !== 200) {
            setIsSaving(false)
            return toast({
                status: "error",
                title: "Erro ao salvar produto",
                description: productResponse.data.message || "Houve um erro salvar o produto, por favor tente novamente",
                isClosable: true
            })
        }
        setIsSaving(false)

        navigate('/products')
        return toast({
            status: "success",
            title: "Produto salvo com sucesso",
            description: productResponse.data.message || "O produto foi salvo com sucesso",
            isClosable: true
        })

    }
    const handleCancel = () => {
        return navigate("/products")
    }
    useEffect(() => {

        if (productId) {
            getProductData()
        }
    }, [])
    const handleAddNewProduct = (index: number) => {
        const currentPayload = productGetValues()
        productResetField(`product_variant.${index}`, {
            defaultValue: {
                ...currentPayload.product_variant[index],
                product_final: [...currentPayload.product_variant[index].product_final, {}]
            }
        })
    }

    const handleRemoveProduct = (variantIndex: number, index: number) => {
        const currentPayload = productGetValues()

        if (currentPayload.product_variant[variantIndex]?.product_final?.length === 1) {
            return toast({
                status: "error",
                title: "Número de versões",
                description: "Em cada variação de produto é necessário ter pelo menos uma opção",
                isClosable: true
            })
        }
        productResetField(`product_variant.${variantIndex}`, {
            defaultValue: {
                ...currentPayload.product_variant[variantIndex],
                product_final: currentPayload.product_variant[variantIndex].product_final?.filter((item: FilterProps, key: number) => key !== index)
            }
        })
    }

    return (
        <>
            <Dialog
                onAccept={handleSave}
                isOpen={saveDialogIsOpen}
                onClose={saveDialogOnClose}
                title={"Salvar produto"}
                description={"Ao confirmar, o produto será salvo"}
            />
            <CancelItemBox title={"Voltar para lista de produtos"} onClick={() => navigate('/products')}/>
            <Accordion title={"Dados do produto"} defaultExpanded={true}>
                <ProductDataBox departments={departments} categories={categories} subcategories={subcategories}
                                manufacturers={manufacturers}
                                control={productControl as Control<any>} isEditing={!!productId}/>
            </Accordion>
            <Accordion title={"Dimensões do produto"} defaultExpanded={false}>
                <ProductDimensionsBox control={productControl as Control<any>} isEditing={!!productId}/>
            </Accordion>
            <Accordion title={"Definições do produto"} defaultExpanded={false}>
                <ProductDefinitionsBox filters={filters} control={productControl as Control<any>}
                                       isEditing={!!productId}/>
            </Accordion>
            {productWatch()?.product_variant?.map((productVariant: any, key: number) => {
                return (
                    <Accordion key={key} panelClassName={`gap-5 grid`}
                               title={`${productWatch()?.name ? productWatch()?.name : ''} ${productVariant?.attribute_value}`}
                               icon={<IoIosAddCircle className={`fill-red-500`} onClick={(e) => {
                                   e.stopPropagation()
                                   handleAddNewProduct(key)
                               }}/>} defaultExpanded={false}>
                        <ProductBox attributeValue={productVariant.attribute_value}
                                    onRemoveProduct={handleRemoveProduct}
                                    values={productWatch()}
                                    length={productWatch()?.product_variant ? (productVariant?.product_final?.length >= key + 1 ? productVariant?.product_final?.length : 1) : 1}
                                    index={key}
                                    filters={productWatch()?.filters}
                                    control={productControl as Control<any>}
                                    isEditing={!!productId}/>
                    </Accordion>
                )
            })}
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => handleCancel()}>Voltar</Button>
                    <Button className={`!bg-red-500 !text-white`}
                            onClick={() => saveDialogOnOpen()}> Salvar {isSaving &&
                    <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                </Flex>
            </Container>
        </>
    )
}