import {SimpleGrid} from "@chakra-ui/react";
import {Input} from "../../../../../components/Input";
import React from "react";
import { Control } from "react-hook-form";
import {MediaInput} from "../../../../../components/MediaInput";
import {Textarea} from "../../../../../components/Textarea";
import {CreatableSelect} from "../../../../../components/CreatableSelect";

type CreateAndEditBoxProps = {
    control: Control<any>
    isEditing?: boolean
    isLoading?: boolean
    onSelectInvalidType?: () => void
}

export function CreateAndEditBox({control, isEditing, onSelectInvalidType, isLoading}: CreateAndEditBoxProps) {
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Input control={control} name={'name'} rules={{required: true}} labelClassName={"flex-1"}
                   label={"Nome *"} placeholder={"Digite o nome *"}
            />
            <Input control={control} name={'url'} labelClassName={"flex-1"}
                   label={"URL"} placeholder={"URL de redirecionamento"}
            />
            <Input control={control} type={"date"} name={'start_date'} labelClassName={"flex-1"}
                   label={"Começa em"} placeholder={"Disponível em"}
            />
            <Input control={control} type={"date"} name={'end_date'} labelClassName={"flex-1"}
                   label={"Acaba em"} placeholder={"Expira em"}
            />
            <CreatableSelect control={control} isMulti={true} name={'slugs'} rules={{required: true}} labelClassName={"flex-1 col-span-2"}
                             label={"Slugs *"} placeholder={"Slugs *"}
            />
            <MediaInput multiple={false} control={control} name={'media'} rules={{required: true}} labelClassName={"flex-1 col-span-2"}
                       label={"Mídia *"} placeholder={"Mídia *"}
            />
        </SimpleGrid>
    )
}