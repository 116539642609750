import {Refunds as RefundsTemplate} from "../../templates/Refunds"
import {RefundDetails as RefundDetailsTemplate} from "../../templates/Refunds/Details";

export function Refunds() {
    if (window.location.pathname.includes("/refunds/") && window.location.pathname !== "/refunds") {
        return <RefundDetailsTemplate />
    }
    return (
        <RefundsTemplate />
    )
}