import {TemplateProps} from "../../types/template";
import {Template} from "./template";

export function DefaultTemplate({children}: TemplateProps) {
    return (
        <div className={"w-full h-screen flex flex-row"}>
            <Template>
                {children}
            </Template>
        </div>
    )
}