import {ColumnProps} from "../../types/table";
import {Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import {ImMenu} from "react-icons/im"
import React from "react";
import {currencyFormat} from "../../helpers";
import {DropdownMenu} from "../../components/DropdownMenu";

type TableColumnsProps = {
    onCancel: (invoiceId: number) => void
    onEmitAgain: (invoiceId: number) => void
    tabIndex: number
}
export const tableColumns = ({tabIndex, onCancel, onEmitAgain}: TableColumnsProps): ColumnProps[] => {

    return [
        ...(tabIndex !== 1 ? [{
            header: "AÇÕES",
            cell: (info: any) => {
                const options = []

                if (info.row.original.id_status === 3) {
                    options.push({
                        label: "Cancelar nota",
                        onClick: () => onCancel(info.row.original.id)
                    })
                }
                if (info.row.original.id_status !== 3 && info.row.original.id_status !== 6) {
                    options.push({
                        label: "Emitir novamente",
                        onClick: () => onEmitAgain(info.row.original.id)
                    })
                }
                if (options.length === 0) {
                    return <></>
                }
                return (
                    <Flex gap={2}>
                        <DropdownMenu options={options}/>
                    </Flex>
                )
            }
        }] : []),
        {
            id: "id",
            header: "ID"
        },
        {
            id: "stripe_id",
            header: "STRIPE"
        },
        {
            id: "stripe_pdf",
            header: "STRIPE PDF"
        },
        ...(tabIndex === 1 ? [{
            id: "stripe_credit_note_pdf",
            header: "STRIPE CREDIT NOTE PDF"
        }] : []),
        {
            id: "id_order",
            header: "PEDIDO"
        },
        {
            id: "id_client",
            header: "ID CLIENTE"
        },
        {
            id: "user_name",
            header: "CLIENTE"
        },
        {
            id: "total_price",
            header: "VALOR",
            cell: info => currencyFormat(Number(info.row.original.total_price))
        },
        {
            id: "invoice_date",
            header: "DATA DA NOTA"
        },
        {
            id: "created_at",
            header: "CRIADO EM"
        },
        {
            id: "updated_at",
            header: "ATUALIZADO EM"
        }
    ]
}