import {api} from "./axios";
import {UserProps} from "../types";

export const getSubcategories = async (categoryId: number, params?: any) => {
    return await api.get(`/product/department/category/${categoryId}/subcategory`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getSubcategoryById = async (subcategoryId?: number) => {
    return await api.get(`/product/department/category/subcategory/${subcategoryId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateSubcategory = async (subcategoryId: number, payload?: any) => {
    return await api.put(`/product/department/category/subcategory/${subcategoryId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createSubcategory = async (categoryId: number, payload?: any) => {
    return await api.post(`/product/department/category/${categoryId}/subcategory`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteSubcategory = async (subcategoryId: number) => {
    return await api.delete(`/product/department/category/subcategory/${subcategoryId}`, {
    }).catch(({response}) => {
        return response
    })
}