import {
    Tabs as ChakraUiTabs, TabsProps as ChakraUiTabsProps,
    TabList,
    TabPanels,
    Tab as ChakraUiTab, TabProps as ChakraUiTabProps,
    TabPanel as ChakraUiTabPanel, TabPanelProps as ChakraUiTabPanelProps, Box
} from '@chakra-ui/react'
import {ReactNode} from "react";

type TabsProps = Partial<ChakraUiTabsProps> & {
    tabs: ReactNode[]
    panels: ReactNode[]
}

export function Tab({children, ...rest}: ChakraUiTabProps) {
    return (
        <ChakraUiTab {...rest}>
            {children}
        </ChakraUiTab>
    )
}

export function TabPanel({children, ...rest}: ChakraUiTabPanelProps) {
    return (
        <ChakraUiTabPanel {...rest}>
            {children}
        </ChakraUiTabPanel>
    )
}

export function Tabs({tabs, panels, ...rest}: TabsProps) {
    const generatePanels = () => {
        const renderPanels: JSX.Element[] = []

        if (panels.length === 1) {
            tabs.map((tab) => {
                renderPanels.push(
                    <TabPanel className={`!py-2 !px-0`}>
                        {panels[0]}
                    </TabPanel>
                )
            })
        } else {
            panels.map((panel) => {
                renderPanels.push(
                    <TabPanel className={`!py-2 !px-0`}>
                        {panel}
                    </TabPanel>
                )
            })
        }

        return renderPanels
    }
    return (
        <ChakraUiTabs {...rest} >
            <TabList className={`!border-red-500 dark:!border-white !border-b`} overflowX={'auto'} overflowY={'hidden'}>
                {tabs.map((tab) => {
                    return (
                        <Tab
                            _selected={{
                                backgroundColor: 'rgb(239 68 68)',
                                color: 'white'
                            }}
                            _active={{
                                backgroundColor: 'rgb(239 68 68)',
                                color: 'white'
                            }}
                            _focusVisible={{
                                border: '2px solid rgba(239, 68, 68, 1)'
                            }}
                            className={`min-w-max !border-b-2 border-2 border-[#40559F] !px-2 !py-1 `}
                        >
                            {tab}
                        </Tab>
                    )
                })}
            </TabList>
            <TabPanels>
                {generatePanels()}
            </TabPanels>
        </ChakraUiTabs>
    )
}