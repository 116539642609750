import {
    BoxProps as ChakraUiBoxProps,
    Box as ChakraUiBox
} from "@chakra-ui/react";
import {CSSProperties} from "react";

type ContainerProps = ChakraUiBoxProps & {
}


export function Container({children, ...rest}: ContainerProps) {
    return (
        <ChakraUiBox {...rest} className={`bg-white dark:bg-neutral-700 p-4 ${rest?.className}`}>
            {children}
        </ChakraUiBox>
    )
}