import {api} from "./axios";
import {UserProps} from "../types";

export const getHighlights = async (params?: any) => {
    return await api.get(`/product/highlight`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getHighlightById = async (highlightId?: number) => {
    return await api.get(`/product/highlight/${highlightId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateHighlight = async (highlightId: number, payload?: any) => {
    return await api.put(`/product/highlight/${highlightId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createHighlight = async (payload?: any) => {
    return await api.post(`/product/highlight`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteHighlight = async (highlightId: number) => {
    return await api.delete(`/product/highlight`, {
        params: {products: [highlightId]}
    }).catch(({response}) => {
        return response
    })
}