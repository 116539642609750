import {api} from "./axios";
import {UserProps} from "../types";

export const getDepartments = async (params?: any) => {
    return await api.get(`/product/department`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getDepartmentById = async (departmentId?: number) => {
    return await api.get(`/product/department/${departmentId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateDepartment = async (departmentId: number, payload?: any) => {
    return await api.put(`/product/department/${departmentId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createDepartment = async (payload?: any) => {
    return await api.post(`/product/department`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteDepartment = async (departmentId: number) => {
    return await api.delete(`/product/department/${departmentId}`, {
    }).catch(({response}) => {
        return response
    })
}