import {ColumnProps} from "../../types/table";
import {Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import React from "react";
import {AdSlugProps} from "../../types";
import {DropdownMenu} from "../../components/DropdownMenu";
type TableColumnsProps = {
    onDelete: (administratorId: number) => void
    onEdit: (administratorId: number) => void
}
export const tableColumns = ({onDelete, onEdit}: TableColumnsProps): ColumnProps[] => {
    return [
        {
            header: "AÇÕES",
            cell: (info) => {
                return (
                    <Flex gap={2}>
                        <DropdownMenu options={[
                            {
                                label: "Editar",
                                onClick: () => onEdit(info.row.original.id)
                            },
                            {
                                label: "Excluir",
                                onClick: () => onDelete(info.row.original.id)
                            }
                        ]}/>
                    </Flex>
                )
            }
        },
        {
            id: "id",
            header: "ID"
        },
        {
            id: "name",
            header: "NOME"
        },
        {
            id: "slug",
            header: "SLUGS",
            cell: info => info.row.original.slugs?.map((item: AdSlugProps) => item.slug).join(', ')

        },
        {
            id: "url",
            header: "URL"
        },
        {
            id: "media_url",
            header: "MÍDIA"
        },
        {
            id: "start_date",
            header: "COMEÇA EM"
        },
        {
            id: "end_date",
            header: "ACABA EM"
        },
        {
            id: "created_at",
            header: "CRIADO EM"
        },
        {
            id: "updated_at",
            header: "ATUALIZADO EM"
        }
    ]
}