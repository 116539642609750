import {Box, BoxProps, Flex} from "@chakra-ui/react";
import {Text} from "../Text";
import React from "react";
import {AiFillCloseCircle} from "react-icons/ai";
import {Container} from "../Container";

type CancelItemBox = BoxProps & {
    title?: string
}

export function CancelItemBox({title = "Cancelar", ...rest}: CancelItemBox) {
    return (
        <>
            <Container {...rest}
                  className={`flex cursor-pointer justify-between items-center ${rest?.className}`}
                  >
                <Text className={"font-medium"}>
                    {title}
                </Text>
                <AiFillCloseCircle className={`fill-neutral-800`}/>
            </Container>
        </>
    )
}