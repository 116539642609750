import {ColumnProps} from "../../types/table";
import {Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import React from "react";
type TableColumnsProps = {
    onDelete: (administratorId: number) => void
    onEdit: (administratorId: number) => void
}
export const tableColumns = ({onDelete, onEdit}: TableColumnsProps): ColumnProps[] => {
    return [
        {
            header: "AÇÕES",
            cell: (info) => {
                return (
                    <Flex gap={2}>
                        <AiFillEdit className={`cursor-pointer`} onClick={() => onEdit(info.row.original.id)} />
                        <AiFillDelete className={`cursor-pointer fill-red-500`} onClick={() => onDelete(info.row.original.id)} />
                    </Flex>
                )
            }
        },
        {
            id: "id",
            header: "ID"
        },
        {
            id: "first_name",
            header: "NOME",
            cell: (info) => {
                return `${info.renderValue()} ${info.row.original.last_name}`
            }
        },
        {
            id: "email",
            header: "E-MAIL"
        },
        {
            id: "phone",
            header: "TELEFONE"
        },
        {
            id: "document",
            header: "CPF"
        },
        {
            id: "id_status",
            header: "STATUS"
        },
        {
            id: "created_at",
            header: "CRIADO EM"
        },
        {
            id: "updated_at",
            header: "ATUALIZADO EM"
        }
    ]
}