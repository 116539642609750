import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control, useForm} from "react-hook-form";
import {UserBox} from "../../../components/FormBox/UserBox";
import React, {useEffect, useState} from "react";
import {AddressProps, CategoryProps, DepartmentProps, SelectOptionProps, UserProps} from "../../../types";
import {createAddress, getAddressById} from "../../../services/address";
import {createCategory, getCategoryById, updateCategory} from "../../../services/categories";
import {Box, Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {
    getAddressCreateAndEditErrors,
    getCategoryCreateAndEditErrors,
    getUserCreateAndEditErrors
} from "../../../helpers";
import {CancelItemBox} from "../../../components/CancelItemBox";
import {Dialog} from "../../../components/Dialog";
import {Container} from "../../../components/Container";
import {Button} from "../../../components/Button";
import {PasswordBox} from "../../../components/FormBox/PasswordBox";
import {CreateAndEditBox} from "./components/CreateAndEditBox";
import {NewItemBox} from "../../../components/NewItemBox";
import {getDepartments as getAllDepartments} from "../../../services/departments";

export function CategoryCreateAndEdit() {
    const [isSaving, setIsSaving] = useState(false)
    const [departments, setDepartments] = useState<SelectOptionProps[]>([])
    const {onOpen: saveDialogOnOpen, isOpen: saveDialogIsOpen, onClose: saveDialogOnClose} = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()
    const {
        control: categoryControl,
        watch: categoryWatch,
        getValues: categoryGetValues,
        reset: categoryReset,
        resetField: categoryResetField
    } = useForm<Partial<CategoryProps>>()
    const params = useParams()
    const categoryId = Number(params.categoryId)

    const getCategoryData = async () => {
        const response = await getCategoryById(categoryId)
        categoryReset({
            ...response.data,
            department: departments.find(item => item.value === response.data.id_department)
        })
    }


    const handleSave = async () => {
        const categoryPayload = categoryGetValues()

        let hasErrors = false

        const categoryErrors = getCategoryCreateAndEditErrors(categoryPayload)

        const errors = [...categoryErrors]

        if (errors.length > 0) {
            hasErrors = true
            for (const error of errors) {
                toast({
                    status: "error",
                    title: error.title,
                    description: error.description,
                    isClosable: true
                })
            }
        }

        if (hasErrors) {
            return
        }

        const {department} = categoryPayload
        delete categoryPayload.department
        delete categoryPayload.filters_hidden

        let categoryResponse: any
        setIsSaving(true)
        if (categoryId) {
            if (department?.value) {
                categoryPayload.id_department = department.value
            }
            categoryResponse = await updateCategory(categoryId, {...categoryPayload})
        } else {
            categoryResponse = await createCategory(department?.value, {...categoryPayload})
        }
        if (categoryResponse.status !== 201 && categoryResponse.status !== 200) {
            setIsSaving(false)
            return toast({
                status: "error",
                title: "Erro ao salvar categoria",
                description: categoryResponse.data.message || "Houve um erro salvar a categoria, por favor tente novamente",
                isClosable: true
            })
        }
        setIsSaving(false)

        navigate('/categories')
        return toast({
            status: "success",
            title: "Departamento salvo com sucesso",
            description: categoryResponse.data.message || "A categoria foi salvo com sucesso",
            isClosable: true
        })

    }
    const handleCancel = () => {
        return navigate("/categories")
    }
    const getDepartments = async () => {
        const response = await getAllDepartments()
        if (response.status !== 200) {
            return
        }
        await setDepartments(response.data.map((department: DepartmentProps) => {
            return {
                value: department.id,
                label: department.name
            }
        }))
    }

    const getInitialData = async () => {
        if (departments.length < 1) {
            await getDepartments()
        }
        if (categoryId && departments.length > 0) {
            await getCategoryData()
        }
    }

    useEffect(() => {
        getInitialData()
    }, [departments])

    return (
        <>
            <Dialog
                onAccept={handleSave}
                isOpen={saveDialogIsOpen}
                onClose={saveDialogOnClose}
                title={"Salvar categoria"}
                description={"Ao confirmar, a categoria será salvo"}
            />
            <CancelItemBox title={"Voltar para lista de categorias"} onClick={() => navigate('/categories')}/>
            <Accordion title={"Dados da categoria"} defaultExpanded={true}>
                <CreateAndEditBox departments={departments} control={categoryControl as Control<any>}
                                  isEditing={!!categoryId}/>
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => handleCancel()}>Voltar</Button>
                    <Button className={`!bg-red-500 !text-white`}
                            onClick={() => saveDialogOnOpen()}> Salvar {isSaving &&
                    <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                </Flex>
            </Container>
        </>
    )
}