import {TextLogo} from "../../../components/TextLogo";
import {Input} from "../../../components/Input";
import {Button} from "../../../components/Button";
import {Link} from "../../../components/Link";
import {WizardStepProps} from "../../../types/wizard";

export function EmailConfirm({...props}: Partial<WizardStepProps>) {
    return (
        <div className={"w-full flex flex-col items-center justify-center gap-5"}>
            <TextLogo className={"w-full"} />
            <Input label={"Seu usuário"} className={"w-full"} labelClassName={"w-full"} />
            <Button onClick={() => props.goToStep && props.goToStep(3)} className={`text-neutral-800`}>Recuperar senha</Button>
            <Link onClick={() => props.goToStep && props.goToStep(1)}>Voltar para o login</Link>
        </div>
    )
}