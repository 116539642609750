import {api} from "./axios";
import {UserProps} from "../types";

export const getPromotions = async (params?: any) => {
    return await api.get(`/promotion`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getPromotionById = async (promotionId?: number) => {
    return await api.get(`/promotion/${promotionId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updatePromotion = async (promotionId: number, payload?: any) => {
    return await api.put(`/promotion/${promotionId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createPromotion = async (payload?: any) => {
    return await api.post(`/promotion`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deletePromotion = async (promotionId: number) => {
    return await api.delete(`/promotion/${promotionId}`, {
    }).catch(({response}) => {
        return response
    })
}