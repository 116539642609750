import {
    Input as ChakraUiInput,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    Text
} from '@chakra-ui/react'
import { v4 as uuid } from 'uuid';
import {Control, useController, UseControllerProps, useForm} from "react-hook-form";
import React, {ReactNode, useEffect, useRef, useState} from "react";
import Cleave from "cleave.js";
import CleaveReact from "cleave.js/react"
import {CleaveOptions} from "cleave.js/options";
type InputMaskProps = any & {
    options: CleaveOptions
    label?: string
    labelClassName?: string
    mask?: 'cpf' | 'zip_code'
    name?: string
    leftIcon?: ReactNode
    rightIcon?: ReactNode
}
export function InputMask({label, labelClassName, rightIcon, name = "", leftIcon, options, mask,  ...input}: InputMaskProps) {

    if (mask === 'cpf') {
        options = {
            blocks: [3, 3, 3, 2],
            delimiters: ['.', '.', '-']
        }
    }
    if (mask === 'currency') {
        options = {
            numeral: true,
            numeralDecimalMark: ",",
            delimiter: "",
            prefix: "$ "
        }
    }
    if (mask === 'float') {
        options = {
            numeral: true,
            delimiter: "",
            numeralDecimalMark: "."

        }
    }

    if (mask === 'zip_code') {
        options = {
            blocks: [5, 4]
        }
    }
    const {control: controlDefault} = useForm()

    const [uniqueId, setUniqueId] = useState<string|undefined>(undefined)
    const {fieldState, field} = useController({
        control: input.control || controlDefault,
        rules: input.rules,
        name: name,
        defaultValue: input.defaultValue
    })

    useEffect(() => {
        setUniqueId(`cleave-${uuid()}`)
    }, [])

    useEffect(() => {
        if (uniqueId) {
            new Cleave(`.${uniqueId}`, {...options, onValueChanged: (e) => {
                    if (options.prefix) {
                        e.target.rawValue = e.target.rawValue.replace(options.prefix, "")
                    }
                    if (input?.onChange) {
                        input?.onChange(e)
                    }
                    field?.onChange(e)
                }})
        }
    }, [uniqueId])


    return (
        <>
            {/*<CleaveReact options={{*/}
            {/*    blocks*/}
            {/*}} />*/}
            <label className={`flex flex-col ${labelClassName ? labelClassName : ``}`}>
                {label && (
                    <Text className={"mb-2 line-clamp-1"}>{label}</Text>
                )}
                <InputGroup>
                    {leftIcon && (
                        <InputLeftAddon className={`!bg-green-500 border !border-green-500 cursor-pointer`}>{leftIcon}</InputLeftAddon>
                    )}
                <ChakraUiInput
                    {...input}
                    {...field}
                    type={input.type}
                    className={`${uniqueId} !border-gray-300 p-1 ${!!input.className && input.className} placeholder:text-black dark:placeholder:text-white ${fieldState.error && `!border-red-500`}`}
                    onChange={(e) => {
                        console.log({e})
                        // console.log({selection: window?.getSelection()?.toString()})
                        // if (e.target.value && e.target.value !== "") {
                        //     if (leftIcon === "BRL") {
                        //         e.target.value = leftIconAsBRL(e.target.value)
                        //     }
                        // }

                        // if (input.onChange) {
                        //     input.onChange(e)
                        // }
                        // field.onChange(e)
                    }}
                    {...(fieldState?.error ? {isInvalid: true} : {})}
                />
                    {rightIcon && (
                        <InputRightAddon className={`!bg-green-500 border !border-green-500 cursor-pointer`}>{rightIcon}</InputRightAddon>
                    )}
                </InputGroup>
            </label>
        </>
    )
}