import {Accordion} from "../../components/Accordion";
import {PaginationInfoProps, UserProps} from "../../types";
import { Table } from "../../components/Table";
import {tableColumns} from "./tableColumns"
import {cancelInvoice, deleteInvoice, finalizeInvoice, getInvoices} from "../../services/invoices";
import React, {useEffect, useState} from "react";
import {Control, FieldValues, useForm} from "react-hook-form";
import {SearchBox} from "./searchBox";
import {NewItemBox} from "../../components/NewItemBox";
import {useNavigate} from "react-router-dom";
import {Flex, useDisclosure, useToast} from "@chakra-ui/react";
import {Dialog} from "../../components/Dialog";
import {debounce} from "debounce";
import {Tab, TabPanel, Tabs} from "../../components/Tabs";

export function Invoices() {
    const [tabIndex, setTabIndex] = useState(0)
    const {onOpen: cancelDialogOnOpen, isOpen: cancelDialogIsOpen, onClose: cancelDialogOnClose} = useDisclosure()
    const [cancelId, setCancelId] = useState(0)
    const toast = useToast()
    const navigate = useNavigate()
    const {
        control,
        watch
    } = useForm()
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfoProps>({
        per_page: 10,
        page: 1,
        last_page: 0,
        total: 0,
        to: 0
    })

    const [data, setData] = useState<any[]>([] as any[])

    useEffect(() => {
    }, [data])

    const getData = debounce(async (page?: number) => {
        let statusId = 3
        switch (tabIndex) {
            case 0 :
                statusId = 3
                break
            case 1 :
                statusId = 6
                break
            case 2 :
                statusId = 1
                break
        }
        const response = await getInvoices({
            per_page: paginationInfo.per_page,
            page: page ? page : paginationInfo.page,
            id_status: statusId,
            ...watch()
        })
        if (response.isAxiosError) {
            return
        }
        setData(response.data.invoices.map((invoice: any) => {
            invoice.created_at = new Date(invoice.created_at).toLocaleString('pt-br')
            invoice.invoice_date = new Date(invoice.invoice_date).toLocaleString('pt-br')
            if (invoice.updated_at) {
                invoice.updated_at = new Date(invoice.updated_at).toLocaleString('pt-br')
            }
            return invoice
        }))
        setPaginationInfo(oldValue => {
            return {
                ...oldValue,
                last_page: response.data.total_pages,
                total: response.data.total_pages,
                to: response.data.total_count,
                page: response.data.page
            }
        })
    }, 750)
    const onPageChange = (page: number) => {
        getData(page + 1)
    }

    // useEffect(() => {
    //     getData()
    // }, [])

    useEffect(() => {
        getData()
    }, [tabIndex])

    useEffect(() => {
        watch((data, {name}) => {
            getData(1)
        })
    }, [])
    const handleEdit = (invoiceId: number) => {
        return navigate(`/invoices/${invoiceId}`)
    }
    const handleDelete = async () => {
        const response = await deleteInvoice(cancelId)
        if (response?.status !== 204) {
            return toast({
                status: "error",
                title: "Erro ao deletar invoice",
                description: response.data.message || "Houve um erro deletar o invoice, por favor tente novamente",
                isClosable: true
            })
        }
        getData()
        return toast({
            status: "success",
            title: "Invoice deletado com sucesso",
            description: response.data.message || "O invoice foi deletado com sucesso",
            isClosable: true
        })
    }
    const onCancel = async (invoiceId: number) => {
        const response = await cancelInvoice(invoiceId)
        if (response?.status !== 201) {
            return toast({
                status: "error",
                title: "Erro ao cancelar invoice",
                description: response.data.message || "Houve um erro cancelar a invoice, por favor tente novamente",
                isClosable: true
            })
        }
        getData()
        return toast({
            status: "success",
            title: "Invoice cancelada com sucesso",
            description: response.data.message || "A invoice foi cancelada com sucesso",
            isClosable: true
        })
    }
    const onEmitAgain = async (invoiceId: number) => {
        const response = await finalizeInvoice(invoiceId)
        if (response?.status !== 201) {
            return toast({
                status: "error",
                title: "Erro ao finalizar invoice",
                description: response.data.message || "Houve um erro finalizar a invoice, por favor tente novamente",
                isClosable: true
            })
        }
        getData()
        return toast({
            status: "success",
            title: "Invoice finalizada com sucesso",
            description: response.data.message || "A invoice foi finalizada com sucesso",
            isClosable: true
        })
    }
    return (
        <>
            {/*<NewItemBox title={"Novo invoice"} className={""} onClick={() => navigate(`/invoices/create`)} />*/}
            <Accordion title={"Digite sua busca"} defaultExpanded={false} className={""}>
                <SearchBox control={control} />
            </Accordion>
            <Accordion title={"Invoices"} panelClassName={`overflow-auto max-h-screen`}>
                <Tabs
                    onChange={(index) => setTabIndex(index)}
                    className={`flex-1`}
                    tabs={
                        [
                            <Flex gap={2} className={`items-center text-sm`}>Emitidas</Flex>,
                            <Flex gap={2} className={`items-center text-sm`}>Canceladas</Flex>,
                            <Flex gap={2} className={`items-center text-sm`}>Com problema</Flex>,
                        ]
                    }
                    panels={
                        [
                            <Table data={data} columns={tableColumns({
                                onCancel: onCancel,
                                onEmitAgain: onEmitAgain,
                                tabIndex
                            })} onPageChange={onPageChange} pageCount={paginationInfo.last_page} />
                        ]
                    }
                />
            </Accordion>
        </>
    )
}