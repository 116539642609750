import {UserProps} from "../types";

export function useUser () {
    const user = (): UserProps | null => {
        try {
            const userData = JSON.parse(localStorage?.getItem("Solution4Import@UserData")!) as UserProps
            if (!userData || !userData.id) {
                return null
            }
            return userData
        } catch (e) {
            return null
        }
    }

    const storeUser = (user: UserProps): boolean => {
        try {
            localStorage?.setItem("Solution4Import@UserData", JSON.stringify(user))
            return true
        } catch (e) {
            return false
        }
    }

    return {
        user,
        storeUser
    }
}