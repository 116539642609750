import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control, useForm} from "react-hook-form";
import {UserBox} from "../../../components/FormBox/UserBox";
import React, {useEffect, useState} from "react";
import {AddressProps, FilterProps, DepartmentProps, SelectOptionProps, UserProps} from "../../../types";
import {createAddress, getAddressById} from "../../../services/address";
import {createFilter, getFilterById, updateFilter} from "../../../services/filters";
import {Box, Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {
    getAddressCreateAndEditErrors,
    getFilterCreateAndEditErrors, getFilterValueAddErrors, getPromotionProductAddErrors,
    getUserCreateAndEditErrors
} from "../../../helpers";
import {CancelItemBox} from "../../../components/CancelItemBox";
import {Dialog} from "../../../components/Dialog";
import {Container} from "../../../components/Container";
import {Button} from "../../../components/Button";
import {PasswordBox} from "../../../components/FormBox/PasswordBox";
import {CreateAndEditBox} from "./components/CreateAndEditBox";
import {NewItemBox} from "../../../components/NewItemBox";
import {getDepartments as getAllDepartments} from "../../../services/departments";
import {ValuesBox} from "./components/ValuesBox";

export function FilterCreateAndEdit() {
    const [isSaving, setIsSaving] = useState(false)
    const [departments, setDepartments] = useState<SelectOptionProps[]>([])
    const {onOpen: saveDialogOnOpen, isOpen: saveDialogIsOpen, onClose: saveDialogOnClose} = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()
    const {
        control: filterControl,
        watch: filterWatch,
        getValues: filterGetValues,
        reset: filterReset,
        resetField: filterResetField
    } = useForm<FilterProps>()
    const params = useParams()
    const filterId = Number(params.filterId)

    const getFilterData = async () => {
        const response = await getFilterById(filterId)
        filterReset({
            ...response.data,
            department: departments?.find(item => item.value === response.data.id_department),
            type: {value: response.data.type, label: response.data.type === "string" ? "String" : "Boolean"},
            values: response?.data?.product_department_filter_values
        })
    }


    const handleSave = async () => {
        const filterPayload = filterGetValues()

        let hasErrors = false

        const filterErrors = getFilterCreateAndEditErrors(filterPayload)

        const errors = [...filterErrors]

        if (errors.length > 0) {
            hasErrors = true
            for (const error of errors) {
                toast({
                    status: "error",
                    title: error.title,
                    description: error.description,
                    isClosable: true
                })
            }
        }

        if (hasErrors) {
            return
        }

        const {department} = filterPayload

        delete filterPayload.department
        delete filterPayload.value_to_add
        delete filterPayload.product_department_filter_values
        delete filterPayload.product_departments

        filterPayload.type = filterPayload?.type?.value


        let filterResponse: any
        setIsSaving(true)
        if (filterId) {
            if (department?.value) {
                filterPayload.id_department = department.value
            }
            filterResponse = await updateFilter(filterId, {...filterPayload})
        } else {
            filterResponse = await createFilter(department?.value, {...filterPayload})
        }
        if (filterResponse.status !== 201 && filterResponse.status !== 200) {
            setIsSaving(false)
            return toast({
                status: "error",
                title: "Erro ao salvar filtro",
                description: filterResponse.data.message || "Houve um erro salvar o filtro, por favor tente novamente",
                isClosable: true
            })
        }
        setIsSaving(false)

        navigate('/filters')
        return toast({
            status: "success",
            title: "Filtro salvo com sucesso",
            description: filterResponse.data.message || "O filtro foi salvo com sucesso",
            isClosable: true
        })

    }
    const handleCancel = () => {
        return navigate("/filters")
    }
    const getDepartments = async () => {
        const response = await getAllDepartments()
        if (response.status !== 200) {
            return
        }
        await setDepartments(response.data.map((department: DepartmentProps) => {
            return {
                value: department.id,
                label: department.name
            }
        }))
    }

    const getInitialData = async () => {
        if (departments.length < 1) {
            await getDepartments()
        }
        if (filterId && departments.length > 0) {
            await getFilterData()
        }
    }

    useEffect(() => {
        getInitialData()
    }, [departments])

    const handleRemoveValue = (value: string) => {
        const data = filterGetValues()

        const values = data?.values?.filter((filtered: any) => filtered?.value !== value)

        filterReset({
            ...data,
            values: [...values],
            value_to_add: ""
        })
        console.log({data})
    }
    const handleAddValue = () => {
        const data = filterGetValues()

        let hasErrors = false
        const errors = getFilterValueAddErrors(data)

        if (errors.length > 0) {
            hasErrors = true
            for (const error of errors) {
                toast({
                    status: "error",
                    title: error.title,
                    description: error.description,
                    isClosable: true
                })
            }
        }

        if (hasErrors) {
            return
        }
        const values = data?.values || []
        const valueExists = data?.values?.find((value: any) => value?.value === data?.value_to_add)

        if (valueExists) {
            return toast({
                status: "error",
                title: "Opção já cadastrada",
                description: "A Opção já se encontra cadastrada na lista, salve para persistir",
                isClosable: true
            })
        }

        filterReset({
            ...data,
            values: [...values, {value: data.value_to_add}],
            value_to_add: ""
        })
        console.log({data})
    }

    return (
        <>
            <Dialog
                onAccept={handleSave}
                isOpen={saveDialogIsOpen}
                onClose={saveDialogOnClose}
                title={"Salvar filtro"}
                description={"Ao confirmar, o filtro será salvo"}
            />
            <CancelItemBox title={"Voltar para lista de filtros"} onClick={() => navigate('/filters')}/>
            <Accordion title={"Dados do filtro"} defaultExpanded={true}>
                <CreateAndEditBox departments={departments} control={filterControl as Control<any>}
                                  isEditing={!!filterId} values={filterWatch()}/>
            </Accordion>
            {filterWatch().type?.value === "string" && (
                <Accordion title={"Opções do filtro"} defaultExpanded={true}>
                    <ValuesBox values={filterWatch()} control={filterControl as Control<any>}
                               isEditing={!!filterId} onAddValue={handleAddValue} onRemoveValue={handleRemoveValue}/>
                </Accordion>
            )}
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => handleCancel()}>Voltar</Button>
                    <Button className={`!bg-red-500 !text-white`}
                            onClick={() => saveDialogOnOpen()}> Salvar {isSaving &&
                    <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                </Flex>
            </Container>
        </>
    )
}