import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent, AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay, useDisclosure, UseDisclosureProps, AlertDialogProps
} from "@chakra-ui/react";
import {Button} from "../Button";
import React, {useRef} from "react";

type DialogProps = Partial<AlertDialogProps> & {
    onAccept: () => void
    onClose: () => void
    isOpen: boolean
    title: string
    description: string
}

export function Dialog({title, description, onAccept, isOpen, onClose, ...rest}: DialogProps) {
    const cancelRef = useRef<any>(null)
    return (
        <AlertDialog
            {...rest}
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent className={`dark:bg-neutral-800`}>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {title}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {description}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button
                            className={`!bg-neutral-800 !text-white `}
                            ref={cancelRef}
                            onClick={onClose}>
                            Cancelar
                        </Button>
                        <Button
                            className={`!bg-red-500 !text-white `}
                            onClick={() => {
                                onClose()
                                onAccept()
                            }} ml={3}>
                            Confirmar
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}