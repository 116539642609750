import {api} from "./axios";
import {UserProps} from "../types";

export const getAdministrators = async (params?: any) => {
    return await api.get(`/administrator`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getAdministratorById = async (administratorId?: number) => {
    return await api.get(`/administrator/${administratorId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateAdministrator = async (administratorId: number, payload?: any) => {
    return await api.put(`/administrator/${administratorId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createAdministrator = async (payload?: any) => {
    return await api.post(`/administrator`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteAdministrator = async (administratorId: number) => {
    return await api.delete(`/administrator/${administratorId}`, {
    }).catch(({response}) => {
        return response
    })
}