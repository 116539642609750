import {api} from "./axios";
import {UserProps} from "../types";

export const getCategories = async (departmentId: number, params?: any) => {
    return await api.get(`/product/department/${departmentId}/category`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getCategoryById = async (categoryId?: number) => {
    return await api.get(`/product/department/category/${categoryId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateCategory = async (categoryId: number, payload?: any) => {
    return await api.put(`/product/department/category/${categoryId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createCategory = async (departmentId: number, payload?: any) => {
    return await api.post(`/product/department/${departmentId}/category`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteCategory = async (categoryId: number) => {
    return await api.delete(`/product/department/category/${categoryId}`, {
    }).catch(({response}) => {
        return response
    })
}