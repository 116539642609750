import {api} from "./axios";
import {LoginProps} from "../types";

export const ownerLogin = async (params?: LoginProps) => {
    return await api.get(`/owner/login`, {params}).catch(({data}) => {
        return data
    })
}
export const administratorLogin = async (payload?: LoginProps) => {
    return await api.post(`/administrator/auth/login`, payload).catch(({response, ...rest}) => {
        console.log({rest})
        return response
    })
}