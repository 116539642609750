import {ColumnProps} from "../../types/table";
import {Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import React from "react";
type TableColumnsProps = {
    onDelete: (administratorId: number) => void
    onEdit: (administratorId: number) => void
}
export const tableColumns = ({onDelete, onEdit}: TableColumnsProps): ColumnProps[] => {
    return [
        {
            header: "AÇÕES",
            cell: (info) => {
                return (
                    <Flex gap={2}>
                        <AiFillDelete className={`cursor-pointer fill-red-500`} onClick={() => onDelete(info.row.original.id)} />
                    </Flex>
                )
            }
        },
        {
            id: "id",
            header: "ID"
        },
        {
            id: "name",
            header: "NOME"
        },
        {
            id: "slug",
            header: "SLUG"
        },
        {
            id: "department",
            header: "DEPARTAMENTO",
            cell: info => info.row.original.product_variants?.products?.product_departments?.name
        },
        {
            id: "available_at",
            header: "COMEÇA EM"
        },
        {
            id: "expire_at",
            header: "EXPIRA EM"
        },
        {
            id: "stop_at",
            header: "PARADO EM"
        },
        {
            id: "created_at",
            header: "CRIADO EM"
        },
        {
            id: "updated_at",
            header: "ATUALIZADO EM"
        }
    ]
}