import {api} from "./axios";
import {UserProps} from "../types";

export const getProducts = async (params?: any) => {
    return await api.get(`/product/all`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getProductById = async (productId?: number) => {
    return await api.get(`/product/info/${productId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateProduct = async (productId: number, payload?: any) => {
    return await api.post(`/product/${productId}/update`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteProductFinal = async (finalId: number) => {
    return await api.delete(`/product/final/${finalId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const createProduct = async (payload?: any) => {
    return await api.post(`/product`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createAttribute = async (payload?: any) => {
    return await api.post(`/product/attribute`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const listAttribute = async () => {
    return await api.get(`/product/attribute`, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteProduct = async (productId: number) => {
    return await api.delete(`/product/${productId}`, {
    }).catch(({response}) => {
        return response
    })
}