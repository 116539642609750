import {ColumnProps} from "../../../../../types/table";
import {Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import React from "react";
type ListColumnsProps = {
    onRemoveProduct: (productId: number) => void
}
export const listColumns = ({onRemoveProduct}: ListColumnsProps): ColumnProps[] => {
    return [
        {
            id: "id",
            header: "ID"
        },
        {
            id: "name",
            header: "PRODUTO"
        },
        {
            header: "AÇÕES",
            cell: (info) => {
                return (
                    <Flex gap={2}>
                        <AiFillDelete className={`cursor-pointer fill-red-800`} onClick={() => onRemoveProduct(info.row.original.id)} />
                    </Flex>
                )
            }
        }
    ]
}