import {api} from "./axios";
import {UserProps} from "../types";

export const getAds = async (params?: any) => {
    return await api.get(`/ad`, {
        params
    }).catch(({response}) => {
        return response
    })
}
export const getAdById = async (adId?: number) => {
    return await api.get(`/ad/get/${adId}`, {
    }).catch(({response}) => {
        return response
    })
}
export const updateAd = async (adId: number, payload?: any) => {
    return await api.post(`/ad/update/${adId}`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const createAd = async (payload?: any) => {
    return await api.post(`/ad`, payload, {
    }).catch(({response}) => {
        return response
    })
}
export const deleteAd = async (adId: number) => {
    return await api.delete(`/ad/delete/${adId}`).catch(({response}) => {
        return response
    })
}