import {Payments as PaymentsTemplate} from "../../templates/Payments"
import {PaymentDetails as PaymentDetailsTemplate} from "../../templates/Payments/Details"

export function Payments() {
    if (window.location.pathname.includes("/payments/") && window.location.pathname !== "/payments") {
        return <PaymentDetailsTemplate />
    }
    return (
        <PaymentsTemplate />
    )
}