import {
    AddressProps,
    CategoryProps,
    DepartmentProps,
    ErrorProps, FilterProps,
    LoginProps, ManufacturerProps, ProductInPromotion, ProductProps, PromotionProps,
    SubcategoryProps,
    UserProps
} from "../types";

export const {format : currencyFormat} = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const getAddressCreateAndEditErrors = (address: Partial<AddressProps>): ErrorProps[]  => {
    let errors: ErrorProps[] = []

    if (!address.zip_code) {
        errors.push({
            title: "CEP",
            description: "O cep não pode ser em branco",
            field: "zip_code"
        })
    }
    if (!address.city?.value) {
        errors.push({
            title: "Cidade",
            description: "A cidade não pode ser em branco",
            field: "city"
        })
    }
    if (!address.latitude) {
        errors.push({
            title: "Latitude",
            description: "A latitude não pode ser em branco",
            field: "latitude"
        })
    }
    if (!address.longitude) {
        errors.push({
            title: "Longitude",
            description: "A longitude não pode ser em branco",
            field: "longitude"
        })
    }

    return errors
}
export const getProductCreateAndEditErrors = (product: Partial<ProductProps>, isEditing = false): ErrorProps[]  => {

    console.log({product})
    let errors: ErrorProps[] = []

    if (!product.diameter) {
        errors.push({
            title: "Diâmetro da caixa",
            description: "O diâmetro da caixa não pode ser em branco",
            field: "weightwith_box"
        })
    }
    if (!product.weight) {
        errors.push({
            title: "Peso da caixa",
            description: "O peso da caixa não pode ser em branco",
            field: "weightwith_box"
        })
    }
    if (!product.length) {
        errors.push({
            title: "Comprimento da caixa",
            description: "O comprimento da caixa não pode ser em branco",
            field: "length"
        })
    }
    if (!product.width) {
        errors.push({
            title: "Largura da caixa",
            description: "A largura da caixa não pode ser em branco",
            field: "width"
        })
    }
    if (!product.height) {
        errors.push({
            title: "Altura da caixa",
            description: "A altura da caixa não pode ser em branco",
            field: "height"
        })
    }
    if (!product.name) {
        errors.push({
            title: "Nome",
            description: "O nome não pode ser em branco",
            field: "name"
        })
    }
    if (!product?.id_delivery_format) {
        errors.push({
            title: "Formato de envio",
            description: "O formato de envio não pode ser em branco",
            field: "id_delivery_format"
        })
    }
    if (!product?.id_department) {
        errors.push({
            title: "Departamento",
            description: "O departamento não pode ser em branco",
            field: "department"
        })
    }
    if (!product?.id_category) {
        errors.push({
            title: "Categoria",
            description: "A categoria não pode ser em branco",
            field: "department"
        })
    }
    if (!product?.id_manufacturer) {
        errors.push({
            title: "Fabricante",
            description: "O fabricante não pode ser em branco",
            field: "manufacturer"
        })
    }
    if (!product?.attribute_values || product?.attribute_values?.length < 1) {
        errors.push({
            title: "Attribute values",
            description: "It's required select only one attribute value",
            field: "attribute_values"
        })
    }
    // if ((!product?.filters || product?.filters?.length < 1)) {
    //     errors.push({
    //         title: "Filtros",
    //         description: "É necessário selecionar ao menos um filtro para cadastrar um produto",
    //         field: "filters"
    //     })
    // }

    if (!product?.product_variant || product?.product_variant?.length < 1) {
        errors.push({
            title: "Produto",
            description: "É necessário criar ao menos um tipo de produto, tente selecionar alguma cor",
            field: "product_variant"
        })
    }

    if (product?.product_variant?.length > 0) {
        product?.product_variant.map((variant: any, key: number) => {

            if (!variant?.medias) {
                errors.push({
                    title: "Imagem",
                    description: "É necessário selecionar ao menos uma imagem para cadastrar um produto",
                    field: `product_variant.${key}.media`
                })
            }

            variant.product_final.map((final: any, finalKey: number) => {

                if (!final?.price) {
                    errors.push({
                        title: "Preço",
                        description: "É necessário digitar o preço para cadastrar um produto",
                        field: `product_variant.${key}.product_final.${finalKey}.price`
                    })
                }
                if (!final?.quantity && !final.id) {
                    errors.push({
                        title: "Quantidade",
                        description: "É necessário digitar a quantidade para cadastrar um produto",
                        field: `product_variant.${key}.product_final.${finalKey}.price`
                    })
                }
                if (product?.filters?.length > 0) {
                    final?.filters?.filter((item: any) => !!item)?.map((filter: any, filterKey: number) => {
                        if (!filter?.value && !filter?.id_value) {
                            errors.push({
                                title: "Filtros",
                                description: "Verificar filtros obrigatórios",
                                field: `product_variant.${key}.product_final.${finalKey}.filters.${filterKey}`
                            })
                        }
                    })
                }

            })

        })
    }

    return errors
}
export const getUserCreateAndEditErrors = (user: Partial<UserProps>, isEditing = false): ErrorProps[]  => {
    let errors: ErrorProps[] = []

    if (!user.first_name) {
        errors.push({
            title: "Nome",
            description: "O nome não pode ser em branco",
            field: "first_name"
        })
    }
    if (!user.last_name) {
        errors.push({
            title: "Sobrenome",
            description: "O sobrenome não pode ser em branco",
            field: "last_name"
        })
    }
    if (!user.email) {
        errors.push({
            title: "E-mail",
            description: "O e-mail não pode ser em branco",
            field: "email"
        })
    }
    if (!user.phone) {
        errors.push({
            title: "Telefone",
            description: "O telefone não pode ser em branco",
            field: "phone"
        })
    }
    if (!isEditing) {
        if (!user.password) {
            errors.push({
                title: "Senha",
                description: "A senha não pode ser em branco",
                field: "password"
            })
        }
    }
    if (user.password) {
        if (user.password !== user.password_repeat) {
            errors.push({
                title: "Senha",
                description: "As senhas não podem ser diferentes",
                field: "password"
            })
        }
    }

    return errors
}
export const getManufacturerCreateAndEditErrors = (manufacturer: Partial<ManufacturerProps>): ErrorProps[] => {
    let errors: ErrorProps[] = []

    if (!manufacturer.name) {
        errors.push({
            title: "Nome",
            description: "O nome não pode ser em branco",
            field: "name"
        })
    }

    if (!manufacturer.slug) {
        errors.push({
            title: "Slug",
            description: "O slug não pode ser em branco",
            field: "slug"
        })
    }

    return errors
}
export const getStockCreateAndEditErrors = (stock: any): ErrorProps[] => {
    let errors: ErrorProps[] = []

    if (!stock.quantity) {
        errors.push({
            title: "Quantidade",
            description: "A quantidade não pode ser em branco",
            field: "quantity"
        })
    }

    return errors
}
export const getPromotionCreateAndEditErrors = (promotion: PromotionProps): ErrorProps[] => {
    let errors: ErrorProps[] = []
    console.log({promotion})

    if (!promotion.name) {
        errors.push({
            title: "Nome",
            description: "O nome não pode ser em branco",
            field: "name"
        })
    }

    if (!promotion.discount_percent) {
        errors.push({
            title: "Desconto",
            description: "O desconto não pode ser em branco",
            field: "discount_percent"
        })
    }

    if (!promotion.available_at) {
        errors.push({
            title: "Data de disponibilidade",
            description: "A data de disponibilidade não pode ser em branco",
            field: "available_at"
        })
    }

    if (!promotion.expire_at) {
        errors.push({
            title: "Data de expiração",
            description: "A data de expiração não pode ser em branco",
            field: "expire_at"
        })
    }

    if (!promotion?.products || promotion?.products?.length < 1) {
        errors.push({
            title: "Produtos",
            description: "É necessário adicionar ao menos um produto para cadastrar uma promoção",
            field: "products"
        })
    }

    return errors
}
export const getPromotionProductAddErrors = (product: any): ErrorProps[] => {
    let errors: ErrorProps[] = []

    if (!product.product_to_add) {
        errors.push({
            title: "Produto",
            description: "O produto não pode ser em branco",
            field: "product_to_add"
        })
    }

    return errors
}
export const getFilterValueAddErrors = (value: any): ErrorProps[] => {
    let errors: ErrorProps[] = []

    if (!value.value_to_add) {
        errors.push({
            title: "Valor",
            description: "O valor não pode ser em branco",
            field: "value_to_add"
        })
    }

    return errors
}
export const getFilterCreateAndEditErrors = (filter: Partial<FilterProps>): ErrorProps[] => {
    let errors: ErrorProps[] = []

    if (!filter?.department?.value) {
        errors.push({
            title: "Departamento",
            description: "O departamento não pode ser em branco",
            field: "department"
        })
    }

    if (!filter?.name) {
        errors.push({
            title: "Nome",
            description: "O nome não pode ser em branco",
            field: "name"
        })
    }

    if (!filter?.type?.value) {
        errors.push({
            title: "Tipo",
            description: "O tipo não pode ser em branco",
            field: "type"
        })
    }
    console.log({filter})

    if (filter?.type?.value === "string" && (filter?.values?.length < 1 || !filter?.values)) {
        errors.push({
            title: "Opções",
            description: "É necessário ter ao menos uma opção quando o tipo for string",
            field: "values"
        })
    }

    return errors
}
export const getHighlightProductAddErrors = (product: any): ErrorProps[] => {
    let errors: ErrorProps[] = []

    if (!product.product_to_add) {
        errors.push({
            title: "Produto",
            description: "O produto não pode ser em branco",
            field: "product_to_add"
        })
    }

    return errors
}

export const getAdCreateAndEditErrors = (highlight: any): ErrorProps[] => {
    let errors: ErrorProps[] = []

    return errors
}
export const getHighlightCreateAndEditErrors = (highlight: any): ErrorProps[] => {
    let errors: ErrorProps[] = []

    if (!highlight?.products || highlight?.products?.length < 1) {
        errors.push({
            title: "Produtos",
            description: "É necessário selecionar ao menos um produto para continuar com o cadastro de destaques",
            field: "products"
        })
    }

    return errors
}
export const getRefundCreateAndEditErrors = (refund: Partial<any>): ErrorProps[] => {
    let errors: ErrorProps[] = []

    if (!refund.id_payment) {
        errors.push({
            title: "Nome",
            description: "O nome não pode ser em branco",
            field: "name"
        })
    }

    return errors
}
export const getDepartmentCreateAndEditErrors = (department: Partial<DepartmentProps>): ErrorProps[] => {
    let errors: ErrorProps[] = []

    if (!department.name) {
        errors.push({
            title: "Nome",
            description: "O nome não pode ser em branco",
            field: "name"
        })
    }

    if (!department.slug) {
        errors.push({
            title: "Slug",
            description: "O slug não pode ser em branco",
            field: "slug"
        })
    }

    return errors
}
export const getCategoryCreateAndEditErrors = (category: Partial<CategoryProps>): ErrorProps[] => {
    let errors: ErrorProps[] = []

    if (!category.name) {
        errors.push({
            title: "Nome",
            description: "O nome não pode ser em branco",
            field: "name"
        })
    }

    if (!category.slug) {
        errors.push({
            title: "Slug",
            description: "O slug não pode ser em branco",
            field: "slug"
        })
    }

    if (!category?.department?.value) {
        errors.push({
            title: "Departamento",
            description: "O departamento não pode ser em branco",
            field: "department"
        })
    }

    return errors
}
export const getSubcategoryCreateAndEditErrors = (subcategory: Partial<SubcategoryProps>): ErrorProps[] => {
    let errors: ErrorProps[] = []

    if (!subcategory.name) {
        errors.push({
            title: "Nome",
            description: "O nome não pode ser em branco",
            field: "name"
        })
    }

    if (!subcategory.slug) {
        errors.push({
            title: "Slug",
            description: "O slug não pode ser em branco",
            field: "slug"
        })
    }

    if (!subcategory?.department?.value) {
        errors.push({
            title: "Departamento",
            description: "O departamento não pode ser em branco",
            field: "department"
        })
    }

    if (!subcategory?.category?.value) {
        errors.push({
            title: "Categoria",
            description: "A categoria não pode ser em branco",
            field: "category"
        })
    }

    return errors
}
export const getLoginErrors = (login: Partial<LoginProps>): ErrorProps[] => {
    let errors: ErrorProps[] = []

    if (!login.email) {
        errors.push({
            title: "E-mail",
            description: "O e-mail não pode ser em branco",
            field: "email"
        })
    }

    if (!login.password) {
        errors.push({
            title: "Senha",
            description: "A senha não pode ser em branco",
            field: "password"
        })
    }

    return errors
}