import {Orders as OrdersTemplate} from "../../templates/Orders"
import {OrderDetails as OrderDetailsTemplate} from "../../templates/Orders/Details"

export function Orders() {
    if (window.location.pathname.includes("/orders/") && window.location.pathname !== "/orders") {
        return <OrderDetailsTemplate />
    }
    return (
        <OrdersTemplate />
    )
}