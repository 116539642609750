import {Flex, SimpleGrid} from "@chakra-ui/react";
import {Input} from "../../../../../components/Input";
import React from "react";
import { Control } from "react-hook-form";
import {IoMdAdd} from "react-icons/io"
import {MediaInput} from "../../../../../components/MediaInput";
import {Textarea} from "../../../../../components/Textarea";
import {Select} from "../../../../../components/Select";
import {Button} from "../../../../../components/Button";
import {List} from "../../../../../components/List";
import {listColumns} from "./listColumns";
import {HighlightProps, PromotionProps, SelectOptionProps} from "../../../../../types";

type ProductsBoxProps = {
    control: Control<any>
    isEditing?: boolean
    isLoading?: boolean
    onSelectInvalidType?: () => void
    onAddProduct?: () => void
    onRemoveProduct: (productId: number) => void
    products: SelectOptionProps[]
    values: HighlightProps
}

export function ProductsBox({control, values, products, isEditing, onSelectInvalidType, onAddProduct, onRemoveProduct, isLoading}: ProductsBoxProps) {
    return (
        <SimpleGrid columns={2} className={"flex-1 gap-2"}>
            <Flex className={"flex-1 col-span-2 gap-2 items-end"}>
                <Select control={control} labelClassName={"flex-1"} options={products?.filter((filter) => {
                    return !values?.products?.find((find: any) => find.id === filter.value)
                })} label={"Produto *"}
                        placeholder={"Selecione um produto *"} name={"product_to_add"}/>
                <Button className={'!bg-red-500'} onClick={onAddProduct}><IoMdAdd className={`fill-white`} /></Button>
            </Flex>
            {values?.products?.length > 0 && (
                <List className={`col-span-2`} columns={listColumns({
                    onRemoveProduct
                })} data={values?.products} />
            )}
        </SimpleGrid>
    )
}