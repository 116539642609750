import {ColumnProps} from "../../types/table";
import {Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import {MdOutlineViewHeadline} from "react-icons/md"
import {BiBlock} from "react-icons/bi"
import {FcApproval} from "react-icons/fc"
import React from "react";
import {currencyFormat} from "../../helpers";
import {DropdownMenu} from "../../components/DropdownMenu";
type TableColumnsProps = {
    onDelete: (paymentId: number) => void
    onEdit: (paymentId: number) => void
    onDetail?: (paymentId: number) => void
}
export const tableColumns = ({onDelete, onEdit, onDetail}: TableColumnsProps): ColumnProps[] => {
    return [
        {
            header: "AÇÕES",
            cell: (info) => {
                const options = [
                    {
                        label: "Details",
                        onClick: () => onDetail && onDetail(info.row.original.id)
                    }
                ]
                return (

                    <Flex gap={2}>
                        <DropdownMenu options={options}/>
                    </Flex>
                )
            }
        },
        {
            id: "id",
            header: "ID"
        },
        {
            id: "id_order",
            header: "PEDIDO"
        },
        {
            id: "method_name",
            header: "MÉTODO",
            cell: info => info.row.original?.payment_methods?.name
        },
        {
            id: "status_name",
            header: "STATUS",
            cell: info => info.row.original?.payment_status?.name
        },
        {
            id: "price",
            header: "VALOR",
            cell: (info) => currencyFormat(Number(info.row.original.price))
        },
        {
            id: "id_maverick_payment",
            header: "ID MAVERICK"
        },
        {
            id: "confirmed_at",
            header: "CONFIRMADO EM"
        },
        {
            id: "refused_at",
            header: "RECUSADO EM"
        },
        {
            id: "created_at",
            header: "CRIADO EM"
        },
        {
            id: "updated_at",
            header: "ATUALIZADO EM"
        }
    ]
}