import * as React from 'react'
import {Table as ChakraUiTable, TableContainer, Thead, Tbody, Tfoot, Tr, Th, Td, Box} from "@chakra-ui/react"
import ReactPaginate from 'react-paginate'
import {GrFormNext, GrFormPrevious, GrMore} from "react-icons/gr"

import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable
} from '@tanstack/react-table'
import {ListProps, TableProps} from "../../types/table";
import { useMediaQuery } from '@chakra-ui/react'

const columnHelper = createColumnHelper<any>()

export function List({columns, data, onRowClick, className}: ListProps) {
    const [isMobile] = useMediaQuery('(max-width: 768px)')

    const columnHelpers = columns?.map((column) => {
        return columnHelper.accessor(column.id!, {
            ...column
        })
    })

    const table = useReactTable({
        data,
        columns: columnHelpers,
        getCoreRowModel: getCoreRowModel(),
    })

    const handleRowClick = (index: number) => {
        if (onRowClick) {
            onRowClick(index)
        }
    }

    return (
            <TableContainer className={`max-w-full overflow-auto border-gray-200 bg-gray-200 dark:border-neutral-800 dark:bg-neutral-800 border-solid border ${className}`}>
                <ChakraUiTable size={"sm"}>
                    <Thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <Tr key={headerGroup.id} className={`!border-0 bg-gray-300 dark:bg-neutral-900`}>
                                {headerGroup.headers.map(header => (
                                    <Th key={header.id} className={`!text-black dark:!text-white !border-0`}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map(row => (
                            <Tr onClick={() => {
                                handleRowClick(Number(row.id))
                            }} key={row.id} className={``}>
                                {row.getVisibleCells().map(cell => (
                                    <Td key={cell.id} className={`!border-0`}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                    <Tfoot>
                        {table.getFooterGroups().map(footerGroup => (
                            <Tr key={footerGroup.id}>
                                {footerGroup.headers.map(header => (
                                    <Th key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.footer,
                                                header.getContext()
                                            )}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Tfoot>
                </ChakraUiTable>
            </TableContainer>
    )
}
