import {useNavigate, useParams} from "react-router-dom";
import {Accordion} from "../../../components/Accordion";
import {Control, useForm} from "react-hook-form";
import {UserBox} from "../../../components/FormBox/UserBox";
import React, {useEffect, useState} from "react";
import {AddressProps, DepartmentProps, UserProps} from "../../../types";
import {createAddress, getAddressById} from "../../../services/address";
import {createDepartment, getDepartmentById, updateDepartment} from "../../../services/departments";
import {Box, Flex, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {
    getAddressCreateAndEditErrors,
    getDepartmentCreateAndEditErrors,
    getUserCreateAndEditErrors
} from "../../../helpers";
import {CancelItemBox} from "../../../components/CancelItemBox";
import {Dialog} from "../../../components/Dialog";
import {Container} from "../../../components/Container";
import {Button} from "../../../components/Button";
import {PasswordBox} from "../../../components/FormBox/PasswordBox";
import {CreateAndEditBox} from "./components/CreateAndEditBox";
import {NewItemBox} from "../../../components/NewItemBox";

export function DepartmentCreateAndEdit() {
    const [isSaving, setIsSaving] = useState(false)

    const {onOpen: saveDialogOnOpen, isOpen: saveDialogIsOpen, onClose: saveDialogOnClose} = useDisclosure()
    const navigate = useNavigate()
    const toast = useToast()
    const {
        control: departmentControl,
        watch: departmentWatch,
        getValues: departmentGetValues,
        reset: departmentReset,
        resetField: departmentResetField
    } = useForm<Partial<DepartmentProps>>()
    const params = useParams()
    const departmentId = Number(params.departmentId)

    const getDepartmentData = async () => {
        const response = await getDepartmentById(departmentId)
        console.log({data: response.data})
        departmentReset({
            ...response.data
        })
    }
    useEffect(() => {

        if (departmentId) {
            getDepartmentData()
        }
    }, [])


    const handleSave = async () => {
        const departmentPayload = departmentGetValues()

        let hasErrors = false

        const departmentErrors = getDepartmentCreateAndEditErrors(departmentPayload)

        const errors = [...departmentErrors]

        if (errors.length > 0) {
            hasErrors = true
            for (const error of errors) {
                toast({
                    status: "error",
                    title: error.title,
                    description: error.description,
                    isClosable: true
                })
            }
        }

        if (hasErrors) {
            return
        }

        let departmentResponse: any
        setIsSaving(true)
        if (departmentId) {
            departmentResponse = await updateDepartment(departmentId, {...departmentPayload})
        } else {
            departmentResponse = await createDepartment({...departmentPayload})
        }
        if (departmentResponse.status !== 201 && departmentResponse.status !== 200) {
            setIsSaving(false)
            return toast({
                status: "error",
                title: "Erro ao salvar departamento",
                description: departmentResponse.data.message || "Houve um erro salvar o departamento, por favor tente novamente",
                isClosable: true
            })
        }
        setIsSaving(false)

        navigate('/departments')
        return toast({
            status: "success",
            title: "Departamento salvo com sucesso",
            description: departmentResponse.data.message || "O departamento foi salvo com sucesso",
            isClosable: true
        })

    }
    const handleCancel = () => {
        return navigate("/departments")
    }

    return (
        <>
            <Dialog
                onAccept={handleSave}
                isOpen={saveDialogIsOpen}
                onClose={saveDialogOnClose}
                title={"Salvar departamento"}
                description={"Ao confirmar, o departamento será salvo"}
            />
            <CancelItemBox title={"Voltar para lista de departamentos"} onClick={() => navigate('/departments')} />
            <Accordion title={"Dados do departamento"} defaultExpanded={true}>
                <CreateAndEditBox control={departmentControl as Control<any>} isEditing={!!departmentId} />
            </Accordion>
            <Container>
                <Flex className={`col-span-2 gap-2 justify-end`}>
                    <Button className={`!bg-neutral-800 !text-white`} onClick={() => handleCancel()}>Voltar</Button>
                    <Button className={`!bg-red-500 !text-white`} onClick={() => saveDialogOnOpen()}> Salvar {isSaving && <Spinner size={"sm"} className={`ml-1`}/>}</Button>
                </Flex>
            </Container>
        </>
    )
}