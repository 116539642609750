import {ColumnProps} from "../../../../../types/table";
import {Flex} from "@chakra-ui/react";
import {AiFillCloseCircle, AiFillDelete, AiFillEdit} from "react-icons/ai";
import React from "react";
type ListColumnsProps = {
    onRemoveValue: (value: string) => void
}
export const listColumns = ({onRemoveValue}: ListColumnsProps): ColumnProps[] => {
    return [
        {
            id: "value",
            header: "OPÇÃO"
        },
        {
            header: "AÇÕES",
            cell: (info) => {
                return (
                    <Flex gap={2}>
                        <AiFillDelete className={`cursor-pointer fill-red-800`} onClick={() => onRemoveValue(info.row.original.value)} />
                    </Flex>
                )
            }
        }
    ]
}